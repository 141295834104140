
export enum ProductKey {
    SmartRadio = 'smart-radio',
    WorkforceDiagnostics = 'workforce-diagnostics',
    EquipmentDiagnostics = 'equipment-diagnostics',
    Protect = 'protect',
    DigitalConfinedSpaceManagement = 'digital-confined-space-management',
}

export class Product {
    id: number;
    name: string;
    key: ProductKey;
    features: string[];
}

export class ProductFeature {
    id: number;
    name: string;
    key: string;
}