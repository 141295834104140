import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { HttpService } from 'app/services/http.service';

@Component({
    selector: 'app-enable-disable-walt-wifis-modal',
    templateUrl: './enable-disable-walt-wifis-modal.component.html',
    styleUrls: ['./enable-disable-walt-wifis-modal.component.scss'],
})
export class EnableDisableWaltWifisModalComponent implements OnChanges {
    @Input() open = false;
    @Input() instanceId: number;
    @Input() siteId: number;
    @Input() waltId: number;
    @Input({ required: true }) disabled: boolean;

    @ViewChild('modalContent') modalContent: any;

    @Output() loadingChanged = new EventEmitter(false);
    @Output() submitEvent = new EventEmitter();
    @Output() closeEvent = new EventEmitter();
    @Output() errorEvent = new EventEmitter(null);

    isSubmitting = false;
    dialogRef: MatDialogRef<EnableDisableWaltWifisModalComponent>;

    constructor(
        private _dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private _httpService: HttpService,
    ) { }

    get actionType(): string {
        return this.disabled ? 'temporarily disable' : 'enable';
    }

    get titleText(): string {
        return this.waltId ? `Are you sure you want to ${this.actionType}<br>Wi-Fi for this device?`
            : `Are you sure you want to ${this.actionType}<br>Wi-Fi for all devices associated<br>with this site?`;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('open' in changes) {
            if (this.open) {
                this.openDialog();
            } else {
                this.closeDialog();
            }
        }
    }

    private openDialog() {
        this.dialogRef = this._dialog.open(this.modalContent, { id: 'enable-disable-wifi' });
        this.dialogRef.backdropClick().subscribe(() => this.closeDialog());
    }

    async dialogSubmit() {
        this.loadingChanged.emit(true);
        this.isSubmitting = true;
        try {
            const enableDisableWaltWifiRequest = {
                instanceId: this.instanceId?.toString(),
                siteId: this.siteId?.toString(),
                waltId: this.waltId?.toString(),
                disabled: this.disabled,
            };
            await this._httpService.post(`administration/enable-disable-walt-wifis`, enableDisableWaltWifiRequest);
            this.submitEvent.emit();
        } catch (error) {
            this.errorEvent.emit(error);
            this._snackBar.open(`Failed to ${this.disabled ? 'disable' : 'enable'} walt wifi: ${error.message}`, 'Dismiss', { duration: 10000 });
        } finally {
            this.loadingChanged.emit(false);
            this.isSubmitting = false;
            this.closeDialog();
        }
    }

    closeDialog() {
        this.dialogRef?.close();
        this.closeEvent.emit();
    }
}
