import { Component, OnInit } from '@angular/core';

import { HttpService } from 'app/services/http.service';
import { Permission } from 'app/models/control-center/permission.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss'],
})
export class PeopleComponent implements OnInit {

  displayedColumns = [
    { key: 'name', label: 'Name', type: 'string' },
    { key: 'email', label: 'Email', type: 'string' },
    { key: 'phone', label: 'Phone', type: 'string' },
    { key: 'company', label: 'Company', type: 'string' },
  ];

  loading = true;
  people: any = [];
  permissions: Permission;

  constructor(private _httpService: HttpService, private _router: Router) { }

  async ngOnInit() {
    this.permissions = await this._httpService.get('people/authenticated/permissions');
    await this.load();
  }

  async load() {
    this.loading = true;

    this.people = await this._httpService.get('people');

    this.loading = false;
  }

  rowClick(id: any) {
    this._router.navigateByUrl('/companies/people/' + id);
  }

  add() {
    this._router.navigateByUrl(`companies/people/0`);
  }

}
