<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-3">
    <h1 class="h2">Scanner Utility Page</h1><br>
</div>

<div *ngIf="loading">loading</div>

<div class="row scrollContainer" *ngIf="!loading">
    <div class="col-md-12 scrollColumn">
        <div class="row">
            <p>Quickly Scan multiple assets to update their statuses. Select all required fields and click "Start
                Scanning".
            </p>
            <mat-form-field appearance="fill" style="width:100%">
                <mat-label>Status</mat-label>
                <mat-select [(ngModel)]="bulkUpdateStatuses.status" name="status" ngDefaultControl>
                    <mat-option [value]="assetStatuses.Available">
                        Available | <small>Asset is ready for use.</small>
                    </mat-option>
                    <mat-option [value]="assetStatuses.CheckedOut">
                        Checked Out | <small>Asset is checked out to a specific site.</small> 
                    </mat-option>
                    <mat-option [value]="assetStatuses.Decommissioned">
                        Decommissioned | <small>Asset is no longer usable and
                            will be discarded.</small>
                    </mat-option>
                    <mat-option [value]="assetStatuses.Maintenance">
                        Maintenance | <small>Asset requires maintenance before
                            it is ready for use.</small>
                    </mat-option>
                    <mat-option [value]="assetStatuses.Provisioning">
                        Provisioning | <small>Asset requires verification before
                            it is ready for use</small>
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" style="width:100%"
                *ngIf="[assetStatuses.Available, assetStatuses.Provisioning, assetStatuses.Maintenance].includes(bulkUpdateStatuses.status)">
                <mat-label>Warehouse</mat-label>
                <mat-select name="warehouseId" [(ngModel)]="bulkUpdateStatuses.warehouseId">
                    <mat-option *ngFor="let warehouse of warehouses" [value]="warehouse.id">
                        {{warehouse.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field style="width:100%" appearance="fill"
                *ngIf="[assetStatuses.Available].includes(bulkUpdateStatuses.status)">
                <mat-label>Warehouse Location</mat-label>
                <input matInput placeholder="Warehouse Location" name="warehouseLocation"
                    [(ngModel)]="bulkUpdateStatuses.warehouseLocation">
            </mat-form-field>
            <app-autocomplete [options]="sites" name="siteId" [value]="bulkUpdateStatuses.siteId"
                label="Company Site the asset is assinged to" (optionChange)="bulkUpdateStatuses.siteId = $event.id"
                [filterLimit]="100" *ngIf="bulkUpdateStatuses.status === assetStatuses.CheckedOut">
            </app-autocomplete>
            <app-autocomplete [options]="people" name="personId" [value]="bulkUpdateStatuses.personId"
                label="Person Assigned To" (optionChange)="bulkUpdateStatuses.personId = $event.id" [filterLimit]="100"
                displayKey="displayName"
                *ngIf="[assetStatuses.CheckedOut, assetStatuses.Maintenance].includes(bulkUpdateStatuses.status)">
            </app-autocomplete>
        </div>
        <div class="row">
            <div class="col-md-6 mb-3">
                <button mat-raised-button class="demo-button" color="primary" (click)="start()" *ngIf="!scanning"
                    [disabled]="bulkUpdateStatuses.status === undefined 
            || ([assetStatuses.Available, assetStatuses.Provisioning, assetStatuses.Maintenance].includes(bulkUpdateStatuses.status) && bulkUpdateStatuses.warehouseId === undefined) 
            || ([assetStatuses.Maintenance].includes(bulkUpdateStatuses.status) && bulkUpdateStatuses.personId === undefined) 
            || ([assetStatuses.CheckedOut].includes(bulkUpdateStatuses.status) && bulkUpdateStatuses.siteId === undefined)
            || ([assetStatuses.Available].includes(bulkUpdateStatuses.status) && bulkUpdateStatuses.warehouseLocation === undefined)">
                    Start Scanning
                </button>
                <button mat-raised-button class="demo-button" color="warn" (click)="stop()" *ngIf="scanning">
                    Stop Scanning
                </button>
            </div>
            <div class="col-md-2 mb-3" *ngIf="scanning">
                <b>{{scanResults.length}}</b> scanned
            </div>
            <div class="col-md-2 mb-3" *ngIf="scanning">
                <b><span style="color:#62bd69">{{scanResultsSuccessful}}</span></b> succeeded
            </div>
            <div class="col-md-2 mb-3" *ngIf="scanning" >
                <b><span style="color:#f44336">{{scanResultsFailed}}</span></b> failed
            </div>
        </div>
        <div class="row" *ngIf="scanning">
            <div class="col-md-12">
                <table style="width:100%">
                    <tr>
                        <th></th>
                        <th>Serial Number</th>
                        <th>Result</th>
                        <th>Time</th>
                    </tr>
                    <tr *ngFor="let scanResult of scanResults">
                        <td>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 640 512"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-file-text" aria-hidden="true" fill="#62bd69"
                                *ngIf="scanResult.success">
                                <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                <path
                                    d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 640 512"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-file-text" aria-hidden="true" fill="#f44336"
                                *ngIf="!scanResult.success">
                                <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                <path
                                    d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                            </svg>
                        </td>
                        <td>{{scanResult.serialNumber}}</td>
                        <td>{{scanResult.result}}</td>
                        <td>{{scanResult.time | date: 'short'}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>