import { GoogleMap } from '@angular/google-maps';

/**
 * @agm/core had a [fitBounds]="true" attribute. @angular/google-maps doesn't, so we have to do it manually.
 */
export function autoFitBoundaries(map: GoogleMap, positions: google.maps.LatLngLiteral[]): boolean {
    if (!map) return false;
    if (!positions?.length) return false;

    const bounds = new google.maps.LatLngBounds();
    positions.forEach(position => bounds.extend(position));
    map.fitBounds(bounds);
    return true;
}
