import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, map, startWith } from 'rxjs';
import { FormControl } from '@angular/forms';
import { HttpService } from 'app/services/http.service';
import { BooleanInput } from '@angular/cdk/coercion';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements OnInit {
  @Output() optionChange = new EventEmitter();
  @Output() blurEvent = new EventEmitter();

  @Input() options: any[] = [];
  @Input() url = '';
  @Input() placeHolder = 'Pick One';
  @Input() value?: any;
  @Input() valueForced?: any;
  @Input() label?: string;
  @Input() disabled = false;
  @Input() disabledValue?: string;
  @Input() hint: string;
  @Input() control: any;
  @Input() filterLimit: number;
  @Input() displayKey = 'name';
  @Input() showErrors = false;
  @Input() required?: BooleanInput;

  myControl = new FormControl();
  filteredOptions: Observable<any[] | undefined>;
  valueLabel: string;

  loaded = false;

  constructor(private _httpService: HttpService) {}

  ngOnInit(): void {

    if (this.control) {
      this.myControl = this.control;
    }

    if (!this.placeHolder) {
      this.placeHolder = 'Pick One';
    }

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );

    if (!this.options) this.options = [{ name: 'loading...', id: 0 }];
    else this.loaded = true;

    if (this.value) {
      const item = this.options.find(o => o.id === this.value);
      if (item) {
        this.myControl.setValue(item);
        this.disabledValue = item[this.displayKey];
      }
    } else if (this.valueForced) {
      this.myControl.setValue(this.valueForced);
      this.disabledValue = this.valueForced[this.displayKey];
    }
  }

  private _filter(value: any) {
    if (value.id) return undefined;
    const filterValue = value.toLowerCase();

    if (this.filterLimit && this.filterLimit > 0) {
      return this.options.filter(option => option[this.displayKey]?.toLowerCase().includes(filterValue)).slice(0, this.filterLimit);
    } else {
      return this.options.filter(option => option[this.displayKey]?.toLowerCase().includes(filterValue));
    }
  }

  async load() {
    if (!this.loaded || this.options.length === 0) {
      if (this.url) {
        this.options = await this._httpService.get(this.url);
      }
      if (this.value) {
        const item = this.options.find(o => o.id === this.value);
        if (item) {
          this.myControl.setValue(item);
          this.disabledValue = item[this.displayKey];
        }
      } else if (this.valueForced) {
        this.myControl.setValue(this.valueForced);
        this.disabledValue = this.valueForced[this.displayKey];
      }
    } else {
      return;
    }
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );
  }

  displayFn(option: any) {
    if (option) {
      return option[this.displayKey];
    }
  }

  clicked(option: any) {
    this.optionChange.emit(option);
  }

  onBlur() {
    this.blurEvent.emit();
  }

  clear() {
    this.myControl.reset();
  }
}
