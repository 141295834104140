<div class="mt-3">
    <p class="lead">Temporary Support Access</p>
    <p class="text-muted">
        This form will allow you access to {{instance.name}}'s console for a specified amount of time.
        The customer will be notified that you have accessed their account
        and will be sent a list of any changes made during this time.
    </p>
</div>

<hr>

<form [formGroup]="supportAccessForm" #formDirective="ngForm">
    <div class="row">
        <div class="col">
            <app-autocomplete
                label="User"
                placeHolder="User"
                [options]="people"
                displayKey="displayName"
                [filterLimit]="100"
                [control]="supportAccessForm.controls.user"
                [showErrors]="true"
                [required]="true"
            />
        </div>
        <div class="col">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Time Period</mat-label>
                <mat-select [formControl]="supportAccessForm.controls.hours">
                    <mat-option [value]="0.0833" *ngIf="isLowerEnv">5 minutes</mat-option>
                    <mat-option [value]="0.1667" *ngIf="isLowerEnv">10 minutes</mat-option>
                    <mat-option [value]="0.5">30 minutes</mat-option>
                    <mat-option [value]="1">1 hour</mat-option>
                    <mat-option [value]="2">2 hours</mat-option>
                    <mat-option [value]="4">4 hours</mat-option>
                    <mat-option [value]="8">8 hours</mat-option>
                    <mat-option [value]="12">12 hours</mat-option>
                    <mat-option [value]="24">24 hours</mat-option>
                </mat-select>
                <mat-error>Invalid Time Period</mat-error>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>External Ticket Number</mat-label>
                <input
                    matInput
                    placeholder="External Ticket Number"
                    formControlName="externalTicketNumber"
                >
            </mat-form-field>
        </div>
    </div>
    
    <div class="row">
        <div class="col">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Reason</mat-label>
                <textarea
                    matInput
                    cdkTextareaAutosize
                    placeholder="Reason"
                    style="min-height: 100px;"
                    formControlName="reason"
                    [required]="true"
                ></textarea>
                <mat-error>Reason Required</mat-error>
                <mat-hint>*Note: This information will be sent to the admin when they are notified you have accessed their account</mat-hint>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col mt-5">
            <button
                mat-raised-button color="primary"
                [disabled]="supportAccessForm.invalid"
                (click)="showConfirmation(formDirective)"
            >
                Submit
            </button>
            <mat-error *ngIf="supportAccessForm.controls.user?.errors?.['isSyncedWithWeavix'] === false">
                Selected user has never logged into weavix or has not synced yet; please select a different user.
            </mat-error>
        </div>
    </div>
</form>

<ng-template #confirmDialog let-data>
    <h2 mat-dialog-title>Temporary Support Access</h2>
    <mat-dialog-content>
        You are about to access
        <strong>{{data.instanceName}}'s</strong>
        account for a period of up to
        <strong>{{data.hours}} hours.</strong>
        The customer will be notified of your access and any changes made during the period.
        The following reason will be included in the notification to the customer:
        <strong>{{data.reason}}</strong>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-raised-button [matDialogClose]="true" color="primary">Confirm</button>
        <button mat-raised-button [matDialogClose]="false">Cancel</button>
    </mat-dialog-actions>
</ng-template>
