import { ProductKey } from './product.model';

export class Instance {
    id?: number;
    companyId: number;
    name: string;
    products?: ProductKey[];
    productFeatures?: string[];
    weavixAccountId?: string;
    disabled: boolean;
    administratorPersonId?: number;
    displayName?: string;
    allCompanies?: boolean;
    trialExpires?: number; // Unix ms
    activated?: boolean;
}

