import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { PermissionType } from 'app/models/control-center/permission.model';
import { Utils } from 'app/utils';
import _ from 'lodash';
import moment from 'moment';


export enum TableFilterType {
    Multiselect,
    Date,
    DateRange,
    Text,
}

export interface TableFilter {
    key: string;
    name?: string;
    type?: TableFilterType;
    options?: Array<{ label: string, value: any }>;
    show?: boolean;
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, AfterViewInit {
  @Output() createClick = new EventEmitter<any>();
  @Output() rowClick = new EventEmitter<any>();
  @Output() rowActionClick = new EventEmitter<any>();
  @Output() deleteClick = new EventEmitter<any>();
  @Output() editClick = new EventEmitter<any>();
  @Output() importClick = new EventEmitter<any>();
  @Output() actionClick = new EventEmitter<any>();

  @Input() header?: string;
  @Input() subheader?: string;
  @Input() displayedColumns: any[] = [];
  @Input() dataSource: any[] = [];
  @Input() route = '';
  @Input() checkBoxes = false;
  @Input() showActions = true;
  @Input() uniqueKey = 'default';
  @Input() search = true;
  @Input() clickToEdit = true;
  @Input() showAdd = true;
  @Input() showDelete = true;
  @Input() paginate = true;
  @Input() permission = PermissionType.None;
  @Input() showImport = false;
  @Input() filters: TableFilter[];
  @Input() additionalActions: Array<{ name: string, key: string, requireItemSelection: boolean | number, show?: (any) => boolean }> = [];
  @Input() rowActions?: Array<{ name: string, key: string, color?: string, show?: (any) => boolean }>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('deleteDialog') deleteDialog: any;
  @ViewChild('deleteManyDialog') deleteManyDialog: any;

  TableFilterType = TableFilterType;

  selection = new SelectionModel<any>(true, []);

  loading = true;
  filteredDataSource: MatTableDataSource<any>;
  columns: string[] = [];
  rowValues: { [id: string]: { [col: string]: any } } = {};
  rowItemExpanded: { [id: string]: { [colKey: string]: boolean } } = {};
  rowItemExpanding: { [id: string]: { [colKey: string]: boolean } } = {};

  afterLoadDataSource = new MatTableDataSource<any>();
  permissionType = PermissionType;

  appliedFilters: { [key: string]: any | any[] } = {};
  previousSearchValue: string;
  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {

    this.filteredDataSource = new MatTableDataSource<any>();

    if (this.checkBoxes && this.permission === PermissionType.Edit) this.columns.push('select');
    for (let i = 0; i < this.displayedColumns.length; i++) {
      this.columns.push(this.displayedColumns[i].key);
    }
    if (this.rowActions?.length && this.permission === PermissionType.Edit) this.columns.push('row-actions');
    this.previousSearchValue = sessionStorage.getItem(`${this.uniqueKey}-searchValue`);
    if (this.previousSearchValue) this.filteredDataSource.filter = this.previousSearchValue;

  }

  ngAfterViewInit() {
    if (this.paginate) this.filteredDataSource.paginator = this.paginator;
    this.filteredDataSource.sort = this.sort;
    this.filteredDataSource.sortData = this.customSort;
    this.filteredDataSource.data = this.dataSource;
    this.afterLoadDataSource = this.filteredDataSource;
    this.createTableRowValues();
    this.loading = false;
  }

  private createTableRowValues() {
    for (const row of this.afterLoadDataSource.data) {
        for (const col of this.displayedColumns) {
            this.rowValues[row.id] = {
                ...(this.rowValues[row.id] || {}),
                [col.key]: col.value ? col.value(row) : row[col.key],
            };
        }
    }
  }

  private customSort = (data: any[], sort: MatSort) => {
    if (sort.active) {
        const factor = sort.direction === 'asc' ? 1 : sort.direction === 'desc' ? -1 : 0;
        data = data.sort((a: any, b: any) => {
            const aValue = this.rowValues[a.id]?.[sort.active];
            const bValue = this.rowValues[b.id]?.[sort.active];
            return aValue > bValue ? factor : aValue < bValue ? -factor : 0;
        });
    }
    return data;
  };

  forceRowUpdate(key: string | string[]) {
    const keys: string[] = _.isArray(key) ? key as string[] : [key as string];
    keys.forEach(k => {
        const row = this.afterLoadDataSource.data.find(d => d.id === k);
        for (const col of this.displayedColumns) {
            this.rowValues[row.id] = {
                ...(this.rowValues[k] || {}),
                [col.key]: col.value ? col.value(row) : row[col.key],
            };
        }
    });
  }

  applySearch(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filteredDataSource.filterPredicate = this.filterCriteria;
    this.filteredDataSource.filter = filterValue.trim().replace(/\s*,\s*/g, ',').toLowerCase();
    sessionStorage.setItem(`${this.uniqueKey}-searchValue`, filterValue);
    if (this.filteredDataSource.paginator) {
      this.filteredDataSource.paginator.firstPage();
    }
  }

  private filterCriteria = (obj, filter): boolean => {
    let isMatch = false;
    const rowValues = this.rowValues?.[obj.id];
    if (rowValues) {
        for (const key in rowValues) {
            const rowValue = rowValues[key];
            if (rowValue) isMatch = filter.split(',').some(item => `${rowValue}`.trim().toLowerCase().includes(item));
            if (isMatch) break;
        }
    }
    return isMatch;
  };

  applyFilter(key: string, value: any) {
    const filterType = this.filters.find(f => f.key === key)?.type;
    if (filterType === TableFilterType.Multiselect) {
        if (this.appliedFilters[key]?.includes(value)) this.appliedFilters[key] = this.appliedFilters[key].filter(v => v !== value);
        else this.appliedFilters[key] = [...(this.appliedFilters[key] || []), value];
    } else if (filterType === TableFilterType.DateRange) {
        if (key in this.appliedFilters) delete this.appliedFilters[key];
        this.appliedFilters[key] = value;
    }

    const doFilter = (data) => {
        for (const key in this.appliedFilters) {
            const filterType = this.filters.find(f => f.key === key)?.type;
            if (filterType === TableFilterType.DateRange) {
                const { start, end } = this.appliedFilters[key];
                const rowValue = moment(data[key]);
                if (rowValue.isBefore(moment(start)) || rowValue.isAfter(moment(end))) return false;
            } else if (filterType === TableFilterType.Multiselect) {
                const rowValue = this.rowValues?.[data.id]?.[key];
                if (rowValue) {
                    const filterValues = this.appliedFilters[key].map(v => v.trim().toLowerCase());
                    const rowValues = `${rowValue}`.trim().toLowerCase();
                    if (filterValues.length && !filterValues.some(v => rowValues.includes(v))) return false;
                }
            }
        }
        return true;
    };

    this.filteredDataSource.filterPredicate = doFilter;
    this.filteredDataSource.filter = Object.values(this.appliedFilters).join(' ').toLowerCase();

    if (this.filteredDataSource.paginator) {
      this.filteredDataSource.paginator.firstPage();
    }
  }

   /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.filteredDataSource.data.length;
    return numSelected === numRows && numRows > 0;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.filteredDataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  create() {
    this.createClick.emit();
  }

  import() {
    this.importClick.emit();
  }

  async export() {
    await Utils.downloadCsv(this.filteredDataSource.filteredData);
  }

  deleteMany() {
    const dialogRef = this.dialog.open(this.deleteManyDialog);
    dialogRef.afterClosed().subscribe(result => {
            if (result !== undefined) {
                if (result === 'yes') {
                    const ids = [];

                    for (let i = 0; i < this.selection.selected.length; i++) {
                      ids.push(this.selection.selected[i].id);
                    }
                    this.deleteClick.emit(ids);
                }
            }
        });
  }

  delete(id: any) {
    const dialogRef = this.dialog.open(this.deleteDialog);
    dialogRef.afterClosed().subscribe(result => {
            if (result !== undefined) {
                if (result === 'yes') {
                    this.deleteClick.emit(id);
                }
            }
        });
  }

  click(id: string, route: boolean) {
    if (route) this.rowClick.emit(id);
  }

  void() {
    return;
  }

  handleActionClick(key): void {
    const ids = [];

    for (let i = 0; i < this.selection.selected.length; i++) {
      ids.push(this.selection.selected[i].id);
    }
    this.actionClick.emit({ key, ids });
  }

  handleRowActionClick(key: string, rows: any[]): void {
    this.rowActionClick.emit({ key, rows });
  }

  hasExpanded(row: any, key?: string): boolean {
    if (key) return this.rowItemExpanded[row.id]?.[key];
    return Object.values(this.rowItemExpanded[row.id] || {}).some(v => v);
  }

  isExpanding(row: any, key?: string): boolean {
    if (key) return this.rowItemExpanding[row.id]?.[key];
    return Object.values(this.rowItemExpanding[row.id] || {}).some(v => v);
  }

  async handleExpandRowCol(row: any, key: string) {
    const col = this.displayedColumns.find(c => c.key === key);
    this.rowItemExpanding[row.id] = { ...this.rowItemExpanding[row.id] || {}, [col.key]: true };
    if (!this.rowItemExpanded[row.id]?.[col.key] && col.lineExpand) await col.lineExpand(row);
    this.rowItemExpanded[row.id] = { ...this.rowItemExpanded[row.id] || {}, [col.key]: !this.rowItemExpanded[row.id]?.[col.key] };
    this.rowItemExpanding[row.id] = { ...this.rowItemExpanding[row.id] || {}, [col.key]: false };
  }

  dateRangeChange(key: string, start: HTMLInputElement, end: HTMLInputElement) {
    if (start.value && end.value) {
        const startDate = new Date(new Date(start.value).setHours(0, 0, 0, 0));
        const endDate = new Date(new Date(end.value).setHours(23, 59, 59, 999));
        this.applyFilter(key, { start: startDate, end: endDate });
    }
  }
}
