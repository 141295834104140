import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/services/auth.service';

const COUNTDOWN_SECONDS = 5 * 60;

@Component({
  selector: 'app-session-expiry-banner',
  templateUrl: './sessionExpiryBanner.component.html',
  styleUrls: ['./sessionExpiryBanner.component.scss'],
})
export class SessionExpiryBannerComponent implements OnInit {
    remainingSeconds: number | null = null;
    remainingTimeFriendly: string | null = null;
    interval = null;

    constructor(private _authService: AuthService) { }

    ngOnInit() {
        this.checkExpiry();
    }

    checkExpiry() {
        const secondsUntilExpiry = this._authService.getSecondsUntilTokenExpiry();
        if (secondsUntilExpiry < COUNTDOWN_SECONDS) {
            this.startCountdown(secondsUntilExpiry);
        } else {
            setTimeout(() => this.checkExpiry(), secondsUntilExpiry - COUNTDOWN_SECONDS);
        }
    }

    startCountdown(secondsUntilExpiry: number) {
        this.remainingSeconds = secondsUntilExpiry;
        this.interval = setInterval(() => {
            this.remainingSeconds = this._authService.getSecondsUntilTokenExpiry();
            this.remainingTimeFriendly = Math.floor(this.remainingSeconds / 60) + ':' + ('0' + (this.remainingSeconds % 60)).slice(-2);
            if (this.remainingSeconds <= 0) {
                this._authService.logOut();
                this.stopCountdown();
            } else if (this.remainingSeconds > COUNTDOWN_SECONDS) {
                this.stopCountdown();
            }
        }, 1_000);
    }

    stopCountdown() {
        clearInterval(this.interval);
        this.interval = null;
        this.remainingSeconds = null;
    }

    async resetSession() {
        await this._authService.refreshSession();
        this.stopCountdown();
        this.checkExpiry();
    }
}
