<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-3">
    <h1 class="h2">Bundle Type: <span *ngIf="bundleType">{{bundleType.name}}</span></h1>
</div>

<div *ngIf="loading">loading</div>

<div *ngIf="!loading">
    <div class="row">
        <div class="col-md-12">
            <form class="needs-validation" novalidate="">
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <mat-form-field appearance="fill" style="width:100%">
                            <mat-label>Name</mat-label>
                            <input matInput placeholder="name" name="name" [(ngModel)]="bundleType.name">
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 mb-3">
                        <mat-checkbox name="isCatalog"
                                    [(ngModel)]="bundleType.catalog"
                                    color="primary">
                            Catalog Item
                        </mat-checkbox>
                    </div>
                </div>
                <div class="row">
                    <h2>Asset Types</h2>
                    <app-table [dataSource]="bundleTypeAssetTypes" [displayedColumns]="displayedAssetTypeColumns"
                        (createClick)="addBundleTypeAssetType()" (rowClick)="openAssetType($event)"
                        (deleteClick)="deleteBundleTypeAssetType($event)" *ngIf="bundleTypeId !== 0" [permission]="permissions.BundleTypes"
                        [clickToEdit]="false" (editClick)="editBundleTypeAssetType($event)"
                        uniqueKey="bundleType-assetType"></app-table>
                    <p *ngIf="bundleTypeId === 0">Please save the new Bundle Type first to add Asset Types</p>
                </div>
                <div class="row" style="padding-top:20px;">
                    <h2>Consumables</h2>
                    <app-table [dataSource]="bundleTypeConsumables" [displayedColumns]="displayedConsumableColumns"
                        (createClick)="addBundleTypeConsumable()" (rowClick)="openConsumable($event)"
                        (deleteClick)="deleteBundleTypeConsumable($event)" *ngIf="bundleTypeId !== 0" [permission]="permissions.BundleTypes"
                        [clickToEdit]="false" (editClick)="editBundleTypeConsumable($event)"
                        uniqueKey="bundleType-consumables"></app-table>
                    <p *ngIf="bundleTypeId === 0">Please save the new Bundle Type first to add Consumables</p>
                </div>
                <div class="row mt-5">
                    <div class="col-md-6 mb-3">
                        <button mat-raised-button class="demo-button" color="primary" (click)="save()" [disabled]="bundleType.name === ''">
                            Save
                        </button>
                        &nbsp;
                        <button mat-raised-button class="demo-button" [routerLink]="'/inventory/bundleTypes'">
                            Cancel
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>


<ng-template #assetDialog>
    <h2 matDialogTitle>Asset Type</h2>
    <app-autocomplete 
                    [options]="assetTypes" 
                    [disabled]="assetTypeDisabled"
                    placeHolder="Asset Type" 
                    [value]="bundleTypeAssetTypeModel.assetTypeId"
                    [disabledValue]="bundleTypeAssetTypeModel.assetTypeName"
                    label="Asset Type" 
                    name="assetType" 
                    (optionChange)="onAssetTypeSelect($event)"
                    [control]=assetTypeFormController
                    (blurEvent)="onAssetTypeBlur()">
    </app-autocomplete>
    <app-autocomplete 
                    [url]="'inventory/assetTypes/' + bundleTypeAssetTypeModel.assetTypeId + '/assetModels'" 
                    placeHolder="Model" 
                    [valueForced]="assetTypeModels"
                    [value]="bundleTypeAssetTypeModel.assetTypeModelId"
                    label="Model" 
                    name="assetTypeModel" 
                    (optionChange)="onAssetTypeModelSelect($event)"
                    [disabled]="bundleTypeAssetTypeModel.assetTypeId === 0 || assetModelDisabled"
                    [disabledValue]="bundleTypeAssetTypeModel.assetModelName"
                    [control]=assetModelFormController
                    (blurEvent)="onAssetTypeModelBlur()">
    </app-autocomplete>
    <mat-form-field appearance="fill" style="width:100%">
        <mat-label>Quantity</mat-label>
        <input matInput placeholder="Quantity" name="quantity" [(ngModel)]="bundleTypeAssetTypeModel.quantity" [disabled]="bundleTypeAssetTypeModel.assetTypeId === 0 || bundleTypeAssetTypeModel.assetTypeModelId === 0">
    </mat-form-field>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose="cancel">Cancel</button>
        <button mat-button matDialogClose="save" [disabled]="bundleTypeAssetTypeModel.assetTypeId === 0 || bundleTypeAssetTypeModel.assetTypeModelId === 0 || bundleTypeAssetTypeModel.quantity < 1">Save</button>
    </mat-dialog-actions>
</ng-template>

<ng-template #consumableDialog>
    <h2 matDialogTitle>Consumable</h2>
    <app-autocomplete [options]="consumables" 
                        placeHolder="Consumable" 
                        [value]="bundleTypeConsumableModel.consumableId"
                        label="Consumable" 
                        name="consumables" 
                        (optionChange)="onConsumableSelect($event)"
                        [disabled]="consumableTypeDisabled"
                        [disabledValue]="bundleTypeConsumableModel.consumableName"
                        [control]="consumableFormController"
                        (blurEvent)="onConsumableBlur()">
    </app-autocomplete>
    <mat-form-field appearance="fill" style="width:100%">
        <mat-label>Quantity</mat-label>
        <input matInput placeholder="Quantity" name="quantity" [(ngModel)]="bundleTypeConsumableModel.quantity">
    </mat-form-field>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose="cancel">Cancel</button>
        <button mat-button matDialogClose="save"
        [disabled]="bundleTypeConsumableModel.consumableId === 0 || bundleTypeConsumableModel.quantity <= 0">Save</button>
    </mat-dialog-actions>
</ng-template>