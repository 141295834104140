import { Component, OnInit, ViewChild } from '@angular/core';

import { HttpService } from 'app/services/http.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
 import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-warehouses',
  templateUrl: './warehouses.component.html',
  styleUrls: ['./warehouses.component.scss'],
})
export class WarehousesComponent implements OnInit {

  @ViewChild('warehouseDialog') warehouseDialog: any;
  @ViewChild('yesNoDialog') yesNoDialog: any;
  @ViewChild('errorDialog') errorDialog: any;

  warehouses: any = [];
  loading = false;
  warehouseId = '0';
  warehouseForm: FormGroup;

  displayedColumns: any = [
    { key: 'name', label: 'Name', type: 'string' },
    { key: 'menu', label: 'Actions', type: 'menu' },
  ];
  permissions;
  errorMessage;

  warehouseModel = {
    id: '',
    name: '',
    streetAddress: '',
    stateOrProvince: '',
    city: '',
    postalCode: '',
    countryCode: '',
  };
  dialogRef: MatDialogRef<WarehousesComponent>;


  constructor(private _httpService: HttpService, private _router: Router, private _dialog: MatDialog, private _formBuilder: FormBuilder) { }

  async ngOnInit() {
    await this.load();
  }

  async load() {
    this.loading = true;
    this.warehouses = await this._httpService.get('inventory/warehouses');
    this.permissions = await this._httpService.get('people/authenticated/permissions');
    this.loading = false;
  }

  buildForm() {
    const formGroup = this._formBuilder.group({
      name: new FormControl(this.warehouseModel.name, Validators.required),
      streetAddress: new FormControl(this.warehouseModel.streetAddress, Validators.required),
      stateOrProvince: new FormControl(this.warehouseModel.stateOrProvince, Validators.required),
      city: new FormControl(this.warehouseModel.city, Validators.required),
      postalCode: new FormControl(this.warehouseModel.postalCode, Validators.required),
      countryCode: new FormControl(this.warehouseModel.countryCode, Validators.required),
      id: new FormControl(this.warehouseModel.id),
    });

    return formGroup;
  }

  addWarehouseModel() {
    this.warehouseForm = this.buildForm();
    this.warehouseForm.reset();
    this.dialogRef = this._dialog.open(this.warehouseDialog, { id: 'create' });
  }

  async dialogCloseAction() {
    if (this.warehouseForm.valid) {
        this.loading = true;
        if (this.dialogRef.id === 'create') {
            await this._httpService.post('inventory/warehouses', this.warehouseForm.value);
        } else {
            await this._httpService.put('inventory/warehouses', this.warehouseForm.value);
        }
        this.load();
        this.dialogRef.close();
    }
  }

  async editWarehouseModel(id: number) {
    this.warehouseModel = await this._httpService.get('inventory/warehouses/' + id);
    this.warehouseForm = this.buildForm();

    this.dialogRef = this._dialog.open(this.warehouseDialog, { id: 'edit' });
  }

  async deleteWarehouseModel(id: number) {
    this.loading = true;
    await this._httpService.delete('inventory/warehouses/' + id);
    this.load();
  }


  async openErrorDialog(errorMessage) {
    this.errorMessage = errorMessage;
    this._dialog.open(this.errorDialog);
  }

  async openSiteConfirmDialog() {
    const dialogRef = this._dialog.open(this.yesNoDialog, { autoFocus: false });
    dialogRef.afterClosed().toPromise().then(async result => {
      if (result !== undefined) {
        if (result === 'yes') {
          return true;
        }
      }
      return false;
    });
  }

}
