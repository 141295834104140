<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-3">
    <h1 class="h2">Order</h1>
</div>

<div *ngIf="loading">loading</div>

<mat-tab-group animationDuration="0ms" *ngIf="!loading">

    <form class="needs-validation" novalidate="">

        <mat-tab label="Order Information" class="pb-5 p-5">
            <div class="row scrollContainer tab" *ngIf="!loading">
                <div class="col-md-9 scrollColumn tab">
                    <div class="row">
                        <div>
                            <p class="lead">Order Information</p>
                            <p class="text-muted">Any order that is created must start with a deal. Please select a deal
                                to
                                start an order.</p>
                        </div>
                        <hr />
                        <div class="col-md-4 mb-3">
                            <mat-form-field appearance="fill" style="width:100%">
                                <mat-label>Company</mat-label>
                                <input matInput placeholder="Company" name="company" [(ngModel)]="order.companyName"
                                    disabled>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4 mb-3">
                            <app-autocomplete [options]="deals" placeHolder="Search Deals" [valueForced]="dealCurrent"
                                label="Deal" name="deal" (optionChange)="onDealSelect($event)">
                            </app-autocomplete>
                        </div>
                        <div class="col-md-2 mb-3">
                            <mat-form-field appearance="fill" style="width:100%">
                                <mat-label>Status</mat-label>
                                <input matInput placeholder="Status" name="status" [(ngModel)]="order.status" disabled>
                            </mat-form-field>
                        </div>
                        <div class="col-md-2 mb-3">
                            <mat-checkbox color="primary" [(ngModel)]="order.isProject" name="isProject">
                                Is Project
                            </mat-checkbox>
                        </div>
                        <div class="col-md-12 mb-3">
                            <mat-form-field appearance="fill" style="width:100%">
                                <mat-label>Please describe the details of the order.</mat-label>
                                <textarea matInput placeholder="Name" name="name"
                                    [(ngModel)]="order.description"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div>
                            <p class="lead">Scheduling</p>
                            <p class="text-muted">Please select dates pertaining to the order</p>
                        </div>
                        <div class="col-md-3 mb-6">
                            <mat-form-field appearance="fill" style="width:100%">
                                <mat-label>Created on</mat-label>
                                <input matInput name="createdOn" [matDatepicker]="createdOnPicker"
                                    [(ngModel)]="order.created" disabled>
                                <mat-datepicker-toggle matSuffix [for]="createdOnPicker"></mat-datepicker-toggle>
                                <mat-datepicker #createdOnPicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3 mb-6">
                            <mat-form-field appearance="fill" style="width:100%">
                                <mat-label>Delivery Date</mat-label>
                                <input matInput name="deliveryDate" [matDatepicker]="deliveryDatePicker"
                                    [(ngModel)]="order.deliveryDate">
                                <mat-datepicker-toggle matSuffix [for]="deliveryDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #deliveryDatePicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3 mb-6">
                            <mat-form-field appearance="fill" style="width:100%">
                                <mat-label>Implementation Date</mat-label>
                                <input matInput name="implementationDate" [matDatepicker]="implementationDatePicker"
                                    [(ngModel)]="order.implementationDate">
                                <mat-datepicker-toggle matSuffix [for]="implementationDatePicker">
                                </mat-datepicker-toggle>
                                <mat-datepicker #implementationDatePicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3 mb-6">
                            <mat-form-field appearance="fill" style="width:100%" *ngIf="order.isProject === 1">
                                <mat-label>Return Date</mat-label>
                                <input matInput name="returnDate" [matDatepicker]="returnDatePicker"
                                    [(ngModel)]="order.returnDate">
                                <mat-datepicker-toggle matSuffix [for]="returnDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #returnDatePicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div>
                            <p class="lead">Products</p>
                            <p class="text-muted">Please select products for this order.</p>
                        </div>
                        <hr />
                        <ng-container *ngFor="let product of order.products" class="col">
                            <div class="col-md-12 mb-6">
                                <label class="text-wrap">
                                    <mat-checkbox color="primary" [(ngModel)]="product.enabled" [name]="product.name">
                                        {{product.name}}
                                    </mat-checkbox>
                                </label>
                            </div>
                        </ng-container>
                    </div>
                    <div class="row" style="padding-top:20px">
                        <div>
                            <p class="lead">Documents</p>
                        </div>
                        <hr />
                        <input type="file" class="file-upload" multiple>
                    </div>
                    <hr />
                    <div class="row" style="padding-right:5%">
                        <div class="col-md-11 mb-3">
                            <button mat-raised-button class="demo-button" color="primary" (click)="save(true)"
                                *ngIf="order.status === 'Draft'">
                                Save
                            </button>
                            &nbsp;
                            <button mat-raised-button class="demo-button" color="primary" (click)="save(false)"
                                *ngIf="order.status === 'Draft'">
                                Save and Continue Editing
                            </button>
                            &nbsp;
                            <button mat-raised-button class="demo-button" (click)="cancel()">
                                Cancel
                            </button>
                        </div>
                        <div class="col-md-1 mb-3">
                            <button mat-raised-button class="demo-button" color="primary"
                                *ngIf="order.status === 'Submitted' || order.status === 'Declined'"
                                (click)="moveToDraft()">
                                Move to Draft
                            </button>
                            <button mat-raised-button class="demo-button" color="primary"
                                *ngIf="order.status === 'Draft'" (click)="submit()">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <app-activity [tab]="true" [id]="orderId" [table]="'order'"></app-activity>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="Site Specifications" *ngIf="this.orderId !== 0">
            <div class="row scrollContainer tab" *ngIf="!loading" style="width:100%;padding-right:19%">
                <div class="col-md-12 scrollColumn">
                    <div class="row">
                        <div>
                            <p class="lead">Site Specifications</p>
                            <p class="text-muted">Please add sites and specifications</p>
                        </div>
                        <hr />
                        <app-table [dataSource]="siteSpecifications" [displayedColumns]="siteSpecificationsColumns"
                            [route]="'route'" [checkBoxes]="true" *ngIf="!loading" (rowClick)="onSiteRowClick($event)"
                            (createClick)="addSiteSpecification()" [permission]="permissions.Orders" uniqueKey="site-specification">
                        </app-table>
                    </div>

                    <hr />
                    <div class="row" style="padding-right:5%">
                        <div class="col-md-11 mb-3">
                            <button mat-raised-button class="demo-button" color="primary" (click)="save(true)"
                                *ngIf="order.status === 'Draft'">
                                Save
                            </button>
                            &nbsp;
                            <button mat-raised-button class="demo-button" color="primary" (click)="save(false)"
                                *ngIf="order.status === 'Draft'">
                                Save and Continue Editing
                            </button>
                            &nbsp;
                            <button mat-raised-button class="demo-button" (click)="cancel()">
                                Cancel
                            </button>
                        </div>
                        <div class="col-md-1 mb-3">
                            <button mat-raised-button class="demo-button" color="primary"
                                *ngIf="order.status === 'Submitted' || order.status === 'Declined'"
                                (click)="moveToDraft()">
                                Move to Draft
                            </button>
                            <button mat-raised-button class="demo-button" color="primary"
                                *ngIf="order.status === 'Draft'" (click)="submit()">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
    </form>
</mat-tab-group>

<ng-template #siteSpecificationDialog>
    <h2 matDialogTitle>Site Specification:</h2>
    <h3>Site Information</h3>
    <hr>
    <div class="row">
        <div class="col-md-2 mb-3">
            <mat-form-field appearance="fill" style="width:100%;">
                <mat-label>Order Id</mat-label>
                <input matInput placeholder="Order Id" name="orderId" [(ngModel)]="siteSpecificationEditor.orderId"
                    disabled>
            </mat-form-field>
        </div>
        <div class="col-md-4 mb-3">
            <mat-form-field appearance="fill" style="width:100%;">
                <mat-label>Company Name</mat-label>
                <input matInput placeholder="Company Name" name="companyName"
                    [(ngModel)]="siteSpecificationEditor.companyName" disabled>
            </mat-form-field>
        </div>
        <div class="col-md-6 mb-3">
            <app-autocomplete [options]="sites" placeHolder="Search Sites" label="Site"
                [value]="siteSpecificationEditor.siteId" name="site" (optionChange)="onSiteSelect($event)">
            </app-autocomplete>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 mb-3">
            <mat-form-field appearance="fill" style="width:100%;">
                <mat-label>Site Address</mat-label>
                <input matInput placeholder="Site Address" name="siteAddress"
                    [(ngModel)]="siteSpecificationEditor.siteAddress" disabled>
            </mat-form-field>
        </div>
    </div>
    <h3>Contact Information</h3>
    <hr>
    <div class="row">
        <div class="col-md-4 mb-3">
            <app-autocomplete [options]="contacts" placeHolder="Search Contacts" label="Contact"
                [value]="siteSpecificationEditor.contactId" name="contact" (optionChange)="onContactSelect($event)">
            </app-autocomplete>
        </div>
        <div class="col-md-4 mb-3">
            <mat-form-field appearance="fill" style="width:100%;">
                <mat-label>Contact Email Address</mat-label>
                <input matInput placeholder="Contact Email Address" name="contactEmailAddress"
                    [(ngModel)]="siteSpecificationEditor.contactEmail" disabled>
            </mat-form-field>
        </div>
        <div class="col-md-4 mb-3">
            <mat-form-field appearance="fill" style="width:100%;">
                <mat-label>Contact Phone Number</mat-label>
                <input matInput placeholder="Contact Phone Number" name="contactPhoneNumber"
                    [(ngModel)]="siteSpecificationEditor.contactPhone" disabled>
            </mat-form-field>
        </div>
    </div>
    <h3>Hardware Catalog</h3>
    <hr>
    <div class="row">
        <div class="col-md-2 mb-3" appearance="fill" *ngFor="let catalogItem of siteSpecificationEditor.catalog">
            <mat-label>{{catalogItem.type}}: {{catalogItem.name}}</mat-label>
            <input matInput type="number" placeholder="Enter the Number Needed" min="0" [name]="catalogItem.name"
                [(ngModel)]="catalogItem.count">
        </div>
    </div>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose="cancel">Cancel</button>
        <button mat-button matDialogClose="save" color="Primary" *ngIf="order.status === 'Draft'">Save</button>
        <p *ngIf="order.status !== 'Draft'">Cannot alter site specification<br>Order is in {{order.status}} status</p>
    </mat-dialog-actions>
</ng-template>

<ng-template #errorDialog>
    <p>{{errorMessage}}</p>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose="Okay">Okay</button>
    </mat-dialog-actions>
</ng-template>