import { WaltAccessory, WaltOneEarHeadsetTypes } from '@weavix/models/src/facility/facility';

export class Language {
    id: number;
    name: string;
    abbreviation: string;
}

export class Site {
    id: number;
    name: string;
    companyId: number;
    contactPersonId: number;
    instanceId: number;
    languageId: number;
    shippingAddress?: string;
    address?: string;
    location?: any[];
    waltAccessoriesUsed?: WaltAccessory;
    oneEarHeadsetType?: WaltOneEarHeadsetTypes;
}

export const waltAccessoryToLabelText: { [key in WaltAccessory]: string } = {
    'one-ear-headset': 'One Ear Headset',
    'speaker-mic': 'Speaker Mic',
    'both': 'Both Accessories',
};

export const waltOneEarHeadsetToLableText: { [key in WaltOneEarHeadsetTypes]: string } = {
    'VX400': 'Old',
    'VX400-B': 'New',
};