<div *ngIf="disabled">
    <mat-form-field style="width:100%" appearance="fill">
        <mat-label>{{label}}</mat-label>
        <input matInput name="person" [(ngModel)]="disabledValue" disabled>
        <mat-hint>{{hint}}</mat-hint>
    </mat-form-field>
</div>


<mat-form-field class="example-full-width" appearance="fill" style="width:100%" *ngIf="!disabled" (click)="load()"
    (focus)="load()" (ng-init)="load()">
    <mat-label>{{label}}</mat-label>
    <input type="text" [placeholder]="placeHolder" aria-label="Number" matInput [formControl]="myControl"
        [matAutocomplete]="auto" (click)="load()" (focus)="load()" [disabled]="disabled" (blur)="onBlur()"
        [required]="required">
    <mat-hint>{{hint}}</mat-hint>
    <mat-error *ngIf="showErrors && myControl.invalid">{{ label }} Required</mat-error>
</mat-form-field>
<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
    <mat-option *ngFor="let option of filteredOptions| async" [value]="option" (onSelectionChange)="clicked(option)"
        (click)="clicked(option)">
        {{option[displayKey]}}
    </mat-option>
</mat-autocomplete>
