<div class="container-fluid">
    <app-instance-support-access-table
        *ngIf="tableVm$ | async as vm"
        [permission]="permission"
        [sessions]="vm.sessions"
        [loading]="sessionsLoading"
        (disconnectSessionClicked)="disconnectSession($event)"
        (extendSessionClicked)="extendSession($event)"
    />
    <ng-container *ngIf="formVm$ | async as vm">
        <app-instance-support-access-user-form
            *ngIf="permission >= permissionType.Edit && vm.people.length && vm.me && vm.isEnabled"
            [instance]="instance"
            [people]="vm.people"
            [me]="vm.me"
            (submitClicked)="submitSupportAccess($event)"
        />
        <div *ngIf="vm.isEnabled === false" class="alert alert-warning mt-2">
            Support Access is not permitted for this instance
        </div>
    </ng-container>
</div>
