import { Component, OnInit, ViewChild } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'app/services/http.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-consumable',
  templateUrl: './consumable.component.html',
  styleUrls: ['./consumable.component.scss'],
})
export class ConsumableComponent implements OnInit {

  @ViewChild('stockDialog') stockDialog: any;

  saving = false;
  loading = true;
  consumable: any;
  consumableId: number;

  stock: any = [];
  warehouses: any = [];

  stockModel;

  displayedColumns: any = [
    { key: 'warehouse', label: 'Warehouse', type: 'string' },
    { key: 'warehouseLocation', label: 'Location', type: 'string' },
    { key: 'quantity', label: 'Quantity', type: 'string' },
    { key: 'unitOfMeasure', label: 'Unit Of Measure', type: 'string' },
    { key: 'menu', label: 'Actions', type: 'menu' },
  ];
  permissions;


  constructor(private _rotue: ActivatedRoute, private _httpService: HttpService, private _router: Router, private _dialog: MatDialog) { }

  async ngOnInit() {
    this.consumableId = Number(this._rotue.snapshot.paramMap.get('id'));
    this.resetStockModel();
    this.load();
  }

  async load() {
    this.loading = true;

    this.warehouses = await this._httpService.get('inventory/warehouses');
    this.stock = await this._httpService.get('inventory/consumables/' + this.consumableId + '/stock');
    this.permissions = await this._httpService.get('people/authenticated/permissions');

    if (this.consumableId === 0) {

      this.consumable = {
        name: '',
      };
    } else {

      this.consumable = await this._httpService.get('inventory/consumables/' + this.consumableId);
    }

    this.loading = false;
  }

  async save() {
    this.saving = true;
    if (this.consumableId === 0) await this._httpService.post('inventory/consumables', this.consumable);
    else await this._httpService.put('inventory/consumables', this.consumable);
    this.saving = false;
    this._router.navigateByUrl('/inventory/consumables');
  }

  resetStockModel() {
    this.stockModel = {
      warehouseId: 0,
      quantity: 0,
      consumableId: this.consumableId,
    };
  }

  async addStock() {
    const dialogRef = this._dialog.open(this.stockDialog);
    dialogRef.afterClosed().toPromise().then(async result => {
      if (result !== undefined) {
        if (result === 'save') {
          this.loading = true;
          await this._httpService.post('inventory/consumables/stock', this.stockModel);
          this.resetStockModel();
          this.load();
        } else if (result === 'cancel') {
          this.resetStockModel();
        }
      }
    });
  }

  async editStock(id: number) {

    this.stockModel = await this._httpService.get('inventory/consumables/stock/' + id);

    const dialogRef = this._dialog.open(this.stockDialog);

    dialogRef.afterClosed().toPromise().then(async result => {
      if (result !== undefined) {
        if (result === 'save') {
          this.loading = true;
          await this._httpService.put('inventory/consumables/stock', this.stockModel);
          this.resetStockModel();
          await this.load();
        } else if (result === 'cancel') {
          this.resetStockModel();
        }
      }
    });
  }

  async deleteStock(id: number) {
    this.loading = true;
    await this._httpService.delete('inventory/consumables/stock/' + id);
    this.stock = await this._httpService.get('inventory/consumables/' + this.consumableId + '/stock');
    this.load();
  }
}
