<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center position-relative pt-3 mb-3">
    <h1 class="h2">Site: <span *ngIf="site">{{site.name}}</span></h1>

    <div *ngIf="!loading" class="actions d-flex flex-column justify-content-start align-items-end gap-1">
        <div class="d-flex flex-row justify-content-start gap-3">
            <button
                mat-raised-button
                class="demo-button"
                color="primary"
                [disabled]="isDeletingSavedWifis"
                (click)="isDeleteSavedWifiModalOpen=true">
                Delete All Saved Wifi Networks
            </button>

            <button *ngIf="waltWifiInfo.enabledCount" mat-raised-button class="demo-button" color="primary" (click)="handleEnableDisableWaltWifiClicked(true)">
                <div class="demo-button-label d-flex flex-column justify-content-start align-items-end">
                    <div>Temporarily Disable WiFi</div>
                    <div class="demo-button-info">{{ waltWifiInfo.enabledCount }} of {{ waltWifiInfo.totalCount }} enabled</div>
                </div>
            </button>

            <button *ngIf="waltWifiInfo.disabledCount" mat-raised-button class="demo-button" color="primary" (click)="handleEnableDisableWaltWifiClicked(false)">
                <div class="demo-button-label d-flex flex-column justify-content-start align-items-end">
                    <div>Re-enable WiFi</div>
                    <div class="demo-button-info">{{ waltWifiInfo.disabledCount }} of {{ waltWifiInfo.totalCount }} disabled</div>
                </div>
            </button>
        </div>
        <div *ngIf="waltWifiInfo.disabledCount" class="d-flex flex-row gap-2 opacity-50">
            <span>Currently disabled:</span>
            <span>{{ waltWifiDisabledDateFormatted }}</span>
        </div>
    </div>
</div>

<div *ngIf="loading">loading</div>

<div class="row" *ngIf="!loading">
    <div class="col-md-9 scrollColumn" style="padding-bottom:20px;">
        <form [formGroup]="siteForm">
            <div class="row">
                <div>
                    <p class="lead">Site Information</p>
                    <p class="text-muted">Contact Information.</p>
                </div>
                <hr />
                <div class="col-md-6 mb-3">
                    <mat-form-field appearance="fill" style="width:100%">
                        <mat-label>Site Name</mat-label>
                        <input matInput placeholder="Site Name" name="name" formControlName="name" [required]="true">
                        <mat-error *ngIf="siteForm.get('name').hasError('required')">Name Required</mat-error>
                        <mat-error *ngIf="siteForm.get('name').hasError('siteNameExisting')">Site name already exists</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-3 mb-3">
                    <app-autocomplete name="contactPersonId" label="Contact" [options]="people" [showErrors]="true"
                        [control]="siteForm.get('contactPersonId')" [filterLimit]="100" [value]="site.contactPersonId"
                        displayKey="displayName" [required]="true">
                    </app-autocomplete>
                </div>
                <div class="col-md-3 mb-3">
                    <app-autocomplete name="instanceId" label="weavix™ Instance" [options]="instances" [showErrors]="true"
                        [control]="siteForm.get('instanceId')" [filterLimit]="100" [value]="site.instanceId" [required]="true"
                        [disabled]="site.id !== 0" (blurEvent)="validateName()">
                    </app-autocomplete>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3 mb-3">
                    <app-autocomplete name="languageId" label="Site Language" [options]="languages" [showErrors]="true"
                        [control]="siteForm.get('languageId')" [filterLimit]="100" [value]="site.languageId" [required]="true">
                    </app-autocomplete>
                </div>
            </div>
            <div class="row mt-3">
                <div>
                    <p class="lead">Shipping Address</p>
                    <p class="text-muted">Enter the site's shipping address.</p>
                </div>
                <hr />
                <mat-form-field appearance="fill" style="width:100%">
                    <mat-label>Shipping Address</mat-label>
                    <input matInput placeholder="Shipping Address" name="shippingAddress" formControlName="shippingAddress" [required]="true" (blur)="handleShippingAddressChange()">
                    <mat-error *ngIf="siteForm.get('shippingAddress').hasError('required')">Shipping Address Required</mat-error>
                </mat-form-field>
            </div>
            <div class="row mt-3">
                <div>
                    <p class="lead">Site Address</p>
                    <p class="text-muted">Enter the site's address. This will set the site's address/location in weavix console when changed</p>
                </div>
                <hr />
                <div class="col-md-12 mb-3">
                    <app-address-search
                        #addressSearch
                        [label]="'Address Search'"
                        [placeHolder]="'Search for an address'"
                        [required]="true"
                        [control]="siteForm.get('address')"
                        (placeChange)="handleAddressChange($event)"
                        [showErrors]="true"
                    />
                </div>
            </div>
            <div class="row my-3">
                <div>
                    <p class="lead">Accessories Used</p>
                    <p class="text-muted">Specify which accessories a site is using. When neither or both are selected, walts will auto-detect which headset is being used.</p>
                </div>
                <hr />
                <div class="col-md-12 mb-3">
                    <div class="d-flex flex-column gap-2 w-100">
                        <mat-checkbox *ngFor="let waltAccessory of availableWaltAccessories" 
                            (click)="$event.stopPropagation()" 
                            (change)="toggleWaltAccessorySelection(waltAccessory)"
                            [checked]="selectedWaltAccessories.isSelected(waltAccessory)" 
                            [aria-label]="getWaltAccessoryLabel(waltAccessory)" 
                            color="primary">
                            {{ getWaltAccessoryLabel(waltAccessory) }}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="row my-3">
                <div>
                    <p class="lead">One Ear Headset Type</p>
                    <p class="text-muted">Specify which one ear headset is used on this site. This will enable/disable high decibel safety features</p>
                </div>
                <hr />
                <div class="col-md-12 mb-3">
                    <mat-radio-group
                        class="d-flex flex-column gap-2 w-100"
                        formControlName="oneEarHeadsetType"
                        [required]="true"
                    >
                        <mat-radio-button
                            *ngFor="let oneEarHeadsetType of waltOneEarHeadsetTypes"
                            [value]="oneEarHeadsetType"
                            color="primary"
                        >
                            {{ getWaltOneEarHeadsetLabel(oneEarHeadsetType) }}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>

            <ng-container *ngIf="!loading && permissions['Companies'] === 2">
                <div class="d-flex flex-row justify-content-between">
                    <div class="d-flex flex-row justify-content-start gap-3">
                        <button mat-raised-button class="demo-button" color="primary" (click)="save()" [disabled]="siteForm.invalid || saving">
                            Save
                        </button>
                        <button mat-raised-button class="demo-button" [routerLink]="'/companies/companies/' + this.site.companyId">
                            Cancel
                        </button>
                    </div>
                </div>
            </ng-container>
        </form>
    </div>
</div>

<app-enable-disable-walt-wifis-modal
    [open]="isEnableDisableWaltWifisModalOpen"
    [instanceId]="site.instanceId"
    [siteId]="site.id"
    [disabled]="shouldWaltWifisDisable"
    (submitEvent)="updateWaltWifiInfo()"
    (errorEvent)="isEnableDisableWaltWifisModalOpen=false"
    (closeEvent)="isEnableDisableWaltWifisModalOpen=false"
    (loadingChanged)="isEnablingDisablingWaltWifis=$event"
/>

<app-delete-walt-saved-wifis-modal
    [open]="isDeleteSavedWifiModalOpen"
    [instanceId]="site.instanceId"
    [siteId]="site.id"
    (errorEvent)="isDeleteSavedWifiModalOpen=false"
    (closeEvent)="isDeleteSavedWifiModalOpen=false"
    (loadingChanged)="isDeletingSavedWifis=$event"
/>