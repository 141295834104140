import { Component, OnInit, ViewChild } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'app/services/http.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-asset-type',
  templateUrl: './assetType.component.html',
  styleUrls: ['./assetType.component.scss'],
})
export class AssetTypeComponent implements OnInit {

  @ViewChild('assetModelDialog') assetModelDialog: any;

  saving = false;
  loading = true;
  assetTypeId: number;
  assetType: any;

  assetModels: any;
  assetModel: any;
  permissions;

  displayedColumns: any = [
    { key: 'name', label: 'Name', type: 'string' },
    { key: 'description', label: 'Description', type: 'string' },
    { key: 'menu', label: 'Actions', type: 'menu' },
  ];


  constructor(private _rotue: ActivatedRoute, private _httpService: HttpService, private _router: Router, private _dialog: MatDialog) { }

  async ngOnInit() {
    this.assetTypeId = Number(this._rotue.snapshot.paramMap.get('id'));
    this.assetModel = {
      name: '',
      description: '',
      assetTypeId: this.assetTypeId,
    };

    this.load();
  }

  async load() {
    this.loading = true;

    if (this.assetTypeId === 0) {

      this.assetType = {
        name: '',
      };
      this.assetModels = [];
    } else {

      this.assetType = await this._httpService.get('inventory/assetTypes/' + this.assetTypeId);
      this.assetModels = await this._httpService.get('inventory/assetTypes/' + this.assetTypeId + '/assetModels');
      this.permissions = await this._httpService.get('people/authenticated/permissions');
    }

    this.loading = false;
  }

  async save() {
    this.saving = true;
    if (this.assetTypeId === 0) await this._httpService.post('inventory/assetTypes', this.assetType);
    else await this._httpService.put('inventory/assetTypes', this.assetType);
    this.saving = false;
    this._router.navigateByUrl('/inventory/assetTypes');
  }

  async addAssetModel() {
    const dialogRef = this._dialog.open(this.assetModelDialog);
    dialogRef.afterClosed().toPromise().then(async result => {
      if (result !== undefined) {
        if (result === 'save') {
          this.loading = true;
          await this._httpService.post('inventory/assetModels', this.assetModel);
          this.assetModel = '';
          this.load();
        } else if (result === 'cancel') {
          this.assetModel = '';
        }
      }
    });
  }

  async editAssetModel(id: number) {

    const assetModel = await this._httpService.get('inventory/assetModels/' + id);
    this.assetModel = assetModel;

    const dialogRef = this._dialog.open(this.assetModelDialog);

    dialogRef.afterClosed().toPromise().then(async result => {
      if (result !== undefined) {
        if (result === 'save') {
          this.loading = true;
          await this._httpService.put('inventory/assetModels', this.assetModel);
          const assetModels = await this._httpService.get('inventory/assetTypes/' + this.assetTypeId + '/assetModels');
          this.assetType.catalog = 0;
          for (const model in assetModels) {
            if (assetModels[model].catalog === 1) {
              this.assetType.catalog = 1;
              break;
            }
          }
          this.saving = true;
          await this._httpService.put('inventory/assetTypes', this.assetType);
          this.saving = false;
          this.assetModel = '';
          await this.load();
        } else if (result === 'cancel') {
          this.assetModel = '';
        }
      }
    });
  }

  async deleteAssetModel(id: number) {
    this.loading = true;
    await this._httpService.delete('inventory/assetModels/' + id);
    this.assetModels = await this._httpService.get('inventory/assetTypes/' + this.assetTypeId + '/assetModels');
    this.load();
  }
}
