<ng-template #modalContent>
    <div class="enable-disable-wifis text-center">
        <div class="enable-disable-wifis-close">
            <button mat-icon-button aria-label="Close modal" class="icon-button-small" (click)="closeDialog()">
                <svg xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                    <path
                        d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                </svg>
            </button>
        </div>
        <div class="enable-disable-wifis-title">
            <p [innerHTML]="titleText"></p>
            <p *ngIf="disabled">Wi-Fi will only be disabled for a<br>24-hour window.</p>
        </div>
        <mat-dialog-actions align="center">
            <div class="d-flex flex-row justify-content-between gap-3 w-100">
                <button
                    mat-button
                    (click)="closeDialog()"
                    [disabled]="isSubmitting"
                >Cancel</button>
                <button
                    mat-raised-button
                    (click)="dialogSubmit()"

                    color="primary"
                    [disabled]="isSubmitting"
                >
                    <div class="d-flex flex-row justify-content-center align-items-center gap-2">
                        <span>Yes</span>
                        <mat-spinner *ngIf="isSubmitting" diameter="20"/>
                    </div>
                </button>
            </div>
        </mat-dialog-actions>
    </div>
</ng-template>