import { TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Instance } from 'app/models/control-center/instance.model';
import { PermissionType } from 'app/models/control-center/permission.model';
import { SupportAccessService } from 'app/services/support-access.service';
import { SupportAccessSessionResponse } from '@weavix/models/src/support-access/support-access';
import moment from 'moment';
import { TableComponent, TableFilter, TableFilterType } from 'app/components/table/table.component';
import { uniqBy } from 'lodash';

@Component({
    selector: 'app-instance-records',
    templateUrl: './instance-records.component.html',
    styleUrls: ['./instance-records.component.scss'],
    providers: [TitleCasePipe],
})
export class InstanceRecordsComponent implements OnInit {
    @Input() instance!: Instance;
    @Input() permission!: PermissionType;
    @Output() failedLoad = new EventEmitter<unknown>();
    @ViewChild(TableComponent) supportAccessTable: TableComponent;

    loading = true;
    dataSource: SupportAccessSessionResponse[] = [];
    displayedColumns: any = [
        {
            key: 'status',
            label: 'Status',
            type: 'string',
            value: (row: SupportAccessSessionResponse) => row?.disconnectDate ? 'Disconnected' : 'Active',
        },
        {
            key: 'externalTicket',
            label: 'External Ticket',
            type: 'string',
        },
        {
            key: 'startDate',
            label: 'Access Began',
            type: 'string',
            value: (row: SupportAccessSessionResponse) => row?.startDate ? moment(row.startDate).format('MM/DD/YYYY hh:mm A') : '',
        },
        {
            key: 'disconnectDate',
            label: 'Access Ended',
            type: 'string',
            value: (row: SupportAccessSessionResponse) => row?.disconnectDate ? moment(row.disconnectDate).format('MM/DD/YYYY hh:mm A') : '',
        },
        {
            key: 'supportUserId',
            label: 'Support User',
            type: 'string',
            value: (row: SupportAccessSessionResponse) => this.titleCasePipe.transform(row?.changes?.supportUserName ?? ''),
        },
        {
            key: 'changes',
            label: 'Changes Made',
            width: 550,
            type: 'string',
            canExpand: true,
            lineExpand: async (row: SupportAccessSessionResponse) => {
                await this.getFullChangeLogsFromMap(row.id);
                this.supportAccessTable.forceRowUpdate(row.id);
            },
            value: (row: SupportAccessSessionResponse) => {
                const changes = (this.fullChangeLogsMap.has(row?.id)) ? this.fullChangeLogsMap.get(row?.id)
                    : row?.changes?.changes?.flatMap(c => c.logs);
                return changes?.join('<br>');
            },
        },
    ];

    fullChangeLogsMap = new Map<string, string[]>();
    tableFilters: TableFilter[] = [];

    constructor(
        private readonly titleCasePipe: TitleCasePipe,
        private readonly supportAccessService: SupportAccessService,
    ) { }

    async ngOnInit(): Promise<void> {
        await this.load();
        this.initTableFilters();
    }

    private async load() {
        this.loading = true;
        try {
            this.dataSource = await this.supportAccessService.getAllSessions(this.instance.id, 'en');
        } catch (err: unknown) {
            this.failedLoad.emit(err);
        }
        this.loading = false;
    }

    private initTableFilters() {
        const statusFilter: TableFilter = { key: 'status', name: 'Status', type: TableFilterType.Multiselect, options: [] };
        const userFilter: TableFilter = { key: 'supportUserId', name: 'Support User', type: TableFilterType.Multiselect, options: [] };
        this.dataSource.forEach(session => {
            const status = session.disconnectDate ? 'Disconnected' : 'Active';
            const user = this.titleCasePipe.transform(session.changes?.supportUserName ?? '');
            statusFilter.options = [...(statusFilter.options || []), { label: status, value: status }];
            userFilter.options = [...(userFilter.options || []), { label: user, value: user }];
        });

        statusFilter.options = uniqBy(statusFilter.options.filter(x => x), 'value');
        userFilter.options = uniqBy(userFilter.options.filter(x => x), 'value');

        if (statusFilter.options) {
            statusFilter.show = true;
            this.tableFilters.push(statusFilter);
        }
        if (userFilter.options) {
            userFilter.show = true;
            this.tableFilters.push(userFilter);
        }

        this.tableFilters.push({ key: 'startDate', name: 'Access Began', type: TableFilterType.DateRange, show: true });
    }

    private async getFullChangeLogsFromMap(sessionId: string) {
        if (this.fullChangeLogsMap.has(sessionId)) {
            return;
        }
        const changes = await this.supportAccessService.getAllSessionChanges(this.instance.id, sessionId, 'en', 100_000);
        if (changes?.changes?.length) {
            this.fullChangeLogsMap.set(sessionId, changes.changes.flatMap(c => c.logs));
        }
    }
}
