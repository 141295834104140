import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'app/services/http.service';

@Component({
  selector: 'app-bundles',
  templateUrl: './bundles.component.html',
  styleUrls: ['./bundles.component.scss'],
})
export class BundlesComponent implements OnInit {

  saving = false;
  loading = true;

  displayedColumns: any = [
    { key: 'status', label: 'Status', type: 'badge' },
    { key: 'name', label: 'Name', type: 'string' },
    { key: 'menu', label: 'Actions', type: 'menu' },
  ];

  permissions;
  bundleTypeId: string;
  bundleType: string;
  dataSource: any = [];


  constructor(private _rotue: ActivatedRoute, private _httpService: HttpService, private _router: Router, private route: ActivatedRoute) { }

  async ngOnInit() {
    this.load();
  }

  async load() {
    this.loading = true;
    this.bundleTypeId = this.route.snapshot.params['id'];

    this.bundleType = (await this._httpService.get('inventory/bundleTypes/' + this.bundleTypeId)).name;
    this.dataSource = await this._httpService.get('inventory/bundleTypes/' + this.bundleTypeId + '/bundles');
    this.permissions = await this._httpService.get('people/authenticated/permissions');

    for (let i = 0; i < this.dataSource.length; i++) {
      switch (this.dataSource[i].status) {
        case 'Checked Out':
          this.dataSource[i].color = '#b53737';
          break;
        case 'Available':
          this.dataSource[i].color = '#62bd69';
          break;
        default:
          this.dataSource[i].color = '#aaaaaa';
      }
    }

    this.loading = false;
  }

  add() {
    this._router.navigateByUrl('/inventory/bundles/0?bundleTypeId=' + this.bundleTypeId);
  }

  edit(id: any) {
    this._router.navigateByUrl('/inventory/bundles/' + id);
  }

  async delete(ids: []) {
    this.loading = true;
    const promises = [];

    for (let i = 0; i < ids.length; i++) {
      promises.push(this._httpService.delete(`inventory/bundles/${ids[i]}`));
    }
    await Promise.all(promises);
    await this.load();
  }
}
