import { Component, HostListener, OnInit } from '@angular/core';

import { HttpService } from 'app/services/http.service';
import { Permission, PermissionType } from 'app/models/control-center/permission.model';
import { Router } from '@angular/router';
import { Utils } from './utils';
import { AuthService } from 'app/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  loading = true;
  title = 'weavix-control-center-frontend';
  authenticated = false;

  authenticatedPerson = '';
  authenticatedCompany = '';

  permissions: Permission;
  permissionType = PermissionType;

  characters: string[] = [];
  lastKeypress: number = Date.now();

  constructor(private _httpService: HttpService, private _router: Router, private _authService: AuthService) { }

  async ngOnInit() {
    this.loading = true;
    try {
      const response = await this._httpService.get('authentication/authenticate');
      if (response.status === 'ok') {
        this.authenticated = true;
        this.authenticatedPerson = response.name;
        this.authenticatedCompany = response.company;
        this.permissions = await this._httpService.get('people/authenticated/permissions');
      } else this.authenticated = false;
      this.loading = false;
      return;
    } catch {
      this.authenticated = false;
      this.loading = false;
      return;
    }
  }

  authenticate() {
    this.authenticated = true;
  }

  async logOut() {
    this._authService.logOut();
  }

  // This listens for the scanner on this page only
  @HostListener('window:keyup', ['$event'])
  // listen for any keyup event
  async listenForScanner(event: KeyboardEvent) {
    if (!event.view || event.view.location.href.indexOf('scanner') !== -1) return;
    const result = await Utils.listenForScanner(event, this._httpService, true);
    if (result?.id) this._router.navigateByUrl(`/inventory/assets/${result.id}`);
  }
}
