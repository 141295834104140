<div *ngIf="header" class="container-fluid p-0 mt-5">
    <div class="row m-0">
        <div class="col-12 p-0">
            <p class="lead">{{ header }}</p>
            <p *ngIf="subheader" class="text-muted">{{ subheader }}</p>
        </div>
        <hr />
    </div>
</div>
<div class="table-features w-100" style="padding-top:20px;">
    <div class="d-flex justify-content-start align-items-center" [style.gap.px]="10">
        <div class="flex-grow-1 d-flex justify-content-start align-items-center" [style.gap.px]="10">
            <div class="table-features-search">
                <mat-form-field appearance="fill">
                    <mat-label>Search</mat-label>
                    <input matInput (keyup)="applySearch($event)" placeholder="Type to Search" [value]="previousSearchValue" #input>
                </mat-form-field>
            </div>
            <div class="table-features-filters d-flex justify-content-center align-items-center hide-empty" [style.gap.px]="10">
                <ng-container *ngFor="let filter of filters" >
                    <ng-container [ngSwitch]="filter.type">
                        <ng-container *ngSwitchCase="TableFilterType.Multiselect">
                            <mat-form-field appearance="fill" *ngIf="filter.show">
                                <mat-label>{{ filter.name }}</mat-label>
                                <mat-select multiple [value]="appliedFilters[filter.key] ?? []">
                                    <mat-option *ngFor="let option of filter.options" [value]="option.value" (click)="applyFilter(filter.key, option.value)">{{option.label}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngSwitchCase="TableFilterType.DateRange">
                            <mat-form-field appearance="fill" *ngIf="filter.show">
                                <mat-label>{{filter.name}}</mat-label>
                                <mat-date-range-input [rangePicker]="picker" >
                                    <input matStartDate placeholder="Start Date" [value]="appliedFilters[filter.key]?.start" #start>
                                    <input matEndDate placeholder="End Date" #end [value]="appliedFilters[filter.key]?.end" (dateChange)="dateRangeChange(filter.key, start, end)">
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker></mat-date-range-picker>
                            </mat-form-field>
                        </ng-container>
                    </ng-container>
                    
                </ng-container>
            </div>
        </div>
        <div class="table-features-actions text-right pull-right" *ngIf="permission === permissionType.Edit && showActions">
            <p class="text-right">
                <button mat-button class="demo-button" [matMenuTriggerFor]="menu">Actions</button>
                <mat-menu #menu="matMenu" xPosition="before">
                    <button mat-menu-item *ngIf="showAdd" (click)="create()">Add</button>
                    <button mat-menu-item *ngIf="selection.selected.length !== 0 && showDelete"
                        (click)="deleteMany()">Delete</button>
                    <button mat-menu-item (click)="export()">Export</button>
                    <button mat-menu-item (click)="import()" *ngIf="showImport">Import</button>
                    <span *ngFor="let action of additionalActions">
                        <ng-container *ngIf="
                            (action.requireItemSelection === true && selection.selected.length !== 0) ||
                            action.requireItemSelection === false ||
                            action.requireItemSelection === selection.selected.length
                        ">
                            <button mat-menu-item
                                *ngIf="!action.show || action.show(selection.selected)"
                                (click)="handleActionClick(action.key)"
                            >{{action.name}}</button>
                        </ng-container>
                        
                    </span>
                </mat-menu>
            </p>
        </div>
    </div>
</div>

<div class="mat-elevation-z8 table-container" *ngIf="afterLoadDataSource">
    <table mat-table [dataSource]="afterLoadDataSource" matSort>

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()"
                    color="primary">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)" color="primary">
                </mat-checkbox>
            </td>
        </ng-container>

        <ng-container *ngFor="let column of displayedColumns">

            <ng-container [matColumnDef]="column.key">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="[column.width ? 'w-' + column.width + '-px' : '']"> {{column.label}} </th>
                <td
                    mat-cell
                    *matCellDef="let row"
                    [ngClass]="[ 
                        column.width ? 'w-' + column.width + '-px' : '',
                        column.lineExpand ? 'can-expand' : '',
                        hasExpanded(row) ? 'has-expanded': '',
                    ]"
                    (click)="click(row['id'], column.type!=='menu')"
                    >

                    <div *ngIf="column.type==='date'" class="hide-empty">{{rowValues[row.id][column.key] ? (rowValues[row.id][column.key] | date:'medium') : ''}}</div>

                    <div *ngIf="column.type==='string'" class="hide-empty" [innerHTML]="rowValues[row.id][column.key]"></div>

                    <div *ngIf="column.type==='money'" class="hide-empty">{{rowValues[row.id][column.key] ? (rowValues[row.id][column.key] | currency) : ''}}</div>

                    <div *ngIf="column.type==='boolean'" class="hide-empty">
                        <svg *ngIf="!!rowValues[row.id][column.key]" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                            viewBox="0 0 448 512">
                            <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                            <path [attr.fill]="rowValues[row.id]['color'] ?? 'green'"
                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
                        </svg>
                        <svg *ngIf="!rowValues[row.id][column.key]" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                            viewBox="0 0 320 512">
                            <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                            <path [attr.fill]="rowValues[row.id]['color'] ?? 'red'"
                                d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
                        </svg>
                    </div>

                    <div *ngIf="column.type==='progress'" class="hide-empty" style="padding:10px">
                        <mat-progress-bar mode="determinate" [value]="rowValues[row.id][column.key]"></mat-progress-bar>
                    </div>

                    <div *ngIf="column.type==='badge'" class="hide-empty">
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 512 512">
                            <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                            <path [attr.fill]="rowValues[row.id]['color']"
                                d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z" />
                        </svg>
                        {{rowValues[row.id][column.key]}}
                    </div>

                    <div *ngIf="column.type==='menu'" class="hide-empty">
                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 448 512">
                                <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                <path
                                    d="M120 256C120 286.9 94.93 312 64 312C33.07 312 8 286.9 8 256C8 225.1 33.07 200 64 200C94.93 200 120 225.1 120 256zM280 256C280 286.9 254.9 312 224 312C193.1 312 168 286.9 168 256C168 225.1 193.1 200 224 200C254.9 200 280 225.1 280 256zM328 256C328 225.1 353.1 200 384 200C414.9 200 440 225.1 440 256C440 286.9 414.9 312 384 312C353.1 312 328 286.9 328 256z" />
                            </svg>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="editClick.emit(row['id'])" *ngIf="!clickToEdit">
                                <span>Edit</span>
                            </button>
                            <button mat-menu-item (click)="delete([row['id']])" *ngIf="showDelete">
                                <span>Delete</span>
                            </button>
                        </mat-menu>
                    </div>
                    <div class="no-data">-</div>
                    <div class="expander" [class.loading-dots]="isExpanding(row, column.key)" (click)="handleExpandRowCol(row, column.key)">
                        <ng-container *ngIf="hasExpanded(row, column.key)">See less</ng-container>
                        <ng-container *ngIf="!hasExpanded(row, column.key)">
                            <ng-container *ngIf="isExpanding(row, column.key)">Loading</ng-container>
                            <ng-container *ngIf="!isExpanding(row, column.key)">See more</ng-container>
                        </ng-container>
                    </div>
                </td>

            </ng-container>

            <mat-progress-bar mode="determinate" value="40"></mat-progress-bar>

        </ng-container>

        <ng-container *ngIf="rowActions?.length" matColumnDef="row-actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
                <ng-container *ngFor="let rowAction of rowActions">
                    <button mat-raised-button
                        *ngIf="!rowAction.show || rowAction.show(row)"
                        [color]="rowAction.color ?? 'basic'"
                        (click)="handleRowActionClick(rowAction.key, [row])"
                        class="mx-1"
                    >
                        {{ rowAction.name }}
                    </button>
                </ng-container>
                
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <ng-component>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No results</td>
            </tr>
        </ng-component>
    </table>

    <mat-paginator [pageSizeOptions]="[50, 100, 1000]" showFirstLastButtons aria-label="Select page" *ngIf="paginate">
    </mat-paginator>
</div>

<ng-template #deleteManyDialog>
    <h2 matDialogTitle>Really delete {{selection.selected.length}} item?</h2>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose="no">No</button>
        <button mat-button matDialogClose="yes">Yes</button>
    </mat-dialog-actions>
</ng-template>

<ng-template #deleteDialog>
    <h2 matDialogTitle>Really delete item?</h2>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose="no">No</button>
        <button mat-button matDialogClose="yes">Yes</button>
    </mat-dialog-actions>
</ng-template>
