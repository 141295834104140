<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-3">
    <h1 class="h2">Bundle Type: {{this.bundleType}}</h1>
</div>

<div *ngIf="loading">loading</div>

<div *ngIf="!loading">
    <div class="row">
        <app-table [dataSource]="dataSource" [displayedColumns]="displayedColumns" [route]="'route'" (createClick)="add()"
            (rowClick)="edit($event)" (deleteClick)="delete($event)" [checkBoxes]="true" [permission]="permissions.Bundles" uniqueKey="bundles-{{this.bundleType}}">
        </app-table>
    </div>
</div>