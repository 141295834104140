import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Person } from 'app/models/control-center/person.model';
import { Permission } from 'app/models/control-center/permission.model';

export interface PersonListItem {
  id: number;
  name: string;
  email: string;
  phone: string;
  weavixUserId?: string | null;
  company?: string;
  displayName: string;
}

export interface CredentialResponse {
    status: 'ok' | 'error';
    message?: string;
    details?: {
        reason: string;
        accounts?: string[];
    };
}

@Injectable({
  providedIn: 'root',
})
export class PeopleService {
  constructor(
    private readonly httpService: HttpService,
  ) {}

  getAll(filters?: { permission: keyof Permission }): Promise<PersonListItem[]> {
    const queryParams = !filters ? '' : '?' + new URLSearchParams(filters).toString();
    return this.httpService.get('people' + queryParams);
  }

  getMe(): Promise<Person> {
    return this.httpService.get('people/authenticated');
  }

  get(personId: number): Promise<Person> {
    return this.httpService.get(`people/${personId}`);
  }

  create(person: Person): Promise<Person> {
    return this.httpService.post('people', person);
  }

  removeCredential(personId: number, credential: 'email' | 'phone'): Promise<CredentialResponse> {
    return this.httpService.delete(`people/${personId}/credentials/${credential}`);
  }

  delete(personId: number): Promise<{ status: string }> {
    return this.httpService.delete(`people/${personId}`);
  }
}
