//This is copied from the new-relic.service.ts file in console.

import { Injectable } from '@angular/core';
import { environment } from './environments/environment';

// we tried way too long to get @newrelic/browser-agent to work...
// we are importing the script from index.html instead
declare const newrelic: any;

@Injectable({ providedIn: 'root' })
export class NewRelicService {
    
    static initializeNewRelic() {
        if (!newrelic) return;
        newrelic.setApplicationVersion(environment.version);
        newrelic.addRelease(environment.releaseStage, environment.version);
        newrelic.start();
    }

    static sendError(error: Error) {
        if (!newrelic) return;
        newrelic.noticeError(error);
    }
}