import { HttpService } from 'app/services/http.service';
import * as converter from 'json-2-csv';

export class Utils {

    // characters for scanner
    static characters: string[] = [];

    static formatDate(input: number) {
        return new Date(Math.round(input)).toLocaleString();
    }

    static async downloadCsv(data) {
        const csvData = await converter.json2csvAsync(data);
        const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
        const dwldLink = document.createElement('a');
        const url = URL.createObjectURL(blob);
        const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
        if (isSafariBrowser) { // if Safari open in new window to save file with random filename.
            dwldLink.setAttribute('target', '_blank');
        }
        dwldLink.setAttribute('href', url);
        dwldLink.setAttribute('download', 'export.csv');
        dwldLink.style.visibility = 'hidden';
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }

    static async listenForScanner(event: KeyboardEvent, httpService: HttpService, fromScannerPage = false) {
        // If we're on the scanner page ignore scans here
        if (!fromScannerPage && event.view.location.href.indexOf('scanner') !== -1) return null;
        // Record only characters, numbers, and spaces
        if (event.code.indexOf('Key') > -1 || event.code.indexOf('Digit') > -1 || event.code === 'Space' || event.code === 'Minus') {
            Utils.characters.push(event.key);
            return false;
        } else if (event.key === 'Enter') {
            // once we press enter see if its a serial number. If the array of characters is too long or short or has a space
            // we assume its not a serial number
            if (Utils.characters.indexOf(' ') === -1 && Utils.characters.length > 5 && Utils.characters.length < 30) {
                // we use an array of characters for efficiency, when we're ready to check a serial number we join them into a string
                const serialNumber = Utils.characters.join('').toLowerCase();
                // if we can find a match, direct the user to the assets page
                try {
                    const result = await httpService.get(`inventory/assets?serialNumber=${serialNumber}`);
                    if (result) {
                        Utils.characters = [];
                        if (result) return result;
                        return result;
                    } else {
                        Utils.characters = [];
                        return { serialNumber };
                    }
                } catch (ex) {
                    Utils.characters = [];
                }
            }
            Utils.characters = [];
        }
    }
}

