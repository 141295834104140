<div *ngIf="loading">
    <mat-spinner class="mt-3" diameter="20" />
</div>

<div *ngIf="!loading">
    <div class="row m-0 pb-3">
      <app-table 
        [dataSource]="dataSource"
        [filters]="tableFilters"
        [displayedColumns]="displayedColumns"
        [permission]="permission"
        [showActions]="false"
        uniqueKey="instance-records">
      </app-table>
    </div>
  </div>
