import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnChanges {
    @Input() open = false;
    @Input() title: string;
    @Input() message: string;
    @Input() submitText: string;
    @Input() closeText: string;
    @Input() showSubmit = true;
    @Input() showCancel = true;

    @ViewChild('modalContent') modalContent: any;

    @Output() submitEvent = new EventEmitter();
    @Output() closeEvent = new EventEmitter();

    isSubmitting = false;
    dialogRef: MatDialogRef<ModalComponent>;

    constructor(
        private readonly _dialog: MatDialog,
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if ('open' in changes) {
            if (this.open) {
                this.openDialog();
            } else {
                this.closeDialog();
            }
        }
    }

    private openDialog() {
        this.dialogRef = this._dialog.open(this.modalContent, {
            id: 'modal',
            panelClass: ['my-custom-dialog-class'],
            autoFocus: false,
        });
        this.dialogRef.backdropClick().subscribe(() => this.closeDialog());
        this.dialogRef.afterClosed().subscribe(() => this.onCancel());
    }

    closeDialog() {
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    }

    onCancel() {
        if (this.closeEvent) this.closeEvent.emit();
        this.closeDialog();
    }

    onAccept() {
        if (this.submitEvent) this.submitEvent.emit();
        this.closeDialog();
    }
}