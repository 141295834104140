<mat-form-field class="example-chip-list" appearance="fill" style="width:100%">
    <mat-label>{{label}}</mat-label>
    <mat-chip-list #chipList aria-label="selection" [disabled]="disabled">
      <mat-chip
        *ngFor="let option of selectedOptions"
        (removed)="remove(option)" (click)="handleClick(option)">
        {{option.name}}
        <button matChipRemove>
          x
        </button>
      </mat-chip>
      <input
        placeholder="Add..."
        #chipInput
        [formControl]="control"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{option.name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>