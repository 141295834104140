<mat-spinner *ngIf="loading" diameter="20" class="mt-3" />

<ng-container *ngIf="sessions?.length && !loading && sessions">
    <div class="mt-3">
        <p class="lead">Active Support Users</p>
    </div>
    <app-table
        [dataSource]="sessions"
        [displayedColumns]="displayedColumns"
        [permission]="permission"
        [showActions]="false"
        [rowActions]="rowActions"
        (rowActionClick)="handleTableRowAction($event)"
    />
</ng-container>
