import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Instance } from 'app/models/control-center/instance.model';
import { Person } from 'app/models/control-center/person.model';

interface ActivateInstanceRequest {
  person: Pick<Person, 'name' | 'email' | 'phone'>;
  instanceIds: number[];
}

@Injectable({
  providedIn: 'root',
})
export class InstanceService {
  private readonly baseUrl = 'companies/instances';

  constructor(
    private readonly httpService: HttpService,
  ) {}

  getAll(): Promise<Instance[]> {
    return this.httpService.get(this.baseUrl);
  }

  getById(instanceId: number): Promise<Instance> {
    return this.httpService.get(`${this.baseUrl}/${instanceId}`);
  }

  getByCompanyId(companyId: number): Promise<Instance[]> {
    return this.httpService.get(`companies/${companyId}/instances`);
  }

  getByPersonId(personId: number): Promise<Instance[]> {
    return this.httpService.get(`${this.baseUrl}/person/${personId}`);
  }

  activate(request: ActivateInstanceRequest): Promise<void> {
    return this.httpService.post(`${this.baseUrl}/activate`, request);
  }

  disable(instanceIds: number[]): Promise<void> {
    return this.httpService.put(`${this.baseUrl}/disable`, instanceIds);
  }

  create(instance: Instance): Promise<Instance> {
    return this.httpService.post(this.baseUrl, instance);
  }

  update(instance: Instance): Promise<Instance> {
    return this.httpService.put(this.baseUrl, instance);
  }
}
