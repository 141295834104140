<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-3">
  <h1 class="h2">Companies</h1>
</div>

<div *ngIf="loading">loading</div>

<div *ngIf="!loading">
  <div class="row">
    <app-table 
      [dataSource]="dataSource" 
      [displayedColumns]="displayedColumns" 
      [route]="'route'" [checkBoxes]="true"
      [permission]="permissions['Companies']"
      (rowClick)="rowClick($event)"
      (createClick)="add()"
      (deleteClick)="delete($event)"
      uniqueKey="companies">
    </app-table>
  </div>
</div>