import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Instance } from 'app/models/control-center/instance.model';
import { Person } from 'app/models/control-center/person.model';

@Component({
    selector: 'app-delete-person-modal',
    templateUrl: './delete-person-modal.component.html',
    styleUrls: ['./delete-person-modal.component.scss'],
})
export class DeletePersonModalComponent {
    @Input() open: boolean;
    @Input() person: Person;
    @Input() instances: Instance[];

    @Output() closeEvent = new EventEmitter();
    @Output() submitEvent = new EventEmitter();

    deleteText: string;

    get instanceList(): string {
        return this.instances.map(instance => instance.name).join(', ');
    }

    onCancel() {
        this.open = false;
        this.deleteText = '';
        this.closeEvent.emit();
    }

    onSubmit() {
        this.open = false;
        this.deleteText = '';
        this.submitEvent.emit();
    }
}