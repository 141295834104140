<div *ngIf="isLoading">
    <mat-spinner class="mt-3" diameter="20" />
</div>

<div *ngIf="!isLoading" class="row container-fluid tab" style="margin-bottom: 20px;">
    <div class="col-md-9 mb-9 tab">
        <div style="margin-top: 20px;">
            <div *ngIf="!inProgressDemo">
                <p class="lead">No demo in progress</p>
                <p class="text-muted">Click below to set up a demo</p>
                <div *ngIf="!isSettingUpDemo">
                    <button mat-raised-button (click)="setUpDemo()" color="primary" style="display: inline-block;">
                        Set Up Demo</button>
                </div>
                <div *ngIf="isSettingUpDemo">
                    <mat-spinner class="mt-3" diameter="20" />
                </div>
            </div>

            <div *ngIf="inProgressDemo">
                <div>
                    <p class="lead">A demo is in progress</p>
                    <p class="text-muted">Changes send PTTs and more below</p>
                    <div *ngIf="!inProgressDemo.pttSeedingComplete">
                        <strong>Note: Ptt Conversations are still being seeded. This takes 5 to 10 minutes.</strong>
                        <button
                            mat-raised-button
                            color="primary"
                            (click)="refresh()"
                            style="display: inline-block"
                        >
                            Refresh
                        </button>
                    </div>
                    <div *ngIf="inProgressDemo.failures.length > 0">
                        <div>
                            <strong>There were failures setting up the demo:</strong>
                        </div>
                        <ul *ngFor="let failure of inProgressDemo.failures">
                            <li>{{failure}}</li>
                        </ul>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-3">
                        <div>
                            <strong>Facility</strong>
                            <p>{{inProgressDemo?.facility?.name}}</p>
                        </div>

                        <div>
                            <strong>Channels</strong>
                            <div *ngFor="let channel of inProgressDemo.channels">
                                <li>{{channel.name}}</li>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div>
                            <strong>Alert Types</strong>
                            <div *ngFor="let alertTypes of inProgressDemo.alertTypes">
                                <li>{{alertTypes.name}}</li>
                            </div>
                        </div>

                        <div>
                            <strong>Crafts</strong>
                            <div *ngFor="let craft of inProgressDemo.crafts">
                                <li>{{craft.name}}</li>
                            </div>
                        </div>

                        <div>
                            <strong>Users</strong>
                            <div *ngFor="let user of inProgressDemo.users">
                                <li>{{user.name}}</li>
                            </div>
                        </div>
                    </div>
                </div>


                <div *ngIf="!isSettingUpDemo" style="margin-top: 20px;">
                    <button
                        mat-raised-button
                        (click)="concludeDemo()"
                        style="display: inline-block; background-color: #EF5350; color:white"
                    >
                        End and Clean Up Demo
                    </button>
                </div>
                <div *ngIf="isSettingUpDemo">
                    <mat-spinner class="mt-3" diameter="20" />
                </div>
            </div>
        </div>
        <div *ngIf="inProgressDemo">
            <hr>
            <p>Demo Sound Board</p>
            <div>
                <button *ngIf="!recordingPrompt1" mat-raised-button color="primary"
                    (click)="startRecording('Prompt1')"
                    style="display: inline-block; width: 125px; margin-right: 10px; background-color: #EF5350; color:white;">
                    Record Voice
                </button>
                <button *ngIf="recordingPrompt1" mat-raised-button color="primary"
                    (click)="stopRecording('Prompt1')"
                    style="display: inline-block; width: 125px; margin-right: 10px; background-color: #EF5350; color:white;">
                    Stop Recording
                </button>
                <!-- this is a formatting placeholder for if there is nothing to playback -->
                <button *ngIf="!hasPrompt1Recording" mat-raised-button color="primary"
                    style="display: inline-block; width: 125px; margin-right: 10px; background-color: green; color:white; opacity: 0;">
                </button>
                <button *ngIf="hasPrompt1Recording" mat-raised-button color="primary"
                    (click)="playAudioRecording('Prompt1')"
                    style="display: inline-block; width: 125px; margin-right: 10px; background-color: green; color:white;">
                    Playback
                </button>
                <button mat-raised-button color="primary"
                    (click)="sendPrerecordedPtt('Prompt1', maintenanceChannelId)"
                    [disabled]="disablePrompts"
                    style="display: inline-block; width: 200px;">
                    Prompt 1
                </button>
                <p style="display: inline-block; margin-left: 20px;">Hello from Wichita - This will send a message to the Maintenance group</p>
            </div>
            <div>
                <button mat-raised-button color="primary"
                    style="display: inline-block; width: 125px; margin-right: 10px; background-color: #EF5350; color:white; opacity: 0;"
                    disabled="true">
                    Record Voice
                </button>
                <button mat-raised-button color="primary"
                    style="display: inline-block; width: 125px; margin-right: 10px; background-color: green; color:white; opacity: 0;"
                    disabled="true">
                    Playback
                </button>
                <button mat-raised-button color="primary"
                    (click)="sendSpoofPtt('Esto es increíble', maintenanceChannelId, 'es')"
                    [disabled]="disablePrompts"
                    style="display: inline-block; width: 200px;">
                    Prompt 2
                </button>
                <p style="display: inline-block; margin-left: 20px;">This is awesome (spanish) - This will send a message to the Maintenance group</p>
            </div>
            <div>
                <button *ngIf="!recordingPrompt3" mat-raised-button color="primary"
                    (click)="startRecording('Prompt3')"
                    style="display: inline-block; width: 125px; margin-right: 10px; background-color: #EF5350; color:white;">
                    Record Voice
                </button>
                <button *ngIf="recordingPrompt3" mat-raised-button color="primary"
                    (click)="stopRecording('Prompt3')"
                    style="display: inline-block; width: 125px; margin-right: 10px; background-color: #EF5350; color:white;">
                    Stop Recording
                </button>
                <!-- this is a formatting placeholder for if there is nothing to playback -->
                <button *ngIf="!hasPrompt3Recording" mat-raised-button color="primary"
                    style="display: inline-block; width: 125px; margin-right: 10px; background-color: green; color:white; opacity: 0;">
                </button>
                <button *ngIf="hasPrompt3Recording" mat-raised-button color="primary"
                    (click)="playAudioRecording('Prompt3')"
                    style="display: inline-block; width: 125px; margin-right: 10px; background-color: green; color:white;">
                    Playback
                </button>
                <button mat-raised-button color="primary" (click)="sendPrerecordedPtt('Prompt3', maintenanceChannelId)"
                    [disabled]="disablePrompts"
                    style="display: inline-block; width: 200px;">
                    Prompt 3
                </button>
                <p style="display: inline-block; margin-left: 20px;">Go ahead - This will send a message to the Maintenance group</p>
            </div>
            <div>
                <button *ngIf="!recordingPrompt4" mat-raised-button color="primary"
                    (click)="startRecording('Prompt4')"
                    style="display: inline-block; width: 125px; margin-right: 10px; background-color: #EF5350; color:white;">
                    Record Voice
                </button>
                <button *ngIf="recordingPrompt4" mat-raised-button color="primary"
                    (click)="stopRecording('Prompt4')"
                    style="display: inline-block; width: 125px; margin-right: 10px; background-color: #EF5350; color:white;">
                    Stop Recording
                </button>
                <!-- this is a formatting placeholder for if there is nothing to playback -->
                <button *ngIf="!hasPrompt4Recording" mat-raised-button color="primary"
                    style="display: inline-block; width: 125px; margin-right: 10px; background-color: green; color:white; opacity: 0;">
                </button>
                <button *ngIf="hasPrompt4Recording" mat-raised-button color="primary"
                    (click)="playAudioRecording('Prompt4')"
                    style="display: inline-block; width: 125px; margin-right: 10px; background-color: green; color:white;">
                    Playback
                </button>
                <button mat-raised-button color="primary"
                    (click)="sendPrerecordedPtt('Prompt4', maintenanceChannelId)"
                    [disabled]="disablePrompts"
                    style="display: inline-block; width: 200px;">
                    Prompt 4
                </button>
                <p style="display: inline-block; margin-left: 20px;">10 - 4. Im on my way. - This will send a message to the Maintenance group</p>
            </div>
            <div>
                <button mat-raised-button color="primary"
                    style="display: inline-block; width: 125px; margin-right: 10px; background-color: #EF5350; color:white; opacity: 0;"
                    disabled="true">
                    Record Voice
                </button>
                <button mat-raised-button color="primary"
                    style="display: inline-block; width: 125px; margin-right: 10px; background-color: green; color:white; opacity: 0;"
                    disabled="true">
                    Playback
                </button>
                <button mat-raised-button
                    (click)="sendDemoMassAlert('Fire in Plant 1. Go to muster point A.', true)"
                    [disabled]="disablePrompts"
                    [ngStyle]="disablePrompts ? { 'width': '200px' } : {display: 'inline-block', width: '200px', backgroundColor: '#EF5350', color: 'white'}">
                    Prompt 5
                </button>
                <p style="display: inline-block; margin-left: 20px;">Fire in Plant 1... - This will send a Mass Alert</p>
            </div>
            <div>
                <button *ngIf="!recordingPrompt6" mat-raised-button color="primary"
                    (click)="startRecording('Prompt6')"
                    style="display: inline-block; width: 125px; margin-right: 10px; background-color: #EF5350; color:white;">
                    Record Voice
                </button>
                <button *ngIf="recordingPrompt6" mat-raised-button color="primary"
                    (click)="stopRecording('Prompt6')"
                    style="display: inline-block; width: 125px; margin-right: 10px; background-color: #EF5350; color:white;">
                    Stop Recording
                </button>
                <!-- this is a formatting placeholder for if there is nothing to playback -->
                <button *ngIf="!hasPrompt6Recording" mat-raised-button color="primary"
                    style="display: inline-block; width: 125px; margin-right: 10px; background-color: green; color:white; opacity: 0;">
                </button>
                <button *ngIf="hasPrompt6Recording" mat-raised-button color="primary"
                    (click)="playAudioRecording('Prompt6')"
                    style="display: inline-block; width: 125px; margin-right: 10px; background-color: green; color:white;">
                    Playback
                </button>
                <button mat-raised-button color="primary" (click)="sendPrerecordedPtt('Prompt6', everyoneChannelId)"
                    [disabled]="disablePrompts"
                    style="display: inline-block; width: 200px;">
                    Prompt 6
                </button>
                <p style="display: inline-block; margin-left: 20px;">Hey Emily - This will send a message to the Everyone group</p>
            </div>
            <div>
                <button mat-raised-button color="primary"
                    style="display: inline-block; width: 125px; margin-right: 10px; background-color: #EF5350; color:white; opacity: 0;"
                    disabled="true">
                    Record Voice
                </button>
                <button mat-raised-button color="primary"
                    style="display: inline-block; width: 125px; margin-right: 10px; background-color: green; color:white; opacity: 0;"
                    disabled="true">
                    Playback
                </button>
                <button mat-raised-button
                    (click)="updateFacilitySettings({music:{enabled:true, headphonesRequired:false, maxVolume: 0.4, bannedGeofenceIds:[]}})"
                    [disabled]="disablePrompts"
                    [ngStyle]="disablePrompts ? { 'width': '200px' } : {'display': 'inline-block', 'width': '200px', 'background-color': 'deeppink', 'color': 'white'}">
                    Prompt 7
                </button>
                <p style="display: inline-block; margin-left: 20px;">Set music volume to 40%</p>
            </div>
            <div> 
                <button mat-raised-button color="primary"
                    style="display: inline-block; width: 125px; margin-right: 10px; background-color: #EF5350; color:white; opacity: 0;"
                    disabled="true">
                    Record Voice
                </button>
                <button mat-raised-button color="primary"
                    style="display: inline-block; width: 125px; margin-right: 10px; background-color: green; color:white; opacity: 0;"
                    disabled="true">
                    Playback
                </button>
                <button mat-raised-button
                    (click)="updateFacilitySettings({music:{enabled:true, headphonesRequired:true, maxVolume: 0.8, bannedGeofenceIds:[]}})"
                    [disabled]="disablePrompts"
                    [ngStyle]="disablePrompts ? { 'width': '200px' } : {'display': 'inline-block', 'width': '200px', 'background-color': 'deeppink', 'color': 'white'}">
                    Prompt 8
                </button>
                <p style="display: inline-block; margin-left: 20px;">Set music volume to 80% and require headphones</p>
            </div>
            <div>
                <button *ngIf="!recordingPrompt9" mat-raised-button color="primary"
                    (click)="startRecording('Prompt9')"
                    style="display: inline-block; width: 125px; margin-right: 10px; background-color: #EF5350; color:white;">
                    Record Voice
                </button>
                <button *ngIf="recordingPrompt9" mat-raised-button color="primary"
                    (click)="stopRecording('Prompt9')"
                    style="display: inline-block; width: 125px; margin-right: 10px; background-color: #EF5350; color:white;">
                    Stop Recording
                </button>
                <!-- this is a formatting placeholder for if there is nothing to playback -->
                <button *ngIf="!hasPrompt9Recording" mat-raised-button color="primary"
                    style="display: inline-block; width: 125px; margin-right: 10px; background-color: green; color:white; opacity: 0;">
                </button>
                <button *ngIf="hasPrompt9Recording" mat-raised-button color="primary"
                    (click)="playAudioRecording('Prompt9')"
                    style="display: inline-block; width: 125px; margin-right: 10px; background-color: green; color:white;">
                    Playback
                </button>
                <button mat-raised-button color="primary"
                    (click)="sendPrerecordedPtt('Prompt9', everyoneChannelId)"
                    [disabled]="disablePrompts"
                    style="display: inline-block; width: 200px;">
                    Prompt 9
                </button>
                <p style="display: inline-block; margin-left: 20px;">Pretty cool huh - This will send a message to the Everyone group</p>
            </div>
        </div>
    </div>
    <div class="col-md-3" *ngIf="instance" style="margin-top: 20px;">
        <app-activity [tab]="true" [id]="instance.id" table="instance" />
    </div>
</div>