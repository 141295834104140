<app-modal
    [title]="'Delete Person - ' + person?.name"
    [showCancel]="false"
    [showSubmit]="false"
    [open]="open"
    (closeEvent)="onCancel()"
>
    <span>Are you sure you want to delete {{person?.name}} from weavix?</span>
    <br/>
    <span class="font-bold">NOTE: THIS ACTION CANNOT BE UNDONE!</span>
    <br/>
    <br/>
    <span *ngIf="!instances?.length">User is not on any instances.</span>
    <span *ngIf="instances?.length">
        User is on the following instance(s): {{instanceList}}
    </span>
    <br/>
    <br/>
    <div class="delete-container">
        <span class="description">Type 'DELETE' to continue:</span>
        <input matInput [(ngModel)]="deleteText" class="delete-text-box" placeholder="DELETE">
    </div>
    <mat-dialog-actions align="end">
            <button
                mat-raised-button
                (click)="onCancel()"
            >
                CANCEL
            </button>
            <button
                mat-raised-button
                (click)="onSubmit()"
                color="primary"
                [disabled]="deleteText !== 'DELETE'"
            >
                DELETE
            </button>
    </mat-dialog-actions>
</app-modal>