export class Person {
    id?: number;
    name: string;
    companyId: string;
    email: string;
    awaitingEmailOwnership: boolean;
    phone: string;
    awaitingPhoneOwnership: boolean;
    hubSpotContactId?: string;
    hubSpotOwnerId?: string;
    hubSpotUserId?: string;
    zendeskUserId?: string;
    weavixUserId?: string;
    lastCity?: string;
    lastRegion?: string;
    lastCountry: string;
}