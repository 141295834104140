<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-3">
  <h1 class="h2">People</h1>
</div>

<div *ngIf="loading">loading</div>

<div *ngIf="!loading">
  <div class="row">
    <app-table
        [dataSource]="people"
        [displayedColumns]="displayedColumns"
        [permission]="permissions['People']"
        [checkBoxes]="true"
        (rowClick)="rowClick($event)"
        (createClick)="add()"
        uniqueKey="people"
        [showDelete]="false"
    >
    </app-table>
  </div>
</div>