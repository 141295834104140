import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class UnauthenticatedInterceptor implements HttpInterceptor {
    constructor(private _authService: AuthService) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            tap({
                error: (err: unknown) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 401) {
                            this._authService.logOut();
                        }
                    }
                },
            }),
        );
    }
}
