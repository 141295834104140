import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { SessionChangesResponse, SupportAccessSessionResponse } from '@weavix/models/src/support-access/support-access';

export interface CreateSessionRequest {
  supportPersonId: number;
  reason: string;
  externalTicket?: string;
  hours: number;
}

export interface ActiveSupportAccessSession {
  id: string;
  personName: string;
  expectedEndDate: Date;
}

interface ExtendSessionRequest {
  hours: number;
}

interface IsEnabledResponse {
  supportAccess: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SupportAccessService {
  private baseUrl(instanceId: number) {
    return `companies/instances/${instanceId}/support-access`;
  }

  constructor(
    private readonly httpService: HttpService,
  ) {}

  async getIsEnabled(instanceId: number): Promise<IsEnabledResponse> {
    return await this.httpService.get(`${this.baseUrl(instanceId)}/is-enabled`);
  }

  async getActiveSessions(instanceId: number): Promise<ActiveSupportAccessSession[]> {
    const sessions: any[] = await this.httpService.get(`${this.baseUrl(instanceId)}/active-sessions`);
    return sessions.map(s => ({ ...s, expectedEndDate: new Date(s.expectedEndDate) }));
  }

  async getAllSessions(instanceId: number, changesLocale?: string): Promise<SupportAccessSessionResponse[]> {
    return await this.httpService.get(`${this.baseUrl(instanceId)}/sessions`, { changesLocale });
  }

  async getAllSessionChanges(instanceId: number, sessionId: string, changesLocale: string, changesLimit: number): Promise<SessionChangesResponse> {
    return await this.httpService.get(`${this.baseUrl(instanceId)}/sessions/${sessionId}`, { changesLocale, changesLimit });
  }

  async createSession(instanceId: number, request: CreateSessionRequest) {
    await this.httpService.post(`${this.baseUrl(instanceId)}/sessions`, request);
  }

  async disconnectSession(instanceId: number, sessionId: string) {
    await this.httpService.put(`${this.baseUrl(instanceId)}/sessions/${sessionId}/disconnect`, {});
  }

  async extendSession(instanceId: number, sessionId: string, request: ExtendSessionRequest) {
    await this.httpService.post(`${this.baseUrl(instanceId)}/sessions/${sessionId}/extensions`, request);
  }
}
