<div *ngIf="disabled">
    <mat-form-field class="width100" appearance="fill">
        <mat-label>{{label}}</mat-label>
        <input matInput name="address" [(ngModel)]="disabledValue" disabled>
        <mat-hint>{{hint}}</mat-hint>
    </mat-form-field>
</div>


<mat-form-field class="example-full-width width100" appearance="fill" *ngIf="!disabled">
    <mat-label>{{label}}</mat-label>
    <input
        #search
        type="text"
        [placeholder]="placeHolder"
        aria-label="Number"
        matInput
        [formControl]="myControl"
        [disabled]="disabled"
        (focus)="editing = true"
        (blur)="onBlur()"
        [required]="required"
        (keydown.enter)="$event.preventDefault()"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
        autocomplete="off"
    >
    <mat-hint>{{hint}}</mat-hint>
    <mat-error *ngIf="showErrors && myControl.invalid">{{ label }} Required</mat-error>
</mat-form-field>
