import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

export interface AuthToken {
    personId: number;
    iat: number;
    exp: number;
}

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(private _httpService: HttpService) { }

    async loginPart1(email: string) {
        return await this._httpService.postNoAuth('authentication/request', {
            email,
        });
    }

    async loginPart2(email: string, code: string) {
        const result = await this._httpService.postNoAuth('authentication/validate', {
            email,
            code,
        });
        if (result.status === 'ok') {
            localStorage.setItem('token', result.body.token);
            window.location.href = '/';
        }
        return result;
    }

    async logOut() {
        if (!localStorage.getItem('token')) return;
        try {
            await this._httpService.post('authentication/logout', {});
        } catch (ex) {
            console.error(`Logout http error`, ex);
        } finally {
            localStorage.removeItem('token');
            location.reload();
        }
    }

    isLoggedIn(): boolean {
        return !!localStorage.getItem('token');
    }

    getSecondsUntilTokenExpiry(): number {
        const token = this.getTokenInfo();
        const tokenExpiry = token.exp;
        const now = Math.floor(new Date().getTime() / 1000);
        return tokenExpiry - now;
    }

    getTokenInfo(): AuthToken {
        const token = localStorage.getItem('token');
        if (!token || !(token.split('.').length === 3)) {
            throw new Error('Token not found');
        }

        return JSON.parse(atob(token.split('.')[1])) as AuthToken;
    }

    async refreshSession(): Promise<void> {
        try {
            const result = await this._httpService.post('authentication/refresh', {});
            localStorage.setItem('token', result.body.token);
        } catch (ex) {
            console.error(`Refresh token error`, ex);
        }
    }

    isAuthUrl(url: string): boolean {
        return url.includes('/authentication/');
    }
}
