<ng-template #pullLogsDialog>
    <form [formGroup]="logsForm" class="pullLogsForm">
        <h2 matDialogTitle>Pull Logs:</h2>

        <mat-form-field appearance="fill" class="matFormField">
            <mat-label>Describe the Issue</mat-label>
            <input matInput placeholder="Please be as detailed as possible and include an estimated time of occurence." name="warehouseName" formControlName="reason">
        </mat-form-field>

        <div class="checkboxContainer">
            <mat-checkbox class="checkbox" formControlName="zendesk" [checked]="logsForm.value.zendesk" color="primary"></mat-checkbox>
            <mat-label class="label">Create a Zendesk ticket</mat-label>
        </div>

        <mat-dialog-actions align="end">
            <button mat-button (click)="closeDialog()">Cancel</button>
            <button mat-raised-button (click)="dialogSubmit()" color="primary" [disabled]="!logsForm.valid">Submit</button>
        </mat-dialog-actions>
    </form>
</ng-template>