import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { environment } from 'environments/environment';

// 30 minute cache age
const MAX_CACHE_AGE_MS = 1800000;
const ROUTES_TO_CACHE_REGEX: RegExp[] = [
    /^people$/,
];

@Injectable({
  providedIn: 'root',
})

export class HttpService {

  constructor(private _httpClient: HttpClient) { }

  private static cacheMap: Record<string, Record<string, { data: unknown, expires: number }>> = {};

  static clearCache(context: string = null) {
    if (context) {
        if (HttpService.cacheMap[context]) {
            Object.keys(HttpService.cacheMap[context]).forEach(key => {
                delete HttpService.cacheMap[context][key];
            });
        }
    } else {
        HttpService.cacheMap = {};
    }
  }

  static getContext(request: string) {
    return request.split('/')[0];
  }

  static shouldCacheGet(url) {
    return ROUTES_TO_CACHE_REGEX.some(x => {
        return x.test(url);
    });
  }

  async postNoAuth(url: string, body: any): Promise<any> {
    HttpService.clearCache(HttpService.getContext(url));
    return await this._httpClient.post(environment.url + url, body).toPromise();
  }

  async get(url: string, params?: { [param: string]: any }): Promise<any> {
    const context = HttpService.getContext(url);
    if (this.checkCache(context, url)) {
        return cloneDeep(HttpService.cacheMap[context][url].data);
    }
    const resp = await this._httpClient.get(environment.url + url, { ...this.getOptions(), params }).toPromise();
    if (HttpService.shouldCacheGet(url)) {
        HttpService.cacheMap[context][url] = { data: resp, expires: new Date().getTime() + MAX_CACHE_AGE_MS };
    }
    return resp;
  }

  async post(url: string, body: any): Promise<any> {
    HttpService.clearCache(HttpService.getContext(url));
    return await this._httpClient.post(environment.url + url, body, this.getOptions()).toPromise();
  }

  async postForm(url: string, formData: FormData): Promise<any> {
    HttpService.clearCache(HttpService.getContext(url));
    return await this._httpClient.post(environment.url + url, formData, this.getOptions()).toPromise();
  }

  async put(url: string, body: any): Promise<any> {
    HttpService.clearCache(HttpService.getContext(url));
    return await this._httpClient.put(environment.url + url, body, this.getOptions()).toPromise();
  }

  async delete(url: string): Promise<any> {
    HttpService.clearCache(HttpService.getContext(url));
    return await this._httpClient.delete(environment.url + url, this.getOptions()).toPromise();
  }

  private checkCache(context: string, endpoint: string): boolean {
      if (!context) return false;

      if (!HttpService.cacheMap[context]) HttpService.cacheMap[context] = {};

      if (!HttpService.cacheMap[context][endpoint]) return false;
      if (HttpService.cacheMap[context][endpoint].expires < new Date().getTime()) return false;

      return true;
  }

  private getOptions() {
    return {
        headers: {
            authorization: localStorage.getItem('token') ?? '',
        },
    };
  }

}
