import { Component, HostListener, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'app/services/http.service';
import { AssetStatuses } from 'app/models/control-center/asset.model';
import { Person } from 'app/models/control-center/person.model';
import { Site } from 'app/models/control-center/site.model';
import { Warehouse } from 'app/models/control-center/warehouse.model';
import { Utils } from 'app/utils';

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss'],
})
export class ScannerComponent implements OnInit {

  loading = false;
  scanning = false;
  scanRunning = false;
  scanResults: Array<{
    serialNumber: string;
    result: string;
    success: boolean;
    time: number;
  }>;
  scanResultsSuccessful: number;
  scanResultsFailed: number;
  bulkUpdateStatuses: {
    status?: AssetStatuses;
    assetIds?: number[];
    warehouseId?: number;
    warehouseLocation?: string;
    personId?: number;
    siteId?: number;
  } = {};
  assetStatuses = AssetStatuses;
  warehouses: Warehouse[] = [];
  people: Person[] = [];
  sites: Site[] = [];

  constructor(private _rotue: ActivatedRoute, private _httpService: HttpService, private _router: Router) { }

  async ngOnInit() {

    await Promise.all([
      (async () => this.warehouses = await this._httpService.get('inventory/warehouses'))(),
      (async () => this.people = await this._httpService.get('people'))(),
      (async () => this.sites = await this._httpService.get('companies/sites'))(),
    ]);
  }

  // This listens for the scanner on this page only
  @HostListener('window:keyup', ['$event'])
  // listen for any keyup event
  async listenForScanner(event: KeyboardEvent) {
    if (this.scanning) {
      const result = await Utils.listenForScanner(event, this._httpService, true);
      if (result) {
        if (result.id) {
          const duplicate = this.scanResults.find(sr => sr.serialNumber === result.serialNumber);
          if (duplicate) {
            this.scanResults.unshift({
              serialNumber: result.serialNumber,
              success: false,
              result: 'Duplicate Scan',
              time: Date.now(),
            });
            this.scanResultsFailed += 1;
          } else {
            this.bulkUpdateStatuses.assetIds[0] = result.id;
            try {
              await this._httpService.put('inventory/assets/statuses', this.bulkUpdateStatuses);
              this.scanResults.unshift({
                serialNumber: result.serialNumber,
                success: true,
                result: 'Successfully Updated',
                time: Date.now(),
              });
              this.scanResultsSuccessful += 1;
            } catch (ex) {
              this.scanResults.unshift({
                serialNumber: result.serialNumber,
                success: false,
                result: 'Update Error',
                time: Date.now(),
              });
              this.scanResultsFailed += 1;
            }
          }
        } else {
          this.scanResults.unshift({
            serialNumber: result.serialNumber,
            success: false,
            result: 'Invalid Serial Number',
            time: Date.now(),
          });
          this.scanResultsFailed += 1;
        }
      }
    }

  }

  start() {
    this.scanResults = [];
    this.scanning = true;
    this.bulkUpdateStatuses.assetIds = [];
    this.scanResultsFailed = 0;
    this.scanResultsSuccessful = 0;
  }

  stop() {
    this.scanning = false;
  }
}
