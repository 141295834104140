<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-3">
    <h1 class="h2">Bundle: <span *ngIf="bundle">{{bundle.name}}</span></h1>
</div>

<div *ngIf="loading">loading</div>

<div *ngIf="!loading">
    <div class="row">
        <div>
            <p class="lead">Status</p>
            <p class="text-muted">The status is where the bundle is in it's lifecycle. If this bundle has attached
                assets, status changes will
                cascade.</p>
        </div>
        <hr />
        <div class="col-md-8 mb-3">
            <mat-form-field appearance="fill" style="width:100%">
                <mat-label>Status</mat-label>
                <mat-select [(ngModel)]="bundle.status" [formControl]="statusControl"
                    [disabled]="bundle.orderId !== null && ['Packing','In Transit'].includes(bundle.status)">
                    <mat-option value="Available" *ngIf="bundleId !== 0">
                        Available | <small>Bundle is ready for use.</small>
                    </mat-option>
                    <mat-option value="Checked Out" *ngIf="bundleId !== 0">
                        Checked Out | <small>Bundle is checked out to a specific
                            person.</small> 
                    </mat-option>
                    <mat-option value="Decommissioned" *ngIf="bundleId !== 0">
                        Decommissioned | <small>Bundle is no longer usable and
                            will be discarded.</small>
                    </mat-option>
                    <mat-option value="Provisioning">
                        Provisioning | <small>Bundle requires verification before
                            it is ready for use</small>
                    </mat-option>
                    <mat-option value="Packing" [ngStyle]="{ display : 'none' }">
                        Fulfillment Packing | <small>Bundle has been included in an order fulfillment</small>
                    </mat-option>
                    <mat-option value="In Transit" [ngStyle]="{ display : 'none' }">
                        Fulfillment In Transit | <small>Bundle's fulfillment is in transit to destination</small>
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="statusControl.invalid">Status Required</mat-error>
                <!-- <mat-hint *ngIf="asset.parentAssetId"> Inherited from {{asset.parentAsset}}</mat-hint> -->
            </mat-form-field>
        </div>
        <div class="col-md-2 mb-3">
            <button mat-button 
                *ngIf="bundle.orderId !== null"
                color="primary" 
                [routerLink]="'/inventory/fulfillments'" [queryParams]="{'orderId': bundle.orderId}">Order ID: {{bundle.orderId}}</button>
        </div>
        <div class="col-md-2 mb-3">
            <button mat-button 
                *ngIf="bundle.fulfillmentId !== null"
                color="primary" 
                [routerLink]="'/inventory/fulfillments/' + bundle.fulfillmentId">Fulfillment ID: {{bundle.fulfillmentId}}</button>
        </div>
        <div class="row" *ngIf="bundleId !== 0">
            <p class="lead">
                Complete Bundle 
                <svg *ngIf="completeness['complete'] === true" fill="green" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 448 512">
                    <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path 
                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
                </svg>
                <svg *ngIf="completeness['complete'] === false" fill="red" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 320 512">
                    <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                        d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
                </svg>
            </p>
            <div class="col-md-12">
                <table class="col-md-12 mb-3">
                    <tr>
                        <th>Component</th>
                        <th>Required</th>
                        <th>Allocated</th>
                    </tr>
                    <tr *ngFor="let component of completeness.components  | keyvalue">
                        <td>
                            {{component.key}}
                        </td>
                        <td>
                            {{component.value['required']}}
                        </td>
                        <td>
                            {{component.value['allocated']}}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>

    <div class="row">
        <div>
            <p class="lead">General Information</p>
            <p class="text-muted">General information about the Bundle.</p>
        </div>
        <hr />
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-4 mb-3">
                    <mat-form-field appearance="fill" style="width:100%">
                        <mat-label>Serial Number</mat-label>
                        <input matInput placeholder="Serial Number" name="serialNumber" [(ngModel)]="bundle.serialNumber"
                            [formControl]="serialNumberControl">
                        <mat-error *ngIf="serialNumberControl.invalid">Serial Number Required</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-3 mb-3">
                    <mat-form-field appearance="fill" style="width:100%">
                        <mat-label>Name</mat-label>
                        <input matInput placeholder="name" name="name" [(ngModel)]="bundle.name">
                    </mat-form-field>
                </div>
                <div class="col-md-3 mb-3">
                    <mat-form-field appearance="fill" style="width:100%">
                        <mat-label>Bundle Type</mat-label>
                        <input matInput placeholder="bundleType" name="bundleType" [(ngModel)]="bundle.bundleTypeName" disabled="true">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 mb-3">
                    <mat-form-field appearance="fill" style="width:100%">
                        <mat-label>Description</mat-label>
                        <input matInput placeholder="description" name="description" [(ngModel)]="bundle.description">
                    </mat-form-field>
                </div>
            </div>

        <div>
            <p class="lead">Assignment and Location</p>
            <p class="text-muted">Where the Bundle is and/or who is responsible for it.</p>
        </div>
        <hr />
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-4 mb-3" *ngIf="['Available','Provisioning','Packing'].includes(bundle.status)"> 
                    <mat-form-field appearance="fill" style="width:100%">
                        <mat-label>Warehouse</mat-label>
                        <mat-select [(ngModel)]="bundle.warehouseId"
                            [(value)]="bundle.warehouseId" 
                            [formControl]="warehouseIdControl"
                            [disabled]="bundle.orderId !== null && ['Packing'].includes(bundle.status)">
                            <mat-option *ngFor="let warehouse of warehouses" [value]="warehouse.id">
                                {{warehouse.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error>Warehouse Required</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6 mb-3" *ngIf="bundle.status === 'Available'">
                    <mat-form-field style="width:100%" appearance="fill">
                        <mat-label>Warehouse Location</mat-label>
                        <input matInput placeholder="Warehouse Location" name="warehouseLocation"
                            [(ngModel)]="bundle.warehouseLocation" [formControl]="warehouseLocationControl">
                        <mat-error *ngIf="warehouseLocationControl.invalid">Warehouse Location Required</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-4 mb-3" *ngIf="['Checked Out'].includes(bundle.status)"> 
                    <app-autocomplete [options]="people" 
                        name="personId" 
                        [value]="bundle.personId" 
                        label="Person Assigned To"
                        [value]="bundle.personId"
                        (optionChange)="onPersonSelect($event)" 
                        [filterLimit]="100"
                        [control]="personIdControl">
                    </app-autocomplete>
                </div>
                <div class="col-md-4 mb-3" *ngIf="['Checked Out'].includes(bundle.status)"> 
                    <mat-form-field appearance="fill" style="width:100%">
                        <mat-label>Checked Out Person's Email</mat-label>
                        <input matInput placeholder="personEmail" name="personEmail" [(ngModel)]="assignedPerson.email" disabled="true">
                    </mat-form-field>
                </div>
                <div class="col-md-4 mb-3" *ngIf="['Checked Out'].includes(bundle.status)"> 
                    <mat-form-field appearance="fill" style="width:100%">
                        <mat-label>Checked Out Person's Phone</mat-label>
                        <input matInput placeholder="personPhone" name="personPhone" [(ngModel)]="assignedPerson.phone" disabled="true">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 mb-3" *ngIf="['Checked Out'].includes(bundle.status)"> 
                    <mat-form-field appearance="fill" style="width:100%">
                        <mat-label>Checked Out Person's company</mat-label>
                        <input matInput placeholder="personCompany" name="personCompany" [(ngModel)]="assignedPerson.company" disabled="true">
                    </mat-form-field>
                </div>
            </div>
        <div class="col-md-12">
            <div class="row">
                <h2>Assets</h2>
                <app-table [dataSource]="bundleAssets" [displayedColumns]="displayedAssetColumns"
                    (createClick)="addBundleAsset()" (rowClick)="openAsset($event)"
                    (deleteClick)="deleteBundleAsset($event)" *ngIf="bundleId!== 0" [permission]="permissions.Bundles"
                    [showAdd]="bundle.orderId === null"
                    [showDelete]="bundle.orderId === null"
                    uniqueKey="bundle-assets"></app-table>
                <p *ngIf="bundleId === 0">Please save the new Bundle first to add Assets</p>
            </div>
            <div class="row" style="padding-top:20px;">
                <h2>Consumables</h2>
                <app-table [dataSource]="bundleConsumables" [displayedColumns]="displayedConsumableColumns"
                    (createClick)="addBundleConsumable()" (rowClick)="openConsumable($event)"
                    (deleteClick)="deleteBundleConsumable($event)" *ngIf="bundleId !== 0" [permission]="permissions.Bundles"
                    [showAdd]="bundle.orderId === null"
                    [showDelete]="bundle.orderId === null"
                    uniqueKey="bundle-consumables"></app-table>
                <p *ngIf="bundleId === 0">Please save the new Bundle first to add Consumables</p>
            </div>
            <div class="row mt-5">
                <div class="col-md-6 mb-3">
                    <button mat-raised-button class="demo-button" color="primary" (click)="save()"
                        [disabled]="serialNumberControl.invalid || statusControl.invalid || (['Available','Provisioning'].includes(bundle.status) && warehouseIdControl.invalid) || (['Checked Out','Maintenance'].includes(bundle.status) && personIdControl.invalid) || (['Available'].includes(bundle.status) && warehouseLocationControl.invalid)">
                        Save
                    </button>
                    &nbsp;
                    <button mat-raised-button class="demo-button" [routerLink]="'/inventory/bundleTypes/' + bundle.bundleTypeId + '/bundles'">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #assetDialog>
    <h2 matDialogTitle>Asset</h2>
    <app-autocomplete [options]="assetTypes" 
                            placeHolder="Asset Type" 
                            [value]="bundleAssetModel.assetTypeId"
                            label="Asset Type" 
                            name="assetType" 
                            (optionChange)="onAssetTypeSelect($event)"
                            [control]=assetTypeFormController
                            (blurEvent)="onAssetTypeBlur()">
    </app-autocomplete>
    <app-autocomplete [url]="'inventory/assetTypes/' + bundleAssetModel.assetTypeId + '/assetModels'" 
                            placeHolder="Model" 
                            [valueForced]="assetTypeModels"
                            label="Model" 
                            name="assetTypeModel" 
                            (optionChange)="onAssetTypeModelSelect($event)"
                            [disabled]="bundleAssetModel.assetTypeId === 0"
                            [control]=assetModelFormController
                            (blurEvent)="onAssetTypeModelBlur()">
    </app-autocomplete>
    <app-autocomplete [url]="'inventory/assetModels/' + bundleAssetModel.assetModelId + '/assets?available=true'" 
                            placeHolder="Asset" 
                            [valueForced]="assetModels"
                            label="Asset" 
                            name="asset" 
                            (optionChange)="onAssetSelect($event)" 
                            displayKey="serialNumber"
                            [disabled]="bundleAssetModel.assetModelId === 0"
                            [control]=assetFormController
                            (blurEvent)="onAssetBlur()">
    </app-autocomplete>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose="cancel">Cancel</button>
        <button mat-button matDialogClose="save"
            [disabled]="bundleAssetModel.assetTypeId === 0 || bundleAssetModel.assetModelId === 0 || bundleAssetModel.assetId === 0">Save</button>
    </mat-dialog-actions>
</ng-template>

<ng-template #consumableDialog>
    <h2 matDialogTitle>Consumable</h2>
    <app-autocomplete [options]="consumables" 
                        placeHolder="Consumable" 
                        [value]="bundleConsumableModel.consumableId"
                        label="Consumable" 
                        name="consumables" 
                        (optionChange)="onConsumableSelect($event)"
                        [control]="consumableFormController"
                        (blurEvent)="onConsumableBlur()">
    </app-autocomplete>
    <mat-form-field appearance="fill" style="width:100%">
        <mat-label>Quantity</mat-label>
        <input matInput placeholder="Quantity" name="quantity" [(ngModel)]="bundleConsumableModel.quantity">
    </mat-form-field>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose="cancel">Cancel</button>
        <button mat-button matDialogClose="save"
            [disabled]="!bundleConsumableModel.consumableId || bundleConsumableModel.quantity <= 0">Save</button>
    </mat-dialog-actions>
</ng-template>

<ng-template #errorDialog>
    <p>{{errorMessage}}</p>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose="Okay">Okay</button>
    </mat-dialog-actions>
</ng-template>