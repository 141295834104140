import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { AssetStatuses } from 'app/models/control-center/asset.model';
import { AssetTypes } from 'app/models/control-center/assetType.model';
import { AutocompleteComponent } from 'app/components/autocomplete/autocomplete.component';
import { HttpService } from 'app/services/http.service';
import { environment } from 'environments/environment';
import { LogsType } from 'app/components/sendLogsModal/sendLogsModal.component';
import { ActivityComponent } from 'app/components/activity/activity.component';

const removeSpaces = control => {
    const replaced = control?.value?.replace(/[^0-9a-z:]/ig, '');
    if (replaced && replaced !== control.value) {
        control.setValue(replaced);
    }
    return null;
};

@Component({
  selector: 'app-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.scss'],
})
export class AssetComponent implements OnInit {
    @ViewChild(ActivityComponent) activityComponent: ActivityComponent;

  serialNumberControl = new FormControl('', [Validators.required, removeSpaces]);
  assetModelControl = new FormControl('', [Validators.required]);
  warehouseControl = new FormControl('', [Validators.required]);
  personControl = new FormControl('', [Validators.required]);
  statusControl = new FormControl('', [Validators.required]);
  warehouseLocationControl = new FormControl('', [Validators.required]);
  siteControl = new FormControl('', [Validators.required]);

  loading = true;
  assetId: number;
  asset: any;
  events: any = [];
  notes = '';
  posting = false;
  assetModels: any = [];
  warehouses: any = [];
  people: any = [];
  allAssets: any = [];
  sites: any = [];
  assetStatuses = AssetStatuses;
  assetTypes = AssetTypes;

  subAssets: any = [];
  subAsset: any;
  showAddButton = false;
  subAssetsChanged = false;
  permissions;
  waltAssetType = environment.waltAssetType.toString();
  logsType = LogsType;
  logsModalOpen: boolean = false;

  waltWifiDisabledDate: Date;
  isWaltWifiDisabled = false;

  isEnableDisableWaltWifisModalOpen = false;
  isEnablingDisablingWaltWifis = false;

  isDeleteSavedWifiModalOpen = false;
  isDeletingSavedWifis = false;

  @ViewChild('subAssetAutocomplete') subAssetAutocomplete: AutocompleteComponent | undefined;

  constructor(private _route: ActivatedRoute, private _httpService: HttpService, private _router: Router) { }

  async ngOnInit() {
    this.assetId = Number(this._route.snapshot.paramMap.get('id'));

    if (this.assetId === 0) {

      this.asset = {
        assetTypeId: parseInt(this._route.snapshot.queryParams['assetTypeId'], 10),
        created: '',
        status: null,
      };
    } else {
      this.asset = await this._httpService.get('inventory/assets/' + this.assetId);
      this.assetModelControl.setValue(this.asset.assetModelId);
      this.warehouseControl.setValue(this.asset.warehouseId);
      this.siteControl.setValue(this.asset.siteId);
      this.serialNumberControl.setValue(this.asset.serialNumber);
      this.serialNumberControl.disable();
      await this.updateWaltWifiInfo();
    }

    const promises = [];

    promises.push(this._httpService.get(`inventory/assetTypes/${this.asset.assetTypeId}/assetModels`));
    promises.push(this._httpService.get('inventory/warehouses'));
    promises.push(this._httpService.get('people'));
    promises.push(this._httpService.get(`inventory/assets/${this.assetId}/subAssets`));
    promises.push(this._httpService.get(`inventory/assets/${this.assetId}/availableSubAssets`));
    promises.push(this._httpService.get('people/authenticated/permissions'));
    promises.push(this._httpService.get('companies/sites'));

    const responses = await Promise.all(promises);

    this.assetModels = responses[0];
    this.warehouses = responses[1];
    this.people = responses[2];
    this.subAssets = responses[3];
    this.allAssets = responses[4];
    this.permissions = responses[5];
    this.sites = responses[6];

    this.loading = false;
  }

  async save() {
    this.loading = true;

    if (this.asset.cost && typeof this.asset.cost !== 'string') {
      this.asset.cost = this.asset.cost.toFixed(2);
    }

    if (this.subAssetsChanged) {
      await this._httpService.delete(`inventory/assets/${this.assetId}/subAssets`);
      for (let i = 0; i < this.subAssets.length; i++) {
        this.subAssets[i].parentAssetId = this.assetId;
        this.subAssets[i].subAssetId = this.subAssets[i].subAssetId ? this.subAssets[i].subAssetId : this.subAssets[i].id;
      }
      await this._httpService.post('inventory/subAssets', this.subAssets);
    }

    if (this.assetId === 0) await this._httpService.post('inventory/assets', this.asset);
    else await this._httpService.put('inventory/assets', this.asset);

    this._router.navigateByUrl('/inventory/assetTypes/assets/' + this.asset.assetTypeId);
    this.loading = false;
  }

  async postNotes() {
    this.posting = true;
    await this._httpService.post('inventory/assets/events', { assetId: this.assetId, notes: this.notes, event: 'Note' });
    this.events = await this._httpService.get('inventory/assets/' + this.assetId + '/events');
    this.notes = '';
    this.posting = false;
  }

  subAssetChanges(events) {
    this.subAssetsChanged = true;
    this.subAssets = events;
  }

  goToSubAsset(id) {
    window.location.href = '/inventory/assets/' + id;
  }

  rowClick(id: any) {
    this._router.navigateByUrl('/inventory/assets/' + id);
  }

  validateNumber(event: any) {
    let testing = '';
    try {
      testing = this.asset.cost.toString();
    } catch { /*Ignore*/ }
    const test_split = testing.split('.');
    if (event.code.includes('Digit') && test_split.length > 1 && test_split[1].length > 2) {
      // this.asset.cost = this.asset.cost.toFixed(2);
      this.asset.cost = test_split[0] + '.' + test_split[1].slice(0, 2);
    }
  }

  showLogsModal() {
    this.logsModalOpen = true;
  }

  async handleEnableDisableWaltWifiSubmitted() {
    this.asset = await this._httpService.get('inventory/assets/' + this.assetId);
    await this.updateWaltWifiInfo();
    await this.activityComponent.init();
  }

  private async updateWaltWifiInfo() {
    this.waltWifiDisabledDate = this.asset?.wifiDisabledUntil ? new Date(`${this.asset.wifiDisabledUntil}Z`) : null;
    this.isWaltWifiDisabled = !!this.waltWifiDisabledDate && this.waltWifiDisabledDate.valueOf() > Date.now();
  }
}
