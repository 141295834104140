<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-3">
    <h1 class="h2">Person: <span *ngIf="person">{{person.name}}</span></h1>
</div>

<div *ngIf="loading">loading</div>
<div *ngIf="showErrorMessage">Error occurred, please refresh and try again.</div>

<mat-tab-group animationDuration="0ms" *ngIf="!loading" sytle="margin-botton: 5px;" (selectedTabChange)="tabChanged($event)" [selectedIndex]="selectedIndex">

    <mat-tab label="Person Information" class="pb-5 p-5">

        <div class="row scrollContainer tab">
            <div class="col-md-8 scrollColumn tab">
                <form [formGroup]="personForm">
                    <div class="row">
                        <div class="flex-row">
                            <div>
                                <p class="lead">Contact Information</p>
                                <p class="text-muted">The person's contact information.</p>
                            </div>
                            <div>
                                <button
                                    *ngIf="this.permissions.PullLogs > 0"
                                    mat-raised-button
                                    class="demo-button mb-3"
                                    style="height: 40px;margin: 0px 10px;"
                                    color="primary"
                                    (click)="showLogsModal()"
                                >
                                    Pull Logs
                                </button>
                                <button
                                    *ngIf="person.id !== 0 && permissions['People'] === permissionType.Edit"
                                    mat-raised-button
                                    class="demo-button mb-3"
                                    style="height: 40px;margin: 0px 10px;"
                                    color="primary"
                                    (click)="deleteModalOpen = true"
                                >
                                    Delete User
                                </button>
                            </div>
                        </div>
                        <hr />
                        <div class="col-md-6 mb-3">
                            <mat-form-field appearance="fill" style="width:100%">
                                <mat-label> Name</mat-label>
                                <input matInput placeholder="Name" name="name" formControlName="name">
                                <mat-error *ngIf="personForm.get('name').invalid">Name Required</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6 mb-3">
                            <app-autocomplete name="companyId" label="Company" [options]="companies" [disabled]="person.id !== 0"
                                [control]="personForm.get('companyId')" [filterLimit]="100" [value]="person.companyId">
                            </app-autocomplete>
                            <mat-error *ngIf="personForm.get('companyId').invalid">Company Required</mat-error>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 mb-3 d-flex">
                            <mat-form-field appearance="fill" style="width:100%">
                                <mat-label>Email</mat-label>
                                <input matInput placeholder="Email" name="email" formControlName="email">
                            </mat-form-field>
                            <ng-container *ngIf="person.email">
                                <ng-container *ngTemplateOutlet="awaitingOwnershipIcon; context: {$implicit: 'Email'}"></ng-container>
                            </ng-container>
                        </div>
                        <div class="col-md-4 mb-3 d-flex">
                            <mat-form-field appearance="fill" style="width:100%">
                                <mat-label>Phone</mat-label>
                                <input matInput placeholder="Phone" name="phone" formControlName="phone">
                            </mat-form-field>
                            <ng-container *ngIf="person.phone">
                                <ng-container *ngTemplateOutlet="awaitingOwnershipIcon; context: {$implicit: 'Phone'}"></ng-container>
                            </ng-container>
                        </div>
                        <div class="col-md-4 mb-3" *ngIf="person.id !== 0">
                            <mat-form-field appearance="fill" style="width:100%">
                                <mat-label>Last Known Location</mat-label>
                                <input matInput name="lastKnownLocation" formControlName="lastKnownLocation">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="button-row" *ngIf="!loading && permissions['People'] === permissionType.Edit">
                        <button
                            *ngIf="person.id !== 0 && person.email"
                            mat-raised-button
                            class="remove-button"
                            color="primary"
                            (click)="openCredentialModal('email')"
                            [disabled]="personForm.invalid"
                        >
                            Remove Email
                        </button>
                        <button
                            *ngIf="person.id !== 0 && person.phone"
                            mat-raised-button
                            class="remove-button"
                            color="primary"
                            (click)="openCredentialModal('phone')"
                            [disabled]="personForm.invalid"
                        >
                            Remove Phone
                        </button>
                    </div>
                </form>
                <div class="row" *ngIf="!loading && permissions['People'] === permissionType.Edit">
                    <div class="col-md-6 mb-3">
                        <button mat-raised-button class="demo-button" color="primary" (click)="save()"
                            [disabled]="personForm.invalid">
                            Save
                        </button>
                    </div>
                </div>

            </div>
            <div class="col-md-4" style="padding-left: 25px;" *ngIf="person.id !== 0">
                <app-activity [id]="person.id" [table]="'person'"
                    [showAddButton]="permissions['People'] >= permissionType.Edit" [tab]="true">
                </app-activity>
            </div>
        </div>
    </mat-tab>

    <mat-tab label="Control Center Permissions" class="pb-5 p-5">
        <div class="row scrollContainer tab">
            <div class="col-md-12 scrollColumn tab">
                <div class="row">
                    <div>
                        <p class="lead">Control Center Permissions</p>
                        <p class="text-muted">Control this person's access to Control Center.</p>
                    </div>
                    <hr />
                    <div class="col-md-6 mb-3">
                        <mat-checkbox name="Control Center Access" [checked]="thisPersonPermissions.ControlCenter === 1" (change)="thisPersonPermissions.ControlCenter = thisPersonPermissions.ControlCenter === 1 ? 0 : 1"
                            color="primary">
                            Control Center Access</mat-checkbox>
                    </div>
                </div>
                <div class="row" *ngIf="thisPersonPermissions.ControlCenter === permissionType.Read">
                    <div>
                        <p class="lead">Functional Permissions</p>
                        <p class="text-muted">Control what this person can do in Control Center.</p>
                    </div>
                    <hr />
                    <div *ngFor="let permission of permissionProperties">
                        <div *ngIf="permission !== 'ControlCenter'">
                            <div class="col-md-2 mb-3">
                                <label>{{displayOverrides[permission] ?? permission}}:</label>
                            </div>
                            <div class="col-md-10 mb-3">
                                <mat-radio-group>
                                    <mat-radio-button [value]="permissionType.None"
                                        [checked]="thisPersonPermissions[permission] === permissionType.None"
                                        (click)="this.thisPersonPermissions[permission] = permissionType.None"
                                        color="primary">No Access
                                    </mat-radio-button>&nbsp;&nbsp;
                                    <mat-radio-button [value]="permissionType.Read"
                                        [checked]="thisPersonPermissions[permission] === permissionType.Read"
                                        (click)="this.thisPersonPermissions[permission] = permissionType.Read"
                                        color="primary">Read
                                    </mat-radio-button>&nbsp;&nbsp;
                                    <mat-radio-button [value]="permissionType.Edit"
                                        [checked]="thisPersonPermissions[permission] === permissionType.Edit"
                                        (click)="this.thisPersonPermissions[permission] = permissionType.Edit"
                                        color="primary">Write
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <hr>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="!loading && permissions['People'] === permissionType.Edit">
                    <div class="col-md-6 mb-3">
                        <button mat-raised-button class="demo-button" color="primary" (click)="save()">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>

    <mat-tab label="Weavix Permissions" class="pb-5 p-5">
        <div class="row scrollContainer tab">
            <div class="col-md-12 scrollColumn tab">
                <div class="row">
                    <div>
                        <p class="lead">Weavix Permissions</p>
                        <p class="text-muted">Control this person's permissions in Weavix.</p>
                    </div>
                    <hr />
                </div >
                    <div class="col-md-6 mb-3">
                        <mat-checkbox name="Walt Admin Code Access"
                            [checked]="hasWeavixPermission('walt-admin-code-access')"
                            (change)="toggleWeavixPermission('walt-admin-code-access')"
                            color="primary">
                            View Walt Admin Code</mat-checkbox>
                    </div>
                    <div class="col-md-6 mb-3">
                        <mat-checkbox name="Listen to Music Off-Site"
                            [checked]="hasWeavixPermission('enable-music-off-site')"
                            (change)="toggleWeavixPermission('enable-music-off-site')"
                            color="primary">
                            Listen to Music Off-Site</mat-checkbox>
                    </div>
                    <div class="col-md-6 mb-3">
                        <mat-checkbox name="Use Location Diagnostics"
                            [checked]="hasWeavixPermission('enable-location-diagnostics')"
                            (change)="toggleWeavixPermission('enable-location-diagnostics')"
                            color="primary">
                            Use Location Diagnostics</mat-checkbox>
                    </div>
                <div class="row" *ngIf="!loading && permissions['People'] === permissionType.Edit">
                    <div class="col-md-6 mb-3">
                        <button mat-raised-button class="demo-button" color="primary" (click)="save()">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>

    <mat-tab label="Instances" class="pb-5 p-5">
        <app-table
            header="Instances"
            subheader="Instances that this person has access to."
            [dataSource]="instances"
            [displayedColumns]="instanceColumns"
            [route]="'route'"
            [permission]="permissions['CompanyInstances']"
            [search]="true"
            [showActions]="false"
            (rowClick)="editInstance($event)"
        ></app-table>
    </mat-tab>

</mat-tab-group>

<app-send-logs-modal
    [id]="person.id"
    [logsType]="logsType.Person"
    *ngIf="logsModalOpen"
    (allDone)="logsModalOpen = false"
    (loadingChanged)="loading = $event"
    (errored)="showErrorMessage = true"
/>

<app-modal
    [title]="credentialModalTitle"
    [message]="crednetialModalMessage"
    [open]="credentialModalOpen"
    submitText="Yes"
    closeText="No"
    (closeEvent)="credentialModalOpen = false"
    (submitEvent)="removeCredential()"
/>

<app-delete-person-modal
    [open]="deleteModalOpen"
    [person]="person"
    [instances]="instances"
    (closeEvent)="deleteModalOpen = false"
    (submitEvent)="deletePerson()"
/>

<ng-template #awaitingOwnershipIcon let-type>
    <svg
        *ngIf="person.id !== 0 && person['awaiting' + type + 'Ownership']"
        mlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 512 512"
        class="credential-icon"
        [matTooltip]="type + ' is awaiting verification'"
    >
        <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
        <path
            fill="orange"
            d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480L40 480c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24l0 112c0 13.3 10.7 24 24 24s24-10.7 24-24l0-112c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"
        />
    </svg>
    <svg
        *ngIf="person.id !== 0 && !person['awaiting' + type + 'Ownership']"
        mlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 448 512"
        class="credential-icon"
        [matTooltip]="type + ' has been verified'"
    >
        <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
        <path
            fill="green"
            d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
        />
    </svg>
</ng-template>
