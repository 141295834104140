<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-3">
    <h1 class="h2">Consumables</h1>
</div>

<div *ngIf="loading">loading</div>

<div *ngIf="!loading">
    <div class="row">
        <app-table [dataSource]="consumables" [displayedColumns]="displayedColumns" (createClick)="add()"
            (rowClick)="edit($event)" (deleteClick)="delete($event)" [checkBoxes]="true" [permission]="permissions.Consumables" uniqueKey="consumables">
        </app-table>
    </div>
</div>