import { Component, OnInit } from '@angular/core';

import { HttpService } from 'app/services/http.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-asset-types',
  templateUrl: './assetTypes.component.html',
  styleUrls: ['./assetTypes.component.scss'],
})
export class AssetTypesComponent implements OnInit {

  loading = true;
  dataSource: any = [];
  displayedColumns: any = [
    { key: 'name', label: 'Name', type: 'string' },
    { key: 'catalog', label: 'Catalog Item', type: 'boolean' },
    { key: 'percentageAvailable', label: 'Asset Usage', type: 'progress' },
    { key: 'totalAssets', label: 'Total Assets', type: 'string' },
    { key: 'availableAssets', label: 'Available Assets', type: 'string' },
    { key: 'menu', label: 'Actions', type: 'menu' },
  ];
  permissions;

  constructor(private _httpService: HttpService, private _router: Router) { }

  async ngOnInit(): Promise<void> {
    this.load();
  }

  async load() {
    this.loading = true;
    this.dataSource = await this._httpService.get('inventory/assetTypes');
    this.permissions = await this._httpService.get('people/authenticated/permissions');
    this.loading = false;
  }


  add() {
    this._router.navigateByUrl('/inventory/assetTypes/0');
  }

  rowClick(id: any) {
    this._router.navigateByUrl('/inventory/assetTypes/assets/' + id);
  }

  edit(id: any) {
    this._router.navigateByUrl('/inventory/assetTypes/' + id);
  }

  async delete(ids: []) {
    this.loading = true;
    const promises = [];

    for (let i = 0; i < ids.length; i++) {
      promises.push(this._httpService.delete(`inventory/assetTypes/${ids[i]}`));
    }
    await Promise.all(promises);
    await this.load();
  }
}

