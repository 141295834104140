import { Component, OnInit } from '@angular/core';

import { HttpService } from 'app/services/http.service';
import { Permission } from 'app/models/control-center/permission.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
})
export class CompaniesComponent implements OnInit {

  loading = true;
  dataSource: any = [];
  displayedColumns: any = [
    { key: 'name', label: 'Name', type: 'string' },
    { key: 'domain', label: 'Domain', type: 'string' },
    { key: 'children', label: 'Child Companies', type: 'string' },
    { key: 'instances', label: 'weavix™ Instances', type: 'string' },
    { key: 'people', label: 'People', type: 'string' },
    { key: 'deals', label: 'Deals', type: 'string' },
  ];
  usage = [];
  permissions: Permission;

  constructor(private _httpService: HttpService, private _router: Router) { }

  async ngOnInit(): Promise<void> {
    this.load();
  }

  private async load() {
    this.loading = true;
    this.dataSource = await this._httpService.get('companies');
    this.permissions = await this._httpService.get('people/authenticated/permissions');
    this.loading = false;
  }

  add() {
    this._router.navigateByUrl('/companies/companies/0');
  }

  rowClick(id: any) {
    this._router.navigateByUrl('/companies/companies/' + id);
  }

  async delete(companyIds: number[]) {
    this.loading = true;
    await this._httpService.put('companies/delete', companyIds);
    await this.load();
  }

}

