import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'app/services/http.service';

@Component({
  selector: 'app-consumables',
  templateUrl: './consumables.component.html',
  styleUrls: ['./consumables.component.scss'],
})
export class ConsumablesComponent implements OnInit {

  saving = false;
  loading = true;
  consumables: any;

  displayedColumns: any = [
    { key: 'name', label: 'Name', type: 'string' },
    { key: 'unitOfMeasure', label: 'Unit of Measure', type: 'string' },
    { key: 'menu', label: 'Actions', type: 'menu' },
  ];
  permissions;


  constructor(private _rotue: ActivatedRoute, private _httpService: HttpService, private _router: Router) { }

  async ngOnInit() {
    this.load();
  }

  async load() {
    this.loading = true;

    this.consumables = await this._httpService.get('inventory/consumables');
    this.permissions = await this._httpService.get('people/authenticated/permissions');

    this.loading = false;
  }

  add() {
    this._router.navigateByUrl('/inventory/consumables/0');
  }

  edit(id: any) {
    this._router.navigateByUrl('/inventory/consumables/' + id);
  }

  async delete(ids: []) {
    this.loading = true;
    const promises = [];

    for (let i = 0; i < ids.length; i++) {
      promises.push(this._httpService.delete(`inventory/consumables/${ids[i]}`));
    }
    await Promise.all(promises);
    await this.load();
  }
}
