import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
  
import { NewRelicService } from './new-relic.service';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

NewRelicService.initializeNewRelic();

const consoleError = console.error;

console.error = (...args) => {
    const exc = args.find(x => x instanceof Error);
    if (exc) NewRelicService.sendError(exc);
    consoleError.apply(console, args);
};