import { Component } from '@angular/core';
import { AuthService } from 'app/services/auth.service';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent {

  year: string = new Date().getFullYear().toString();
  email = '';
  error = '';
  code = '';
  step = 1;
  loading = false;

  constructor(private _authService: AuthService) { }

  async request() {
    this.loading = true;
    this.error = '';
    const result = await this._authService.loginPart1(this.email);
    if (result.status !== 'ok') {
      this.error = result.error ?? `Something went wrong`;
      if (result.until) {
        this.error += ` until ${new Date(result.until).toLocaleString()}`;
      }
      this.loading = false;
      return;
    }
    this.step = 2;
    this.loading = false;
    return;
  }

  async validate() {
    this.loading = true;
    this.error = '';
    const result = await this._authService.loginPart2(this.email, this.code);
    if (result.status !== 'ok') {
      this.error = result.error ?? `Something went wrong`;
      if (result.until) {
        this.error += ` until ${new Date(result.until).toLocaleString()}`;
      }
      this.loading = false;
    }
  }

}
