import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Instance } from 'app/models/control-center/instance.model';
import { Permission, PermissionType } from 'app/models/control-center/permission.model';
import { InstanceService } from 'app/services/instance.service';
import { PermissionService } from 'app/services/permission.service';
import { Observable, forkJoin, from, map, of } from 'rxjs';

interface InstanceViewModel {
  instance?: Instance | null;
  permissions?: Permission;
}

@Component({
  selector: 'app-instance',
  templateUrl: './instance.component.html',
  styleUrls: ['./instance.component.scss'],
})
export class InstanceComponent {
  loading = false;
  selectedIndex = 0;
  errorMessage: string | null = null;
  permissionType = PermissionType;

  instanceId: number;
  companyId: number;

  vm$: Observable<InstanceViewModel>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly instanceService: InstanceService,
    private readonly permissionService: PermissionService,
  ) {
    this.instanceId = parseInt(this.route.snapshot.paramMap.get('id') ?? '0', 10);
    this.companyId = parseInt(this.route.snapshot.queryParamMap.get('companyId') ?? '0', 10);

    const instance$ = this.instanceId
      ? from(this.instanceService.getById(this.instanceId))
      : of(null);
    const permissions$ = from(this.permissionService.getMyPermissions());

    this.vm$ = forkJoin([instance$, permissions$]).pipe(
      map(([instance, permissions]) => ({
        instance,
        permissions,
      })),
    );
  }

  handleError(err: unknown) {
    if (!err || err === false) {
      this.errorMessage = null;
      return;
    }
    this.errorMessage = 'Please refresh and try again.';

    if (!(err instanceof HttpErrorResponse)) return;

    if (err.error['details']?.['reason']) {
      this.errorMessage = this.handleKnownReasons(err.error['details']['reason']);
    } else if (err.error['message']) {
      this.errorMessage = err.error['message'];
    } else {
      this.errorMessage = err.message;
    }
  }

  private handleKnownReasons(reason: string): string {
    switch (reason) {
      case 'unknown_support_user':
        return 'User is unknown to weavix.';
      case 'user_already_in_account':
        return 'User is already added to this account and cannot be added again.';
      case 'session_disconnected':
        return 'Session has already been disconnected.';
      default:
        return reason;
    }
  }
}
