import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { throttle } from 'lodash';

const AUTO_REFRESH_THRESHOLD_SECONDS = 30 * 60;

@Injectable()
export class RefreshAuthInterceptor implements HttpInterceptor {
    constructor(private _authService: AuthService) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (this._authService.isLoggedIn() && !this._authService.isAuthUrl(request.url)) {
            if (this._authService.getSecondsUntilTokenExpiry() < AUTO_REFRESH_THRESHOLD_SECONDS) {
                this.refreshSession();
            }
        }
        return next.handle(request);
    }

    refreshSession = throttle(() => {
        // explicitly not awaiting because we don't want to hang up the currently intercepted request
        this._authService.refreshSession();
    }, 5_000);
}
