<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 class="h2">Orphaned Instances</h1>

</div>

<div *ngIf="loading && !showErrorMessage">loading</div>
<div *ngIf="showErrorMessage">Error occurred, please refresh and try again.</div>


<app-table
    *ngIf="!loading"
    uniqueKey="orphaned-instances"
    [dataSource]="orphanedInstances"
    [displayedColumns]="displayedColumns"
    [showAdd]="false"
    [checkBoxes]="true"
    [permission]="permissions['Instances']"
    [additionalActions]="tableActions"
    [rowActions]="rowActions"
    (actionClick)="handleTableAction($event)"
    (rowActionClick)="handleTableRowAction($event)">
</app-table>

<ng-template #assignDialog>
    <h2 matDialogTitle>Assign to Comapny</h2>
    <p>Assign this instance to a company</p>
    <mat-form-field appearance="fill" style="width:100%">
        <mat-label>Company</mat-label>
        <mat-select name="companyId" [(ngModel)]="companyId">
            <mat-option *ngFor="let company of companies" [value]="company.id">
                {{company.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose="cancel">Cancel</button>
        <button mat-button matDialogClose="save" [disabled]="!companyId">Save</button>
    </mat-dialog-actions>
</ng-template>

<ng-template #mergeDialog>
    <h2 matDialogTitle>Merge Instances</h2>
    <p>Merge this instance into another instance</p>
    <mat-form-field appearance="fill" style="width:100%">
        <mat-label>Instance</mat-label>
        <mat-select name="instanceId" [(ngModel)]="instanceId">
            <mat-option *ngFor="let instance of instances" [value]="instance.id">
                {{instance.displayName}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose="cancel">Cancel</button>
        <button mat-button matDialogClose="save" [disabled]="!instanceId">Save</button>
    </mat-dialog-actions>
</ng-template>

<ng-template #activateDialog>
    <form [formGroup]="instanceAdminForm">
        <h2 matDialogTitle>Activate {{ selectedInstances?.length > 1 ? 'Instances' : 'Instance' }}</h2>
        <p>Assign a user that will be the first admin of this instance.</p>
        <app-autocomplete
            name="administratorPersonId"
            placeHolder="Instance Administrator"
            label="Instance Administrator"
            [options]="people"
            [filterLimit]="100"
            [control]="administratorPersonIdControl">
        </app-autocomplete>
    </form>
    <mat-dialog-actions align="end">
        <button mat-raised-button matDialogClose="cancel">Cancel</button>
        <button mat-raised-button matDialogClose="save" color="primary" [disabled]="!administratorPersonIdValue">Assign</button>
    </mat-dialog-actions>

</ng-template>
