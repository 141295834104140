import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Permission, PermissionChanges } from 'app/models/control-center/permission.model';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(
    private readonly httpService: HttpService,
  ) {}

  getMyPermissions(): Promise<Permission> {
    return this.httpService.get('people/authenticated/permissions');
  }

  getByPersonId(personId: number): Promise<Permission> {
    return this.httpService.get(`people/${personId}/permissions`);
  }

  update(personId: number, permissions: { permission: Permission }): Promise<{ status: string, changes: { [key: string]: { old: any, new: any } } }> {
    return this.httpService.put(`people/${personId}/permissions`, permissions);
  }

  sendPermissionsChangeEmail(personId: number, changes: { [key: string]: { old: any, new: any } }) {
    return this.httpService.post(`people/${personId}/permissions/changeEmail`, changes);
  }

  getWeavixPermissions(weavixUserId: string): Promise<string[]> {
    return this.httpService.get(`people/${weavixUserId}/weavixPermissions`);
  }

  updateWeavixPermissions(weavixUserId: string, permissions: { assignedPermissions: string[] }): Promise<PermissionChanges> {
    return this.httpService.put(`people/${weavixUserId}/weavixPermissions`, permissions);
  }
}
