<div class="text-center authBody" data-testid="Authentication">
    <main class="form-signin" *ngIf="step === 1">
        <form>
            <img class="mb-4" src="/assets/logo_dark.png" alt="" height="57">

            <p>Welcome to weavix™ Control Center</p>

            <div class="form-floating">
                <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" name="email"
                    [(ngModel)]="email" data-testid="Authentication-EmailInput">
                <label for="floatingInput">Email address</label>
            </div>

            <div>
                <p class="text-danger">{{error}}</p>
            </div>

            <button class="w-100 btn btn-lg btn-primary" type="submit" (click)="request()" [disabled]="loading" data-testid="Authentication-SignInButton">
                Sign in
            </button>

            <p class="mt-5 mb-3 text-muted">&copy; {{year}} weavix™</p>
        </form>
    </main>

    <main class="form-signin" *ngIf="step === 2">
        <form>
            <img class="mb-4" src="/assets/logo_dark.png" alt="" height="57">

            <p>We've emailed a validate code to {{email}}</p>

            <div class="form-floating">
                <input type="email" class="form-control" id="floatingInput" placeholder="xxxxxx" name="code"
                    [(ngModel)]="code" data-testid="Authentication-ShortCodeInput">
                <label for="floatingInput">Validation Code</label>
            </div>

            <div>
                <p class="text-danger">{{error}}</p>
            </div>

            <button class="w-100 btn btn-lg btn-primary" type="submit" (click)="validate()"
                [disabled]="loading" data-testid="Authentication-ValidateButton">Validate Identity</button>

            <p class="mt-5 mb-3 text-muted">&copy; {{year}} weavix™</p>
        </form>
    </main>

</div>
