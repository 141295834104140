import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Product, ProductFeature, ProductKey } from 'app/models/control-center/product.model';
import { Router } from '@angular/router';
import { Company } from 'app/models/control-center/company.model';
import { Instance } from 'app/models/control-center/instance.model';
import { Person } from 'app/models/control-center/person.model';
import { HttpService } from 'app/services/http.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DatePipe } from '@angular/common';
import { InstanceService } from 'app/services/instance.service';

interface AddInstanceForm {
  name: FormControl<string>;
  allCompanies: FormControl<boolean>;
  isTrial: FormControl<boolean>;
  trialExpires: FormControl<Date>;

  person?: FormControl<Person>;

  products: FormGroup<{ [key in ProductKey]?: FormControl<boolean> }>;
  productFeatures: FormGroup<{ [key: string]: FormControl<boolean> }>;
}

@Component({
  selector: 'app-instance-information',
  templateUrl: './instance-information.component.html',
  styleUrls: ['./instance-information.component.scss'],
  providers: [DatePipe],
})
export class InstanceInformationComponent implements OnInit {
  @ViewChild('activateDialog') activateDialog: TemplateRef<unknown>;
  @Input() instance: Instance | null = null;
  @Input() companyId = 0;
  @Input() loading = false;
  @Output() loadingChange = new EventEmitter<boolean>();
  @Output() showErrorMessageChange = new EventEmitter<boolean>();

  addInstanceForm: FormGroup<AddInstanceForm>;

  instanceAdminForm = this._formBuilder.group({
    administratorPersonId: this._formBuilder.control<Person | null>(null, [Validators.required]),
  });

  company: Company;
  products: Product[];
  productFeatures: ProductFeature[];
  distinctProducts: Product[];
  selectedProductIds = [];

  people: Person[] = [];

  valueFormatters = {
    trialExpires: (value) => {
      if (!value || isNaN(value)) return value;
      try {
        return this._datePipe.transform(new Date(+value), 'MM/dd/yyyy');
      } catch (err) {
        return value;
      }
    },
  };

  constructor(
    private readonly _httpService: HttpService,
    private readonly _router: Router,
    private readonly _formBuilder: FormBuilder,
    private readonly _dialog: MatDialog,
    private readonly _datePipe: DatePipe,
    private readonly instanceService: InstanceService,
  ) { }

  get isTrial(): boolean {
    return this.addInstanceForm.get('isTrial').value;
  }
  get administratorPersonIdControl() {
    return this.instanceAdminForm.controls.administratorPersonId;
  }
  get administratorPersonIdValue() {
    return this.administratorPersonIdControl.value;
  }

  async ngOnInit() {
    this.loading = true;
    this.loadingChange.emit(this.loading);

    if (!this.instance) {
      this.instance = new Instance();
      this.instance.id = 0;
    }
    const companyId = this.companyId;

    await Promise.all([
      (async () => this.products = await this._httpService.get(`companies/products`))(),
      (async () => this.company = await this._httpService.get(`companies/${companyId}`))(),
      (async () => this.people = await this._httpService.get(`people`))(),
      (async () => this.productFeatures = await this._httpService.get(`companies/productFeatures`))(),
    ]);

    if (this.instance.id === 0) {
      this.instance.name = this.company.name;
      this.instance.companyId = this.company.id;
      this.instance.products = [ProductKey.SmartRadio];
      // 'azure-transcribe' is a reference to PermissionAction.AzureTranscribe from app/models/control-center
      this.instance.productFeatures = ['azure-transcribe'];
      this.instance.allCompanies = true;
    }

    this.addInstanceForm = this.buildForm();
    this.loading = false;
    this.loadingChange.emit(this.loading);

  }

  private buildForm(): FormGroup<AddInstanceForm> {
    const productsFormGroup = this._formBuilder.group({});
    for (let p = 0; p < this.products.length; p++) {
      productsFormGroup.addControl(this.products[p].key, this._formBuilder.control(
        this.productCheckboxChecked(this.products[p].key)));
    }

    const productFeaturesFormGroup = this._formBuilder.group({});
    for (let pf = 0; pf < this.productFeatures.length; pf++) {
      productFeaturesFormGroup.addControl(this.productFeatures[pf].key, this._formBuilder.control(
        this.productFeatureCheckboxChecked(this.productFeatures[pf].key)));
    }

    const formGroup = this._formBuilder.group<AddInstanceForm>({
      name: this._formBuilder.control(this.instance?.name ?? this.instance.name, [Validators.required]),
      allCompanies: this._formBuilder.control(this.instance?.allCompanies, []),
      isTrial: this._formBuilder.control(!!this.instance?.trialExpires, []),
      trialExpires: this._formBuilder.control(
        this.instance?.trialExpires ? new Date(+this.instance.trialExpires) : new Date(),
        this.instance?.trialExpires ? [Validators.required] : [],
      ),
      products: productsFormGroup,
      productFeatures: productFeaturesFormGroup,
    });

    if (!this.instance.id) {
      formGroup.addControl('person', this._formBuilder.control(null, [Validators.required]));
    }

    formGroup.get('isTrial').valueChanges
      .subscribe(value => {
        const trialExpiresControl = formGroup.get('trialExpires');
        if (value) {
          trialExpiresControl.setValidators([Validators.required]);
          trialExpiresControl.setValue(this.instance?.trialExpires ? new Date(+this.instance.trialExpires) : new Date());
        } else {
          trialExpiresControl.setValidators([]);
          trialExpiresControl.setValue(null);
        }
        formGroup.updateValueAndValidity();
      });

    return formGroup;
  }

  productCheckboxChecked(key: ProductKey): boolean {
    if (this.instance?.products?.includes(key)) return true;
    return false;
  }

  productFeatureCheckboxChecked(key: string): boolean {
    if (this.instance?.productFeatures?.includes(key)) return true;
    return false;
  }

  private generateInstance(): void {
    this.instance.name = this.addInstanceForm.get('name').value;
    this.instance.products = this.getSelectedProducts();
    this.instance.productFeatures = this.getSelectedProductFeatures();
    this.instance.allCompanies = this.addInstanceForm.get('allCompanies').value;
    this.instance.trialExpires = this.addInstanceForm.get('isTrial').value ? this.addInstanceForm.get('trialExpires').value?.getTime?.() : null;
    if (this.instance.id === 0) this.instance.administratorPersonId = this.addInstanceForm?.get('person').value?.id;
  }

  private getSelectedProducts(): ProductKey[] {

    const productKeys: ProductKey[] = [];

    for (let i = 0; i < this.products.length; i++) {
      if (this.addInstanceForm.controls.products.get(this.products[i].key).value) {
        productKeys.push(this.products[i].key);
      }
    }

    return productKeys;
  }

  private getSelectedProductFeatures(): string[] {

    const productFeatures: string[] = [];

    for (let i = 0; i < this.productFeatures.length; i++) {
      if (this.addInstanceForm.controls.productFeatures.get(this.productFeatures[i].key).value) {
        productFeatures.push(this.productFeatures[i].key);
      }
    }

    return productFeatures;
  }

  async save() {

    this.loading = true;
    this.loadingChange.emit(this.loading);

    this.generateInstance();

    if (this.instance.id === 0) {
      await this.instanceService.create(this.instance);
    } else {
      await this.instanceService.update(this.instance);
    }

    this.loading = false;
    this.loadingChange.emit(this.loading);

    this._router.navigateByUrl(`companies/companies/${this.company.id}`);
  }

  back() {
    this._router.navigateByUrl(`companies/companies/${this.company.id}`);
  }

  activate() {
    this.administratorPersonIdControl.reset();
        this._dialog.open<unknown, unknown, string>(this.activateDialog)
          .afterClosed()
          .subscribe(async action => {
            switch (action) {
              case 'save':
                this.showErrorMessageChange.emit(false);
                this.loading = true;
                this.loadingChange.emit(this.loading);
                try {
                      await this.instanceService.activate({
                        person: this.administratorPersonIdValue,
                        instanceIds: [this.instance.id],
                      });
                      this.instance = await this.instanceService.getById(this.instance.id);
                      this.loading = false;
                      this.loadingChange.emit(this.loading);
                } catch (e) {
                      console.error('Failed to activate instance', e);
                      this.showErrorMessageChange.emit(true);
                }
                break;
            }
          });
  }

}
