<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-3">
    <h1 class="h2">Company: <span *ngIf="!loading && company">{{company.name}}</span></h1>
</div>

<div *ngIf="loading">loading</div>
<div *ngIf="showErrorMessage">Error occurred, please refresh and try again.</div>

<mat-tab-group animationDuration="0ms" *ngIf="!loading" (selectedTabChange)="tabChanged($event)" [selectedIndex]="selectedIndex">
    <mat-tab label="Information" class="pb-5 p-5">

        <div class="row" style="padding-top: 20px;" *ngIf="!loading">
            <div class="col-md-12">
                <form [formGroup]="companyForm">
                    <div class="row">
                        <div>
                            <p class="lead">Company Information</p>
                            <p class="text-muted">Basic Company Information. This will be synced with Hubspot.</p>
                        </div>
                        <hr />
                        <div class="col-md-8 mb-3">
                            <mat-form-field appearance="fill" style="width:100%">
                                <mat-label>Company Name</mat-label>
                                <input matInput placeholder="Company Name" name="name" formControlName="name">
                                <mat-error *ngIf="companyForm.get('name').invalid">Name Required</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4 mb-3">
                            <mat-form-field appearance="fill" style="width:100%">
                                <mat-label>Domain</mat-label>
                                <input matInput placeholder="Domain" name="domain" formControlName="domain">
                                <mat-error *ngIf="companyForm.get('domain').invalid">Domain Required</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </mat-tab>
    <!--<mat-tab label="Orders"  *ngIf="permissions['Orders']">
        <app-table [dataSource]="orders" [displayedColumns]="orderColumns" [route]="'route'" [checkBoxes]="true" [permission]="permissions['Orders']"
        *ngIf="!loading" [search]="true" (createClick)="create()" (rowClick)="rowClickOrder($event)"></app-table>
    </mat-tab>-->
    <mat-tab label="weavix™ Instances" *ngIf="companyId !== 0">
        <app-table
            *ngIf="!loading"
            uniqueKey="company-instances-{{company.name}}"
            [dataSource]="instances"
            [displayedColumns]="instanceColumns"
            [route]="'route'"
            [checkBoxes]="true"
            [permission]="permissions['CompanyInstances']"
            [search]="true"
            [additionalActions]="instancesTableActions"
            [rowActions]="instancesRowActions"
            (actionClick)="handleInstancesTableAction($event)"
            (rowActionClick)="handleInstancesTableRowAction($event)"
            (createClick)="addInstance()"
            (rowClick)="editInstance($event)"
            (deleteClick)="deleteInstances($event)"
        ></app-table>
    </mat-tab>
    <mat-tab label="Sites" *ngIf="companyId !== 0">
        <app-table [dataSource]="sites" [displayedColumns]="sitesColumns" [route]="'route'" [checkBoxes]="true"
            [permission]="permissions['Companies']" *ngIf="!loading" [search]="true" (createClick)="addSite()"
            (rowClick)="editSite($event)" (deleteClick)="deleteSites($event)" uniqueKey="company-sites-{{company.name}}"></app-table>
    </mat-tab>
</mat-tab-group>

<ng-container *ngIf="!loading && permissions['Companies'] === 2">

    <button mat-raised-button class="demo-button" color="primary" (click)="save()">
        Save
    </button>
    &nbsp;
    <button mat-raised-button class="demo-button" routerLink="../">
        Cancel
    </button>

</ng-container>

<ng-template #activateDialog>
    <form [formGroup]="instanceAdminForm">
        <h2 matDialogTitle>Activate {{ selectedInstances?.length > 1 ? 'Instances' : 'Instance' }}</h2>
        <p>Assign a user that will be the first admin of this instance.</p>
        <app-autocomplete
            name="administratorPersonId"
            placeHolder="Instance Administrator"
            label="Instance Administrator"
            [options]="people"
            [filterLimit]="100"
            [control]="administratorPersonIdControl">
        </app-autocomplete>
    </form>
    <mat-dialog-actions align="end">
        <button mat-raised-button matDialogClose="cancel">Cancel</button>
        <button mat-raised-button matDialogClose="save" color="primary" [disabled]="!administratorPersonIdValue">Assign</button>
    </mat-dialog-actions>

</ng-template>
