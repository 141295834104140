<div *ngIf="loading">
    <mat-spinner class="mt-3" diameter="20" />
</div>

<div class="row scrollContainer tab" *ngIf="!loading">
    <div class="col-md-9 mb-9 scrollColumn tab">
        <form [formGroup]="addInstanceForm">
            <div class="row">
                <div>
                    <p class="lead">weavix™ Instance Information</p>
                    <p class="text-muted">The instance's information.</p>
                </div>
                <hr />
                <div class="col-md-6 mb-3">
                    <mat-form-field appearance="fill" style="width:100%">
                        <mat-label>Instance Name</mat-label>
                        <input matInput placeholder="Instance Name" name="name" formControlName="name">
                        <mat-error *ngIf="addInstanceForm.get('name').invalid">Name Required</mat-error>
                    </mat-form-field>
                </div>

                <div class="col-md-6 mb-3">
                    <ng-container *ngIf="instance.id === 0">
                        <app-autocomplete name="personId" label="Instance Administrator" [options]="people"
                            hint="This is the first administrator that will be created"
                            [control]="addInstanceForm.get('person')" [filterLimit]="100"
                            displayKey="displayName">
                        </app-autocomplete>
                    </ng-container>
                    <ng-container *ngIf="instance?.id !== 0">
                        <div class="d-flex" style="padding-top: 15px; gap: 10px;">
                            <ng-container *ngIf="instance.activated">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                    viewBox="0 0 448 512">
                                    <path [attr.fill]="'green'"
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
                                </svg>
                                <span class="text-success text-bold">Activated</span>
                            </ng-container>
                            <ng-container *ngIf="!instance.activated">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                    viewBox="0 0 320 512">
                                    <path [attr.fill]="'red'"
                                        d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
                                </svg>
                                <span class="text-danger text-bold">Not Activated</span>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
                <div>
                    <p class="lead">Instance Settings</p>
                    <p class="text-muted">The instance's information.</p>
                </div>
                <hr />
                <div class="col-md-6 mb-3">
                    <mat-checkbox color="primary" name="allCompanies" formControlName="allCompanies">
                        Visible to Global Directory
                    </mat-checkbox>
                </div>
                <div class="col-md-6 mb-3">
                    <div class="row">
                        <div class="col-md-4">
                            <mat-checkbox color="primary" name="isTrial" formControlName="isTrial">
                                Is trial?
                            </mat-checkbox>
                        </div>
                        <div class="col-md-8" *ngIf="isTrial">
                            <mat-form-field appearance="fill" style="width:100%">
                                <mat-label>Trial Expires</mat-label>
                                <input matInput name="trialExpires" formControlName="trialExpires" [matDatepicker]="trialExpires">
                                <mat-error *ngIf="addInstanceForm.get('trialExpires').invalid">
                                    <ng-container *ngIf="addInstanceForm.get('trialExpires').hasError('matDatepickerParse') else trialExpiresRequiredErrorMessage">Invalid Date Format</ng-container>
                                    <ng-template #trialExpiresRequiredErrorMessage>Trial Expires Required</ng-template>
                                </mat-error>
                                <mat-datepicker-toggle matSuffix [for]="trialExpires"></mat-datepicker-toggle>
                                <mat-datepicker #trialExpires></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div>
                    <p class="lead">Instance Products</p>
                    <p class="text-muted">Products enabled for this instance.</p>
                </div>
                <hr />
                <div class="col-md-6 mb-3" formGroupName="products">
                    <div *ngFor="let product of products">
                        <mat-checkbox color="primary" [formControlName]="product.key"
                            [disabled]="product.key === 'smart-radio'">
                            {{product.name}}
                        </mat-checkbox>
                    </div>
                </div>
                <div>
                    <p class="lead">Instance Features</p>
                    <p class="text-muted">Additional features enabled in this instance.</p>
                </div>
                <hr />
                <div class="col-md-6 mb-3" formGroupName="productFeatures">
                    <div *ngFor="let productFeature of productFeatures">
                        <mat-checkbox color="primary" [formControlName]="productFeature.key">
                            {{productFeature.name}}
                        </mat-checkbox>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 d-flex justify-content-between">
                    <div class="d-flex mb-3" style="gap: 15px;">
                        <button mat-raised-button class="demo-button" color="primary" (click)="save()"
                            [disabled]="!this.addInstanceForm.valid">
                            Save
                        </button>
                        <button mat-raised-button class="demo-button" (click)="back()">
                            Cancel
                        </button>
                    </div>
                    <div *ngIf="instance && !instance.activated" class="d-flex mb-3">
                        <button mat-raised-button color="primary" class="demo-button" (click)="activate()">
                            Activate
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="col-md-3" *ngIf="instance">
        <app-activity [tab]="true" [id]="instance.id" table="instance" [valueFormatters]="valueFormatters" />
    </div>
</div>

<ng-template #activateDialog>
    <form [formGroup]="instanceAdminForm">
        <h2 matDialogTitle>Activate Instance</h2>
        <p>Assign a user that will be the first admin of this instance.</p>
        <app-autocomplete
            name="administratorPersonId"
            placeHolder="Instance Administrator"
            label="Instance Administrator"
            [options]="people"
            [filterLimit]="100"
            [control]="administratorPersonIdControl"
            displayKey="displayName">
        </app-autocomplete>
    </form>
    <mat-dialog-actions align="end">
        <button mat-raised-button matDialogClose="cancel">Cancel</button>
        <button mat-raised-button matDialogClose="save" color="primary" [disabled]="!administratorPersonIdValue">Assign</button>
    </mat-dialog-actions>

</ng-template>
