import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Instance } from 'app/models/control-center/instance.model';
import { PermissionType } from 'app/models/control-center/permission.model';
import { Person } from 'app/models/control-center/person.model';
import { PeopleService, PersonListItem } from 'app/services/people.service';
import { ActiveSupportAccessSession, CreateSessionRequest, SupportAccessService } from 'app/services/support-access.service';
import { Observable, catchError, forkJoin, from, map, of, tap } from 'rxjs';

interface UserFormViewModel {
  people: PersonListItem[];
  me?: Person;
  isEnabled?: boolean;
}

interface TableViewModel {
  sessions: ActiveSupportAccessSession[];
}

@Component({
  selector: 'app-instance-support-access',
  templateUrl: './instance-support-access.component.html',
  styleUrls: ['./instance-support-access.component.scss'],
})
export class InstanceSupportAccessComponent implements OnInit {
  @Input() instance!: Instance;
  @Input() permission!: PermissionType;
  @Output() failedLoad = new EventEmitter<unknown>();

  permissionType = PermissionType;
  sessionsLoading = true;

  formVm$: Observable<UserFormViewModel> | undefined;
  tableVm$: Observable<TableViewModel> | undefined;

  constructor(
    private readonly supportAccessService: SupportAccessService,
    private readonly peopleService: PeopleService,
  ) {}

  ngOnInit(): void {
    this.loadFormViewModel();
    this.loadTableViewModel();
  }

  private loadFormViewModel() {
    const people$ = from(this.peopleService.getAll({ permission: 'SupportAccess' }));
    const me$ = from(this.peopleService.getMe());
    const isEnabledResponse$ = from(this.supportAccessService.getIsEnabled(this.instance.id!));

    this.formVm$ = forkJoin([people$, me$, isEnabledResponse$]).pipe(
      map(([people, me, isEnabledResponse]) => ({
        people,
        me,
        isEnabled: isEnabledResponse.supportAccess,
      })),
    );
  }

  private loadTableViewModel() {
    const sessions$ = from(this.supportAccessService.getActiveSessions(this.instance.id!)).pipe(
      tap(() => this.sessionsLoading = false),
      catchError((err: unknown) => {
        console.error('Failed to load support access sessions.', err);
        this.failedLoad.emit(err);
        return of([]);
      }),
    );
    this.tableVm$ = sessions$.pipe(
      map(sessions => ({ sessions })),
    );
  }

  async disconnectSession(event: { sessionId: string }) {
    this.sessionsLoading = true;
    try {
        await this.supportAccessService.disconnectSession(this.instance.id!, event.sessionId);
    } catch (err: unknown) {
        this.failedLoad.emit(err);
    }
    this.loadTableViewModel();
  }

  async extendSession(event: { sessionId: string, hours: number }) {
    this.sessionsLoading = true;
    try {
        await this.supportAccessService.extendSession(this.instance.id!, event.sessionId, { hours: event.hours });
    } catch (err: unknown) {
        this.failedLoad.emit(err);
    }
    this.loadTableViewModel();
  }

  async submitSupportAccess(event: CreateSessionRequest) {
    this.sessionsLoading = true;
    try {
        await this.supportAccessService.createSession(this.instance.id!, event);
    } catch (err: unknown) {
        this.failedLoad.emit(err);
    }
    this.loadTableViewModel();
  }
}
