import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';


@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
})
export class ChipComponent {

  @Output() selectionChanged = new EventEmitter<any>();
  @Output() chipClicked = new EventEmitter<any>();

  @Input() label: string;
  @Input() options = [];
  @Input() selectedOptions = [];
  @Input() disabled = false;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  control = new FormControl();
  filteredOptions: Observable<any[]>;

  @ViewChild('chipInput') chipInput: ElementRef<HTMLInputElement>;

  constructor() {
    this.load();
  }

  load() {
    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(null),
      map((option) => (option ? this._filter(option) : this.options.slice())),
    );
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.selectedOptions.push(value);
      this.selectionChanged.emit(this.selectedOptions);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.control.setValue(null);
  }

  remove(option: string): void {
    const index = this.selectedOptions.indexOf(option);

    if (index >= 0) {
      this.selectedOptions.splice(index, 1);
      this.selectionChanged.emit(this.selectedOptions);
    }

    this.options.push(option);
    this.load();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedOptions.push(event.option.value);
    this.selectionChanged.emit(this.selectedOptions);
    this.chipInput.nativeElement.value = '';
    this.control.setValue(null);

    const index = this.options.indexOf(event.option.value);
    if (index >= 0) {
      this.options.splice(index, 1);
      this.load();
    }
  }

  private _filter(value): string[] {
    let filterValue;
    if (typeof value == 'string') filterValue = value.toLowerCase();
    else filterValue = value.name.toLowerCase();

    return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  handleClick(item) {
    this.chipClicked.emit(item.subAssetId ? item.subAssetId : item.id);
  }
}
