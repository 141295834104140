import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'app/services/http.service';
import { Permission, PermissionChanges, PermissionType } from 'app/models/control-center/permission.model';
import { Person } from 'app/models/control-center/person.model';
import { Utils } from 'app/utils';
import { Instance } from 'app/models/control-center/instance.model';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { PermissionService } from 'app/services/permission.service';
import { PeopleService } from 'app/services/people.service';
import { LogsType } from 'app/components/sendLogsModal/sendLogsModal.component';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss'],
})
export class PersonComponent implements OnInit {
  utils = Utils;

  loading = true;
  showErrorMessage = false;
  person: Person = new Person();

  events = [];
  companies = [];
  permissions: Permission;
  weavixPermissions: string[] = [];
  instances: Instance[] = [];
  permissionType = PermissionType;
  lastKnownLocation: string;
  logsType = LogsType;

  thisPersonPermissions: Permission = new Permission();
  permissionProperties: string[];
  /** Optional display names to use instead of Permission property names. */
  displayOverrides: Record<string, string> = {
    'SupportAccess': 'Temporary Customer Account Access',
  };
  selectedIndex: number;
  personForm: FormGroup;
  logsModalOpen: boolean = false;
  credentialModalOpen: boolean = false;
  credentialModalTitle: string;
  crednetialModalMessage: string;
  credentialType: 'email' | 'phone';
  deleteModalOpen: boolean = false;

  instanceColumns: any = [
    { key: 'name', label: 'Name', type: 'string' },
    { key: 'lastActivityDate', label: 'Last Activity', type: 'date' },
  ];

  constructor(
    private _route: ActivatedRoute,
    private _httpService: HttpService,
    private _router: Router,
    private _formBuilder: FormBuilder,
    private readonly _snackBar: MatSnackBar,
    private readonly peopleService: PeopleService,
    private readonly permissionService: PermissionService,
  ) { }

  async ngOnInit() {

    this.loading = true;

    this.person.id = Number(this._route.snapshot.paramMap.get('id'));

    if (this.person.id !== 0) {
        this.person = await this.peopleService.get(this.person.id);
      }

    await Promise.all([
      (async () => this.companies = await this._httpService.get(`companies`))(),
      (async () => this.thisPersonPermissions = await this.permissionService.getByPersonId(this.person.id))(),
      (async () => this.permissions = await this.permissionService.getMyPermissions())(),
      (async () => this.instances = await this._httpService.get(`companies/instances/person/${this.person.id}`))(),
      (async () => this.weavixPermissions = await this.permissionService.getWeavixPermissions(this.person.weavixUserId))(),
    ]);

    this.permissionProperties = Object.getOwnPropertyNames(this.thisPersonPermissions);
    this.lastKnownLocation = this.person.lastCity ? `${this.person.lastCity}, ${this.person.lastRegion}, ${this.person.lastCountry}` : 'Unknown';

    this.personForm = this.buildForm();

    this.loading = false;
  }

  buildForm() {
    const formGroup = this._formBuilder.group({
      name: new FormControl({ value: this.person?.name ?? this.person?.name, disabled: this.person.id !== 0 }, Validators.required),
      companyId: new FormControl({ value: this.person?.companyId ?? this.person?.companyId, disabled: this.person.id !== 0 }, Validators.required),
      phone: new FormControl({ value: this.person?.phone ?? this.person?.phone, disabled: this.person.id !== 0 }, Validators.required),
      email: new FormControl({ value: this.person?.email ?? this.person?.email, disabled: this.person.id !== 0 }, Validators.required),
      lastKnownLocation: new FormControl({ value: this.lastKnownLocation, disabled: true }),
    });

    return formGroup;
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.selectedIndex = tabChangeEvent.index;
  }

  generatePerson(): void {
    this.person.name = this.personForm.get('name').value;
    this.person.companyId = this.personForm.get('companyId').value?.id;
    this.person.email = this.personForm.get('email').value;
    this.person.phone = this.personForm.get('phone').value;
  }

  hasWeavixPermission(permission: string) {
    const permissionAction = permission;
    return this.weavixPermissions.some(x => x === permissionAction);
  }

  toggleWeavixPermission(permission: string) {
    const permissionAction = permission;
    if (!permissionAction) return;

    if (!this.hasWeavixPermission(permissionAction)) {
        this.weavixPermissions.push(permissionAction);
    } else {
        this.weavixPermissions = this.weavixPermissions.filter(x => x !== permissionAction);
    }
  }

  openCredentialModal(type: 'email' | 'phone') {
    this.credentialType = type;
    this.credentialModalTitle = `Remove ${type.charAt(0).toUpperCase() + type.slice(1)}`;
    this.credentialModalOpen = true;
    this.crednetialModalMessage = `Are you sure you want to remove ${this.person[type]} from ${this.person.name}?`;
  }

  async removeCredential() {
    this.loading = true;
    this.credentialModalOpen = false;
    try {
        const resp = await this.peopleService.removeCredential(this.person.id, this.credentialType);
        if (resp.status === 'ok') {
            this._snackBar.open(`Successfully removed ${this.credentialType} from ${this.person.name}`, 'Dismiss', { duration: 5000, panelClass: ['success'] });
            this.person = await this.peopleService.get(this.person.id);
            this.personForm = this.buildForm();
        } else if (resp.details?.accounts?.length) {
                this._snackBar.open(`Failed to remove ${this.credentialType} from ${this.person.name}. User is an admin on the following accounts: ${resp.details.accounts.join(', ')}`, 'Dismiss', { duration: 10000 });
        } else {
            this._snackBar.open(`Failed to remove ${this.credentialType} from ${this.person.name}. Reason: ${resp.details?.reason}`, 'Dismiss', { duration: 10000 });
        }
    } catch (e) {
        console.error('Failed to remove credential', e);
        this._snackBar.open(`Unexpected error attempting to remove ${this.credentialType} from ${this.person.name}`, 'Dismiss', { duration: 10000 });
    } finally {
        this.loading = false;
    }
  }

  async save() {
    this.loading = true;
    this.generatePerson();

    if (this.person.id === 0) {
      this.person = await this.peopleService.create(this.person);
    }
    if (!this.thisPersonPermissions.ControlCenter) Object.keys(this.thisPersonPermissions).forEach(p => this.thisPersonPermissions[p] = PermissionType.None);
    const weavixPermissionChanges: PermissionChanges = await this.permissionService.updateWeavixPermissions(this.person.weavixUserId, { assignedPermissions: this.weavixPermissions });
    const ccPermissionChanges: { status: string, changes: PermissionChanges } = await this.permissionService.update(this.person.id, { permission: this.thisPersonPermissions });
    if (Object.keys(weavixPermissionChanges).length || Object.keys(ccPermissionChanges?.changes).length) {
        await this.permissionService.sendPermissionsChangeEmail(this.person.id, { ...weavixPermissionChanges, ...ccPermissionChanges.changes });
    }
    this.loading = false;
  }

  editInstance(id: number) {
    const instance = this.instances.find(i => i.id === id);
    if (instance) this._router.navigateByUrl(`companies/instances/${id}?companyId=${instance.companyId}`);
  }

  showLogsModal() {
    this.logsModalOpen = true;
  }

  async deletePerson() {
    this.deleteModalOpen = false;
    this.loading = true;
    try {
        const resp = await this.peopleService.delete(this.person.id);
        if (resp.status === 'ok') {
            this._snackBar.open(`Successfully deleted ${this.person.name}`, 'Dismiss', { duration: 5000, panelClass: ['success'] });
            this._router.navigateByUrl('/companies/people');
        } else {
            this._snackBar.open(`Failed to delete ${this.person.name}`, 'Dismiss', { duration: 5000, panelClass: ['error'] });
        }
    } catch (e) {
        console.error('Failed to delete person', e);
        this._snackBar.open(`Failed to delete ${this.person.name}`, 'Dismiss', { duration: 5000, panelClass: ['error'] });
    }
  }
}
