import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { PermissionType } from 'app/models/control-center/permission.model';
import { ActiveSupportAccessSession } from 'app/services/support-access.service';

const ROW_ACTIONS = [
    'disconnect',
    'extend',
] as const;
type RowAction = typeof ROW_ACTIONS[number];

@Component({
  selector: 'app-instance-support-access-table',
  templateUrl: './instance-support-access-table.component.html',
  styleUrls: ['./instance-support-access-table.component.scss'],
})
export class InstanceSupportAccessTableComponent {
  @Input() permission = PermissionType.None;
  @Input() sessions: ActiveSupportAccessSession[] | null = [];
  @Input() loading = false;

  @Output() disconnectSessionClicked = new EventEmitter<{ sessionId: string }>();
  @Output() extendSessionClicked = new EventEmitter<{ sessionId: string, hours: number }>();

  displayedColumns = [
    { key: 'personName', label: 'User', type: 'string' },
    { key: 'expectedEndDate', label: 'Expiration Time', type: 'date' },
  ];

  rowActions: Array<{ name: string, key: RowAction, color?: ThemePalette }> = [
    { name: 'Disconnect Early', key: 'disconnect', color: 'primary' },
    { name: 'Extend Access (+8hr)', key: 'extend', color: 'primary' },
  ];

  async handleTableRowAction({ key, rows }: { key: RowAction, rows: ActiveSupportAccessSession[] }) {
    if (rows.length !== 1) throw new Error('Table component implementation has changed! It should only emit one row per action!');
    switch (key) {
      case 'disconnect':
        return await this.disconnectUser(rows[0]);
      case 'extend':
        return await this.extendUserAccess(rows[0]);
    }
  }

  private async disconnectUser(session: ActiveSupportAccessSession) {
    this.disconnectSessionClicked.emit({ sessionId: session.id });
  }

  private async extendUserAccess(session: ActiveSupportAccessSession) {
    this.extendSessionClicked.emit({ sessionId: session.id, hours: 8 });
  }
}
