import { Component, ElementRef, EventEmitter, Injectable, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { BooleanInput } from '@angular/cdk/coercion';

// Replacement for @agm/core's MapsApiLoader that's not implemented in @angular/google-maps.
// We could just call importLibrary() directly, but this makes testing easier.
@Injectable({
    providedIn: 'root',
})
export class MapsApiLoader {
    async load(): Promise<void> {
        await google.maps.importLibrary('maps');
    }
}

@Component({
  selector: 'app-address-search',
  templateUrl: './address-search.component.html',
  styleUrls: ['./address-search.component.scss'],
})
export class AddressSearchComponent implements OnInit {
  @Output() placeChange = new EventEmitter<google.maps.places.PlaceResult>();
  @Output() blurEvent = new EventEmitter();

  @Input() options: any[] = [];
  @Input() url = '';
  @Input() placeHolder = 'Search for an address...';
  @Input() value?: any;
  @Input() label?: string;
  @Input() disabled = false;
  @Input() disabledValue?: string;
  @Input() hint: string;
  @Input() control: any;
  @Input() filterLimit: number;
  @Input() displayKey = 'name';
  @Input() showErrors = false;
  @Input() required?: BooleanInput;

  @ViewChild('search')
  public searchElementRef: ElementRef;

  myControl = new FormControl();
  filteredOptions$: Observable<any[] | undefined>;
  valueLabel: string;
  lastPlace: google.maps.places.PlaceResult;
  editing: boolean = false;

  constructor(
    private mapsApiLoader: MapsApiLoader,
    private ngZone: NgZone,
  ) {}

  ngOnInit(): void {

    if (this.control) {
      this.myControl = this.control;
    }

    if (!this.placeHolder) {
      this.placeHolder = 'Pick One';
    }

    if (this.value) {
      this.myControl.setValue(this.value);
    }

    this.initializeMapsApi();
  }

  private setLastPlace(place: google.maps.places.PlaceResult) {
    this.lastPlace = place;
    this.placeChange.emit(place);
  }

  // fetches initial address, but doesn't get all the details... dumb
  textSearch(query: string) {
    new google.maps.places.PlacesService(this.searchElementRef.nativeElement).textSearch({
      query,
    }, (result) => {
      if (result.length) {
        this.getDetails.bind(this)(result[0].place_id);
      }
    });
  }

  // fetches initial address details needed for form
  private getDetails(placeId: string) {
    new google.maps.places.PlacesService(this.searchElementRef.nativeElement).getDetails({
      placeId,
    }, (result) => {
      if (result) {
        // google.maps often doesn't trigger Angular change detection.
        // forcing to run in ngZone to trigger change detection.
        this.ngZone.run(() => {
          this.setLastPlace.bind(this)(result);
          this.myControl.setValue(result.formatted_address);
        });
      }
    });
  }

  private async initializeMapsApi() {
    await this.mapsApiLoader.load();
    // allow element to render
    setTimeout(() => {
        if (this.myControl.value) this.textSearch(this.myControl.value);

        const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
            types: ['address'],
        });
        autocomplete.addListener('place_changed', () => {
            const place: google.maps.places.PlaceResult = autocomplete.getPlace();
            this.setLastPlace(place);
            this.myControl.setValue(place.formatted_address);
            this.editing = false;
        });
    });
  }

  displayFn(option: any) {
    if (option) {
      return option[this.displayKey];
    }
  }

  onBlur() {
    setTimeout(() => {
        if (this.editing) {
            this.myControl.setValue(this.lastPlace?.formatted_address);
            this.editing = false;
        }
    }, 200);
    this.blurEvent.emit();
  }

  clear() {
    this.myControl.reset();
  }
}
