<ng-template #modalContent>
    <div class="modal-container">
        <button mat-icon-button aria-label="Close modal" class="icon-button-small" (click)="onCancel()">
            <svg xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                <path
                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
            </svg>
        </button>
        <div class="title">
            {{title}}
        </div>
        <div class="separator"></div>
        <div class="message">
            {{message}}
            <ng-content/>
        </div>
        <mat-dialog-actions align="end" *ngIf="showCancel || showSubmit">
                <button
                    *ngIf="showCancel"
                    mat-raised-button
                    (click)="onCancel()"
                    [disabled]="isSubmitting"
                >
                    {{closeText ?? 'Cancel'}}
                </button>
                <button
                    *ngIf="showSubmit"
                    mat-raised-button
                    (click)="onAccept()"
                    color="primary"
                    [disabled]="isSubmitting"
                >
                    {{submitText ?? 'Yes'}}
                </button>
        </mat-dialog-actions>
    </div>
</ng-template>