import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Instance } from 'app/models/control-center/instance.model';
import { PermissionType } from 'app/models/control-center/permission.model';
import { HttpService } from 'app/services/http.service';
import { PeopleService } from 'app/services/people.service';
import { RecordRTCPromisesHandler, StereoAudioRecorder } from 'recordrtc';
import { Buffer } from 'buffer';
@Component({
    selector: 'app-instance-two-walt-demo',
    templateUrl: './instance-two-walt-demo.component.html',
    styleUrls: ['./instance-two-walt-demo.component.scss'],
})
export class InstanceTwoWaltDemoComponent implements OnInit {
    @Input() instance!: Instance;
    @Input() permission!: PermissionType;
    @Output() failedLoad = new EventEmitter<unknown>();

    targetAccountId;
    sentFromUserId;
    maintenanceChannelId;
    safetyChannelId;
    operationsChannelId;
    everyoneChannelId;
    isLoading = true;
    isSettingUpDemo = false;
    inProgressDemo: any;
    stream: MediaStream;
    blobUrl;
    recorder: RecordRTCPromisesHandler;
    hasPrompt1Recording = false;
    hasPrompt3Recording = false;
    hasPrompt4Recording = false;
    hasPrompt6Recording = false;
    hasPrompt9Recording = false;
    prompt1Base64: string;
    prompt3Base64: string;
    prompt4Base64: string;
    prompt6Base64: string;
    prompt9Base64: string;
    recordingPrompt1 = false;
    recordingPrompt3 = false;
    recordingPrompt4 = false;
    recordingPrompt6 = false;
    recordingPrompt9 = false;
    disablePrompts = false;

    constructor(private httpService: HttpService,
        private peopleService: PeopleService,
    ) { }

    async ngOnInit(): Promise<void> {
        try {
            const me = await this.peopleService.getMe();
            this.sentFromUserId = me.weavixUserId;
            await this.initialize();
        } finally {
            this.isLoading = false;
        }
    }

    async sendPrerecordedPtt(prompt: string, channelId: string) {
        this.disablePrompts = true;
        try {
            switch (prompt) {
                case 'Prompt1': {
                    await this.httpService.post(`companies/accounts/${this.instance.weavixAccountId}/demos/${this.inProgressDemo.id}/send-recorded-ptt`, {
                        'destinationChannelId': channelId,
                        'destinationAccountId': this.targetAccountId,
                        'message': 'Hello from Wichita',
                        'senderUserId': this.sentFromUserId,
                        'ccAccountId': this.instance.id,
                        'recordingId': `two-walt-demo/${this.sentFromUserId}/Prompt1.wav`,
                    });
                    break;
                }
                case 'Prompt3': {
                    await this.httpService.post(`companies/accounts/${this.instance.weavixAccountId}/demos/${this.inProgressDemo.id}/send-recorded-ptt`, {
                        'destinationChannelId': channelId,
                        'destinationAccountId': this.targetAccountId,
                        'message': 'Go ahead',
                        'senderUserId': this.sentFromUserId,
                        'ccAccountId': this.instance.id,
                        'recordingId': `two-walt-demo/${this.sentFromUserId}/Prompt3.wav`,
                    });
                    break;
                }
                case 'Prompt4': {
                    await this.httpService.post(`companies/accounts/${this.instance.weavixAccountId}/demos/${this.inProgressDemo.id}/send-recorded-ptt`, {
                        'destinationChannelId': channelId,
                        'destinationAccountId': this.targetAccountId,
                        'message': 'Ten Four. I\'m on my way.',
                        'senderUserId': this.sentFromUserId,
                        'ccAccountId': this.instance.id,
                        'recordingId': `two-walt-demo/${this.sentFromUserId}/Prompt4.wav`,
                    });
                    break;
                }
                case 'Prompt6': {
                    await this.httpService.post(`companies/accounts/${this.instance.weavixAccountId}/demos/${this.inProgressDemo.id}/send-recorded-ptt`, {
                        'destinationChannelId': channelId,
                        'destinationAccountId': this.targetAccountId,
                        'message': 'Hey Emily',
                        'senderUserId': this.sentFromUserId,
                        'ccAccountId': this.instance.id,
                        'recordingId': `two-walt-demo/${this.sentFromUserId}/Prompt6.wav`,
                    });
                    break;
                }
                case 'Prompt9': {
                    await this.httpService.post(`companies/accounts/${this.instance.weavixAccountId}/demos/${this.inProgressDemo.id}/send-recorded-ptt`, {
                        'destinationChannelId': channelId,
                        'destinationAccountId': this.targetAccountId,
                        'message': 'Pretty cool huh',
                        'senderUserId': this.sentFromUserId,
                        'ccAccountId': this.instance.id,
                        'recordingId': `two-walt-demo/${this.sentFromUserId}/Prompt9.wav`,
                    });
                    break;
                }
            }
        } finally {
            this.disablePrompts = false;
        }
    }

    async sendSpoofPtt(message: string, channelId: string, locale: string) {
        this.disablePrompts = true;
        try {
            await this.httpService.post(`companies/accounts/${this.instance.weavixAccountId}/demos/${this.inProgressDemo.id}/send-ptt`, {
                'destinationChannelId': channelId,
                'destinationAccountId': this.targetAccountId,
                'message': message,
                'messageLanguage': locale,
                'senderUserId': this.sentFromUserId,
                'ccAccountId': this.instance.id,
            });
        } finally {
            this.disablePrompts = false;
        }
    }

    async initialize() {
        const inProgressDemo = await this.httpService.get(`companies/${this.instance.weavixAccountId}/demos`);
        this.inProgressDemo = inProgressDemo?.id ? inProgressDemo : undefined;

        this.targetAccountId = this.instance.weavixAccountId;
        if (!this.inProgressDemo) return;

        this.maintenanceChannelId = this.inProgressDemo.channels.find(x => x.name === 'Maintenance').id;
        this.safetyChannelId = this.inProgressDemo.channels.find(x => x.name === 'Safety').id;
        this.operationsChannelId = this.inProgressDemo.channels.find(x => x.name === 'Operations').id;
        this.everyoneChannelId = this.inProgressDemo.channels.find(x => x.name === 'Everyone').id;

        //get list of user prerecorded ptts
        await this.getAllPrerecordedPttsForUser();
    }


    async refresh() {
        this.isLoading = true;
        this.ngOnInit();
    }

    async setUpDemo() {
        if (this.isSettingUpDemo) return;
        this.isSettingUpDemo = true;
        try {
            await this.httpService.post(`companies/${this.instance.weavixAccountId}/demos`, {
                callingUserId: this.sentFromUserId,
                accountId: this.instance.id,
            });
            await this.initialize();
        } finally {
            this.isSettingUpDemo = false;
        }
    }

    async concludeDemo() {
        if (this.isSettingUpDemo) return;
        this.isSettingUpDemo = true;
        try {
            await this.httpService.post(`companies/${this.instance.weavixAccountId}/demos/${this.inProgressDemo.id}/conclude`, {
                callingUserId: this.sentFromUserId,
                accountId: this.instance.id,
            });
            await this.initialize();
        } finally {
            this.isSettingUpDemo = false;
        }
    }

    async sendDemoMassAlert(message: string, danger: boolean = false) {
        this.disablePrompts = true;
        try {
            const request = {
                created: new Date(),
                facilityId: this.inProgressDemo.facility.id,
                sender: this.sentFromUserId,
                alertTypeId: danger ? this.inProgressDemo.alertTypes.find(x => x.name.toLowerCase() === 'danger').id : this.inProgressDemo.alertTypes.find(x => x.name.toLowerCase() === 'info').id,
                message,
                accountId: this.instance.id,
            };
            await this.httpService.post(`companies/${this.instance.weavixAccountId}/demos/${this.inProgressDemo.id}/mass-alert`, request);
        } finally {
            this.disablePrompts = false;
        }
    }

    async updateFacilitySettings(settings: any) {
        this.disablePrompts = true;
        try {
            await this.httpService.post(`companies/${this.instance.weavixAccountId}/demos/${this.inProgressDemo.id}/update-facility`, {
                callingUserId: this.sentFromUserId,
                accountId: this.instance.id,
                settings,
            });
        } finally {
            this.disablePrompts = false;
        }
    }

    async startRecording(prompt: string) {
        try {
            this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        } catch (err) {
            alert('You need to allow the browser to access your microphone to record audio.');
        }
        this.recorder = new RecordRTCPromisesHandler(this.stream, {
            type: 'audio',
            mimeType: 'audio/wav',
            recorderType: StereoAudioRecorder,
        });

        switch (prompt) {
            case 'Prompt1': {
                this.recordingPrompt1 = true;
                break;
            }
            case 'Prompt3': {
                this.recordingPrompt3 = true;
                break;
            }
            case 'Prompt4': {
                this.recordingPrompt4 = true;
                break;
            }
            case 'Prompt6': {
                this.recordingPrompt6 = true;
                break;
            }
            case 'Prompt9': {
                this.recordingPrompt9 = true;
                break;
            }
        }
        await this.recorder.startRecording();
    }

    async stopRecording(prompt: string) {
        switch (prompt) {
            case 'Prompt1': {
                this.recordingPrompt1 = false;
                break;
            }
            case 'Prompt3': {
                this.recordingPrompt3 = false;
                break;
            }
            case 'Prompt4': {
                this.recordingPrompt4 = false;
                break;
            }
            case 'Prompt6': {
                this.recordingPrompt6 = false;
                break;
            }
            case 'Prompt9': {
                this.recordingPrompt9 = false;
                break;
            }
        }
        let base64: string;

        await this.recorder.stopRecording();
        this.stream.getTracks().forEach(track => track.stop());
        const blob = await this.recorder.getBlob();
        this.recorder = null;
        switch (prompt) {
            case 'Prompt1': {
                this.hasPrompt1Recording = true;
                const buffer = await this.blobToBuffer(blob);
                base64 = buffer.toString('base64');
                this.prompt1Base64 = base64;
                break;
            }
            case 'Prompt3': {
                this.hasPrompt3Recording = true;
                const buffer = await this.blobToBuffer(blob);
                base64 = buffer.toString('base64');
                this.prompt3Base64 = base64;
                break;
            }
            case 'Prompt4': {
                this.hasPrompt4Recording = true;
                const buffer = await this.blobToBuffer(blob);
                base64 = buffer.toString('base64');
                this.prompt4Base64 = base64;
                break;
            }
            case 'Prompt6': {
                this.hasPrompt6Recording = true;
                const buffer = await this.blobToBuffer(blob);
                base64 = buffer.toString('base64');
                this.prompt6Base64 = base64;
                break;
            }
            case 'Prompt9': {
                this.hasPrompt9Recording = true;
                const buffer = await this.blobToBuffer(blob);
                base64 = buffer.toString('base64');
                this.prompt9Base64 = base64;
                break;
            }
        }

        await this.httpService.post(`companies/users/${this.sentFromUserId}/prerecorded-ptt/${prompt}`, { base64: base64 });
    }

    async getAllPrerecordedPttsForUser() {
        const [prompt1, prompt3, prompt4, prompt6, prompt9] = await Promise.all([
            this.httpService.get(`companies/users/${this.sentFromUserId}/prerecorded-ptt/Prompt1`),
            this.httpService.get(`companies/users/${this.sentFromUserId}/prerecorded-ptt/Prompt3`),
            this.httpService.get(`companies/users/${this.sentFromUserId}/prerecorded-ptt/Prompt4`),
            this.httpService.get(`companies/users/${this.sentFromUserId}/prerecorded-ptt/Prompt6`),
            this.httpService.get(`companies/users/${this.sentFromUserId}/prerecorded-ptt/Prompt9`),
        ]);
        this.hasPrompt1Recording = (typeof prompt1.base64String) === 'string';
        this.hasPrompt3Recording = (typeof prompt3.base64String) === 'string';
        this.hasPrompt4Recording = (typeof prompt4.base64String) === 'string';
        this.hasPrompt6Recording = (typeof prompt6.base64String) === 'string';
        this.hasPrompt9Recording = (typeof prompt9.base64String) === 'string';

        this.prompt1Base64 = this.hasPrompt1Recording ? prompt1.base64String : '';
        this.prompt3Base64 = this.hasPrompt3Recording ? prompt3.base64String : '';
        this.prompt4Base64 = this.hasPrompt4Recording ? prompt4.base64String : '';
        this.prompt6Base64 = this.hasPrompt6Recording ? prompt6.base64String : '';
        this.prompt9Base64 = this.hasPrompt9Recording ? prompt9.base64String : '';
    }

    convertBase64ToBlob(base64: string) {
        // Decode base64 string to a byte array
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // Create a blob from the byte array
        return new Blob([byteArray], { type: 'audio/wav' });
    }

    playAudioRecording(prompt: string) {
        let base64: string;
        switch (prompt) {
            case 'Prompt1': {
                base64 = this.prompt1Base64;
                break;
            }
            case 'Prompt3': {
                base64 = this.prompt3Base64;
                break;
            }
            case 'Prompt4': {
                base64 = this.prompt4Base64;
                break;
            }
            case 'Prompt6': {
                base64 = this.prompt6Base64;
                break;
            }
            case 'Prompt9': {
                base64 = this.prompt9Base64;
                break;
            }
        }
        new Audio(`data:audio/wav;base64,${base64}`).play();
    }

    blobToBuffer(blob: Blob): Promise<Buffer> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function () {
                const arrayBuffer = reader.result as ArrayBuffer;
                const buffer = Buffer.from(arrayBuffer);
                resolve(buffer);
            };
            reader.onerror = function (err) {
                reject(err);
            };
            reader.readAsArrayBuffer(blob);
        });
    }
}
